import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../backoffice/admin/include/layout";
import { myroutes } from "../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import StatutsService from "../../../services/StatutsService";
import { useSelector } from "react-redux";
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import CalendriersService from "../../../services/CalendriersService";
import CalendriersGlobalsService from "../../../services/CalendriersGlobalsService";
import DossiersService from "../../../services/DossiersService";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { afficherMessagesSuccess, convertDateToTexte, filterStatut, sortedDates } from '../../../utils/helpers';
import { Editor } from "primereact/editor";
import ChambresService from "../../../services/ChambresService";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

export default function DatesaudiencesEdit() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    const [dossier_id, setDossier_id] = useState('')
    const [calendrier_id, setCalendrierId] = useState('')
    const [dossiers, setDossiers] = useState([])
    const [motif_renvoie, setMotifRenvoie] = useState('')
    const [observation, setObservation] = useState('')
    const [statuts, setStatuts] = useState([])
    const [statut_id, setStatut_id] = useState('')
    const [chambre_id, setChambre_id] = useState('')
    const [chambres, setChambres] = useState([])
    const [calendriers_available, setCalendriersAvailable] = useState([]);
    const [calendriers, setCalendriers] = useState([]);
    const [loading, setLoading] = useState(false)
    const [reference_dossier, setReferenceDossier] = useState('')



    ///Save form data
    const handleUpdate = async (event) => {
        event.preventDefault()
        let form = {
            dossier_id, calendrier_id, chambre_id, motif_renvoie, observation, statut_id
        }
        //console.log(form)

        await DatesaudiencesService.update(location.state.ref, form, token).then((response) => {
            if (response.data.status == true) {
                //redirection sur index
                navigate(myroutes.datesaudiences_index, { state: { chambre_id: chambre_id } })
                afficherMessagesSuccess(response.data.message)
            } else {

                Swal.fire({
                    icon: "error",
                    text: response.data.message,
                    showConfirmButton: false,
                    timer: 2000
                })
                //setValidationError(response.data.erreur)
                //console.log(response.data)

            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.response.data.message
            });
            console.log(error);
            //affichage des erreurs sur le formulaire
            setValidationError(error.response.data.message)
        });
    }

    //get liste dossiers
    const fetchDossiers = async () => {
        await DossiersService.listeDossierPayer(token)
            .then((response) => {
                /*const data = response.data.data.map((item) => {
                    return {
                        name: item.reference_dossier,
                        id: item.id
                    }
                })*/

                setDossiers(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get liste chambres
    const fetchChambres = async () => {
        try {
            const response = await ChambresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setChambres(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setStatuts(filterStatut(responseData.data));
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste calendrier
    const fetchCalendriers = async () => {
        await CalendriersService.index(token).then((res) => {
            //Filtrer pour récupérer les dates qui sont à 7j au moins de la date du jour
            const currentDate = new Date();

            const filteredDates = res.data.data.filter(dateString => {
                const date = new Date(dateString.jour);
                const differenceInTime = date.getTime() - currentDate.getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                return differenceInDays >= 7;
            });


            setCalendriersAvailable(sortedDates(filteredDates));
            edit(sortedDates(filteredDates));
            //setCalendriersAvailable(res.data.data);
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            //edit(filteredDates);
        })

    }

    const handleChangeChambre = (e) => {
        setChambre_id(e.target.value)
        const filteredData = calendriers_available.filter((opt) => parseInt(opt.chambre_id) == e.target.value)
        /*const data = filteredData.map((item) => {
            return {
                name: convertDateToTexte(item.jour),
                id: item.id
            }
        })*/
        setCalendriers(filteredData)
    }

    //get edit data
    const edit = async (calendarParam) => {
        await DatesaudiencesService.edit(location.state.ref, token).then((response) => {
            const data = response.data.data
            setDossier_id(data.dossier_id)
            setCalendrierId(data.calendrier_id)
            setMotifRenvoie(data.motif_renvoie)
            setObservation(data.observation)
            setStatut_id(data.statut_id)
            setChambre_id(data.chambre_id)
            setReferenceDossier(data.get_dossier?.reference_dossier)

            const filtered = calendarParam.filter((opt) => parseInt(opt.chambre_id) == data.chambre_id)

            setCalendriers(filtered)

        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message
            })
            setValidationError(error.erreur)
        });
    }

    useEffect(() => {
        fetchCalendriers();
        //fetchDossiers();
        fetchStatuts()
        fetchChambres();

        $("#datesaudiences-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: false, libelle: "Date d'audience", title: "Audiences" }} routeName={myroutes.datesaudiences_index} />


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Edition du dossier</h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>

                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleUpdate}>

                                    <Row>
                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Numero du dossier<i className="text-danger">*</i></Form.Label>
                                                <InputText readOnly id="reference_dossier" disabled className="form-control"
                                                    type="text" name="reference_dossier" value={reference_dossier} />
                                                <InputText readOnly id="dossier_id" disabled
                                                    type="hidden" name="dossier_id" value={dossier_id} />

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Chambre<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="chambre_id"
                                                    name="chambre_id"
                                                    value={chambre_id}
                                                    required
                                                    onChange={(e) => {
                                                        handleChangeChambre(e)
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {chambres.length > 0 &&
                                                        chambres.map((item) => (
                                                            <option key={item.id} value={item.id} calendrier_id={item.calendrier_id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Calendrier<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="calendrier_id"
                                                    name="calendrier_id"
                                                    value={calendrier_id}
                                                    required
                                                    onChange={(e) => {
                                                        setCalendrierId(e.target.value)
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {calendriers.length > 0 &&
                                                        calendriers.map((item) => (
                                                            <option key={item.id} value={item.id} >
                                                                {convertDateToTexte(item.jour)}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Statut <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="statut_id" name="statut_id" value={statut_id}
                                                    required
                                                    onChange={(e) => {
                                                        setStatut_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {statuts.length > 0 &&
                                                        statuts.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Motif de renvoi  <i className="text-danger"></i> <i className="text-info bi bi-question-circle-fill" title="Motif de renvoi du dossier"></i>
                                                </Form.Label>
                                                <Editor value={motif_renvoie} onTextChange={(e) => setMotifRenvoie(e.htmlValue)} style={{ height: '100px' }} />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Observation  <i className="text-danger"></i>
                                                </Form.Label>
                                                <Editor value={observation} onTextChange={(e) => setObservation(e.htmlValue)} style={{ height: '100px' }} />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.datesaudiences_index)}>Annuler</Button>
                                </Form>


                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}