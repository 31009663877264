import React, { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../include/layout";
import { Steps } from 'primereact/steps';
import StepForm1 from "../../../components/StepForm1";
import StepForm2 from "../../../components/StepForm2";
import StepForm3 from "../../../components/StepForm3";
import StepForm4 from "../../../components/StepForm4";
import { myroutes } from "../../../routes/webroute";
import DossiersService from "../../../services/DossiersService";
import Swal from "sweetalert2";
import PartiesService from "../../../services/PartiesService";
import DossierspartiesdocumentsService from "../../../services/DossierspartiesdocumentsService";
import { afficherMessagesErreur, afficherMessagesSuccess, afficherOneMessageError } from "../../../utils/helpers";

export default function SaisinesCreate() {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [selectedOptionAssujetties, setSelectedOptionAssujetties] = useState([]);
  const [selectedOptionRepresentePar, setSelectedOptionRepresentePar] = useState([]);
  const [selectedOptionAssujettiesdefendeur, setSelectedOptionAssujettiesdefendeur] = useState([]);
  const [selectedOptionRepresentePardefendeur, setSelectedOptionRepresentePardefendeur] = useState([]);
  const [assujetties, setAssujetties] = useState([]);
  const [assujetties_acteurs, setAssujetties_acteurs] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [is_avocat, setIsAvocat] = useState(null);
  const [files, setFiles] = useState("")
  const [dossierId, setDossierId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formData, setFormData] = useState({
    reference_dossier: '',
    reference_jugement: '',
    objet: '',
    date_jugement: '',
    ancienne_reference: '',
    categorie_id: '',
    categorie_dossier_id: '',
    nature_affaire: '',
    statut_dossier_id: '',
    numero_greffe: '',
    test: '',
    path: '',
  });
  const [data, setData] = useState({
    dossier_id: '',
    is_avocat: '',
    assujettie_id: [],
  });

  const [demandeurs, setDemandeurs] = useState([]);
  const [representants, setRepresentants] = useState([]);

  // Ajoutez un état pour la validation
  const [validation, setValidation] = useState({
    reference_jugement: true,
    date_jugement: true,
    categorie_dossier_id: true,
    categorie_id: true,
  });

  const [path, setPath] = useState("");
  const [paths, setPaths] = useState([]);
  

  const handleFileChange = (selectedFiles) => {
    // reset path
    //setPaths([]);
    setPaths([...paths, ...selectedFiles]);
    console.log("--- info path file ----")
    console.log(paths.length)
    console.log("--- info path file ----")
    //setPath(file); // Met à jour le chemin du fichier dans le composant parent
  };


  /* const handleFileChange = (file) => {
     // Ajoute le nouveau fichier au tableau des chemins
     setPaths([...paths, file]);
   };*/

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Fonction pour mettre à jour les assujettis sélectionnés dans le formulaire
  /* const handleSelectedAssujettiesChange = (selectedAssujetties) => {
     setFormData((prevData) => ({ ...prevData, selectedOptionAssujetties: selectedAssujetties }));
   };*/



  const handleSaveStep1 = async () => {
    try {
      // Save the form data to the state
      setFormData((prevData) => ({ ...prevData, ...formData }));

      // Submit the form data to the API and get the response
      const response = await DossiersService.store(formData, token);

      if (response.data.status == true) {
        // Récupérer l'ID du dossier à partir de la réponse
        const dossierId = response.data.dossier_id;
        // Show success message 
        afficherMessagesSuccess("Merci! Le dossier a été enregistré, veuillez le compléter .")

        // navigate(myroutes.dossiers_index)

        // Mettez à jour l'état du dossier ID
        setDossierId(dossierId);
      }


    } catch (error) {

      const dataError = error.response.data;

      // Appeler la fonction pour afficher les messages d'erreur
      afficherMessagesErreur(dataError.error);
      //console.error("Erreur lors de l'enregistrement du dossier :", error);
      throw error;
    }
  };



  // Définition de la fonction pour mettre à jour les représentants sélectionnés
  const handleSelectedRepresenteParChange = (selectedOptionRepresentePar) => {
    // setIsAvocat(1);
    setSelectedOptionRepresentePar(selectedOptionRepresentePar);
  };

  const handleSelectedAssujettiesChange = (selectedOptionAssujetties) => {
    setIsAvocat(0);
    setSelectedOptionAssujetties(selectedOptionAssujetties);
  };



  const handleSaveStep2 = async () => {

    try {
      // Vérifier si des options assujetties sont sélectionnées
      if (!selectedOptionAssujetties || selectedOptionAssujetties.length === 0) {
        // Si aucune option n'est sélectionnée, afficher un message d'erreur
        throw new Error("Veuillez sélectionner au moins une option pour 'Nom du demandeur'.");
      }

      // Initialiser type_partie_id à 1
      const type_partie_id = 1;


      // Convertir le tableau de selectedOptionAssujetties en un tableau d'IDs
      const assujettieIds = selectedOptionAssujetties;

      // console.log(assujettieIds);

      // Convertir le tableau d'IDs en une chaîne de caractères séparée par des virgules
      const assujettieIdsString = assujettieIds;

      // Convertir le tableau de selectedOptionAssujetties en un tableau d'IDs
      const representesIds = selectedOptionRepresentePar;

      // console.log(representesIds);

      // Convertir le tableau d'IDs en une chaîne de caractères séparée par des virgules
      const representesIdsString = representesIds;

      //Récupérer les demandeurs et leurs représentants sélectionnés
      setDemandeurs(assujettieIds)
      setRepresentants(representesIds)

      // Soumettre les données à l'API avec les IDs récupérés
      const response = await PartiesService.store({ dossier_id: dossierId, assujettie_id: assujettieIdsString, represente_id: representesIdsString, is_avocat: is_avocat, type_partie_id: type_partie_id }, token);
      if (response.data.status == true) {
        // Afficher un message de réussite
        // Show success message 
        afficherMessagesSuccess("Vous venez d'ajouter les demandeurs.")

      } else {
        afficherOneMessageError(response.data.message)
      }
      //console.log(response.data);

    } catch (error) {

      const dataError = error.response.data;

      // Appeler la fonction pour afficher les messages d'erreur
      afficherMessagesErreur(dataError.error);
      throw error;

    }
  };


  const handleSaveStep3 = async () => {
    try {
      // Vérifier si des options assujetties sont sélectionnées
      if (!selectedOptionAssujetties || selectedOptionAssujetties.length === 0) {
        // Si aucune option n'est sélectionnée, afficher un message d'erreur
        throw new Error("Veuillez sélectionner au moins une option pour 'Nom du demandeur'.");
      }
      // Initialiser type_partie_id à 1
      const type_partie_id = 2;

      // Convertir le tableau de selectedOptionAssujetties en un tableau d'IDs
      const assujettieIds = selectedOptionAssujetties;

      // console.log(assujettieIds);

      // Convertir le tableau d'IDs en une chaîne de caractères séparée par des virgules
      const assujettieIdsString = assujettieIds;

      // Convertir le tableau de selectedOptionAssujetties en un tableau d'IDs
      const representesIds = selectedOptionRepresentePar;

      // console.log(representesIds);

      // Convertir le tableau d'IDs en une chaîne de caractères séparée par des virgules
      const representesIdsString = representesIds;

      //      console.log("Valeur de is_avocat avant l'envoi au backend :", is_avocat);

      // Soumettre les données à l'API avec les IDs récupérés
      const response = await PartiesService.store({ dossier_id: dossierId, assujettie_id: assujettieIdsString, represente_id: representesIdsString, is_avocat: is_avocat, type_partie_id: type_partie_id }, token);
      //console.log(response);
      if (response.data.status === true) {
        // Afficher un message de réussite
        // Show success message 
        afficherMessagesSuccess("Vous venez d'ajouter les défendeurs.")

      } else {
        Swal.fire({
          icon: "error",
          text: "Une erreur s'est produite lors de l'enregistrement",
          showConfirmButton: true,
          timer: 3000,
        });

      }

    } catch (error) {
      // En cas d'erreur, afficher un message d'erreur
      console.error("Erreur lors de l'enregistrement de la partie :", error.message);

      const dataError = error.response.data;

      // Appeler la fonction pour afficher les messages d'erreur
      afficherMessagesErreur(dataError.error);

      throw error;

    }
  };


  const handleSaveStep4 = async () => {
    try {
      const formData = new FormData();
      /* console.log("--- store file ----")
       console.log(paths)
       console.log("--- store file ----")*/
      paths.forEach((file, index) => {
        formData.append(`path[${index}]`, file);
      });
      //formData.append('path[]', paths);  
      formData.append('dossier_id', dossierId);

      const response = await DossierspartiesdocumentsService.store(formData, token);
      if (response.data.status === true) {
        // Afficher un message de réussite
        Swal.fire({
          icon: "success",
          title: "Merci !",
          text: "L'ajout du dossier est effective.",
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Une erreur s'est produite lors de l'enregistrement",
          showConfirmButton: true,
          timer: 3000,
        });
      }
      navigate(myroutes.dossiers_index)
    } catch (error) {
      // En cas d'erreur, afficher un message d'erreur
      console.log(error)
      console.error("Erreur lors de l'envoi du fichier :", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: error.message || 'Une erreur s\'est produite lors de l\'envoi du fichier. Veuillez réessayer.'
      });

      throw error;

    }
  };


  const validateStep = (step) => {
    let isValid = true;

    // Vérifiez les données de l'étape en fonction du numéro d'étape
    if (step === 0) {
      // Validez les données de la première étape
      isValid = formData.reference_jugement && formData.date_jugement && formData.categorie_id;

    } else if (step === 1) {
      // Validez les données de la deuxième étape
      // isValid = data.assujettie_id;
      isValid = selectedOptionAssujetties.length > 0 && selectedOptionRepresentePar.length > 0;

    } else if (step === 2) {
      // Validez les données de la deuxième étape
      // isValid = data.assujettie_id;
      isValid = selectedOptionAssujetties.length > 0 && selectedOptionRepresentePar.length > 0;

    }
    // Ajoutez des conditions pour d'autres étapes si nécessaire

    return isValid;
  };


  const handleSaveAndProceed = async () => {
    try {
      // Enregistrez les données spécifiques à chaque étape
      if (currentStep === 0) {
        await handleSaveStep1();
      } else if (currentStep === 1) {
        await handleSaveStep2();
      } else if (currentStep === 2) {
        await handleSaveStep3();
      } else if (currentStep === 3) {
        await handleSaveStep4();
      }

      // Vérifiez si l'étape actuelle est valide avant de passer à l'étape suivante
      if (currentStep === 0 || currentStep === 1 || currentStep === 2) {
        const isValid = validateStep(currentStep);
        if (!isValid) {
          // Afficher un message de réussite
          Swal.fire({
            icon: 'Erreur',
            title: 'Attention !',
            text: 'Vous devez enregistrer les champs obligatoires avant de passer au suivant .'
          });
          return; // Restez sur la même page si l'étape actuelle n'est pas valide
        }
      }

      // Passez à l'étape suivante si nécessaire
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };



  const items = [
    { label: 'Renseignements sur l\'affaire' },
    { label: 'Demandeur(s)' },
    { label: 'Défendeur(s)' },
    { label: 'Pièces jointes' }
  ];

  useEffect(() => {
    // console.log("is_avocat:", is_avocat);
  }, [selectedOptionAssujetties, selectedOptionRepresentePar, is_avocat]);

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Gestion des dossiers / Enrôlement d'un dossier</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to={myroutes.dossiers_index}>Dossiers</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="p-2" style={{ height: "80px", border: "1px solid #d9e6ea4a", backgroundColor: "#d9e6ea4a", fontSize: "12px", color: "", lineHeight: '1.6', fontWeight: "500" }}>
              <ul>
                <li>Veuillez remplir tous les champs obligatoires avant de passer à l'étape suivante.</li>
                <li>Merci de bien vérifier  les  données à soumettre.</li>
              </ul>
            </div>
            <div className="row g-3 mb-0 align-items-center justify-content-between">
              <div className="card bg-success">

              </div>
            </div>
            <div className="card pt-3 p-3" style={{ marginTop: "-25px" }}>
              <Steps model={items} activeIndex={currentStep} />
            </div>
            <div className="app-card app-card-settings shadow-lg mb-3" style={{ marginTop: "-25px" }}>
              <div className="app-card-body py-1 pt-2">
                {currentStep === 0 && (
                  <StepForm1 formData={formData} onChange={handleInputChange} onNextStep />
                )}
                {currentStep === 1 && (
                  <StepForm2
                    data={data}
                    handleSelectedAssujettiesChange={handleSelectedAssujettiesChange}
                    handleSelectedRepresenteParChange={handleSelectedRepresenteParChange}
                  />
                )}
                {currentStep === 2 && (
                  <StepForm3
                    data={data}
                    demandeurs={demandeurs}
                    representants={representants}
                    handleSelectedAssujettiesChange={handleSelectedAssujettiesChange}
                    handleSelectedRepresenteParChange={handleSelectedRepresenteParChange}
                  />)}
                {currentStep === 3 && (
                  <StepForm4 onFileChange={handleFileChange} />
                )}

                <div className="p-3" style={{ textAlign: "right" }}>
                  <Button
                    className="me-3 x-2"
                    variant="warning"
                    onClick={handlePreviousStep}
                    hidden={currentStep === 0}
                  >
                    Précédent
                  </Button>
                  <Button
                    variant="warning"
                    onClick={
                      currentStep === items.length - 1 // Vérifiez si c'est la dernière étape
                        ? handleSaveStep4 // Si oui, appelez la fonction de sauvegarde de la dernière étape
                        : handleSaveAndProceed // Sinon, appelez la fonction pour sauvegarder et passer à l'étape suivante
                    }
                  >
                    {currentStep === items.length - 1 ? "Terminer" : "Suivant"} {/* Texte du bouton basé sur la dernière étape */}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
