import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../backoffice/admin/include/layout";
import { myroutes } from "../../../routes/webroute";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import DossiersService from "../../../services/DossiersService";
import { Editor } from "primereact/editor";
import CalendriersService from "../../../services/CalendriersService";
import ChambresService from "../../../services/ChambresService";
import { convertDateToTexte, sortedDates } from '../../../utils/helpers';
import { Dropdown } from "primereact/dropdown";
import { ProgressSpinner } from "primereact/progressspinner";
import StatutsdossiersService from '../../../services/StatutsdossiersService';

export default function DatesaudiencesCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)
    const [dossier_id, setDossier_id] = useState('')
    const [calendrier_id, setCalendrier_id] = useState('')
    const [dossiers, setDossiers] = useState([])
    const [chambre_id, setChambre_id] = useState('')
    const [motif_renvoie, setMotifRenvoie] = useState('')
    const [observation, setObservation] = useState('')
    const [chambres, setChambres] = useState([])
    const [calendriers_available, setCalendriersAvailable] = useState([]);
    const [calendriers, setCalendriers] = useState([]);
    const [loading, setLoading] = useState(false)

    const [statutsdossiers, setStatutsdossiers] = useState([]);
    const [statut_dossier_id, setStatutDossierId] = useState('')

    //get liste statuts dossiers
    const fetchStatutsdossiers = async () => {
        await StatutsdossiersService.index(token).then((res) => {
            setStatutsdossiers(res.data.data)
            // console.log("res.data.data", res.data.data)
        }).catch(error => {
            console.log(error)
        })
    }

    //get liste dossiers
    const fetchDossiers = async () => {
        await DossiersService.listeDossierPayer(token)
            .then((response) => {
                const data = response.data.data.map((item) => {
                    return {
                        name: item.reference_dossier,
                        id: item.id
                    }
                })
                setDossiers(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

  
    //get liste chambres
    const fetchChambres = async () => {
        try {
            const response = await ChambresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setChambres(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };


    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            dossier_id : dossier_id.id, calendrier_id : calendrier_id.id, chambre_id, motif_renvoie, observation, statut_dossier_id
        }
        setLoading(true)

        await DatesaudiencesService.store(form, token)
            .then((response) => {
                setLoading(false)
                if (response.status == 200) {
                    navigate(myroutes.datesaudiences_index)
                    //alert ("ok")
                    Swal.fire({
                        icon: "success",
                        text: response.data.message,
                        timer: 3000,
                    });
                } else {
                    //redirection sur index
                    Swal.fire({
                        icon: "error",
                        text: response.data.message + ' ' + response.data.data,
                        showConfirmButton: true,
                        timer: 3000,
                    });
                }
            }).catch(error => {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
                //affichage des erreurs sur le formulaire
                setValidationError(error.response.data.message)
            });
    }

    //get liste calendrier
    const fetchCalendriers = async () => {
        await CalendriersService.index(token).then((res) => {
            //Filtrer pour récupérer les dates qui sont à 7j au moins de la date du jour
            const currentDate = new Date();

            const filteredDates = res.data.data.filter(dateString => {
                const date = new Date(dateString.jour);
                const differenceInTime = date.getTime() - currentDate.getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                return differenceInDays >= 7;
            });
           
            setCalendriersAvailable(sortedDates(filteredDates));
            //setCalendriersAvailable(res.data.data);
        }).catch(error => {
            console.log(error)
        })
    }

    const handleChangeChambre = (e) => {
        setChambre_id(e.target.value)
        const filteredData = calendriers_available.filter((opt) => parseInt(opt.chambre_id) == e.target.value)
        const data = filteredData.map((item) => {
            return {
                name: convertDateToTexte(item.jour),
                id: item.id
            }
        })
        setCalendriers(data)
    }



    useEffect(() => {
        fetchDossiers();
        fetchCalendriers();
        fetchChambres();
        fetchStatutsdossiers();
        $("#datesaudiences-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Date d'audiences </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Editer un dossier </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                    <Row>
                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Numero du dossier<i className="text-danger">*</i></Form.Label>
                                                <Dropdown value={dossier_id} onChange={(e) => setDossier_id(e.value)} options={dossiers} optionLabel="name" placeholder="Rechercher..."
                                                    filter className="w-100" />
                                            </Form.Group>

                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Chambre<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="chambre_id"
                                                    name="chambre_id"
                                                    value={chambre_id}
                                                    required
                                                    onChange={(e) => {
                                                        handleChangeChambre(e)
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {chambres.length > 0 &&
                                                        chambres.map((item) => (
                                                            <option key={item.id} value={item.id} calendrier_id={item.calendrier_id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Calendrier<i className="text-danger">*</i></Form.Label>
                                                <Dropdown value={calendrier_id} onChange={(e) => setCalendrier_id(e.value)} options={calendriers} optionLabel="name" placeholder="Rechercher..."
                                                    filter className="w-100" />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Statut du dossier<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="statut_dossier_id"
                                                    name="statut_dossier_id"
                                                    value={statut_dossier_id}
                                                    required
                                                    onChange={(e) => {
                                                        setStatutDossierId(e.target.value)
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {statutsdossiers.length > 0 &&
                                                        statutsdossiers.map((item) => (
                                                            <option key={item.id} value={item.id} >
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>


                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Motif de renvoi  <i className="text-danger"></i> <i className="text-info bi bi-question-circle-fill" title="Motif de renvoi du dossier"></i>
                                                </Form.Label>
                                                <Editor value={motif_renvoie} onTextChange={(e) => setMotifRenvoie(e.htmlValue)} style={{ height: '100px' }} />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Observation  <i className="text-danger"></i>
                                                </Form.Label>
                                                <Editor value={observation} onTextChange={(e) => setObservation(e.htmlValue)} style={{ height: '100px' }} />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.datesaudiences_index)}>Annuler</Button>
                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}
