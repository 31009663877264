import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import CalendriersGlobalsService from "../../../../services/CalendriersGlobalsService";
import StatutsService from "../../../../services/StatutsService";
import { filterStatut } from "../../../../utils/helpers";

export default function CalendriersGlobalsEdit() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [annee, setAnnee] = useState('')
    const [date_debut, setDateDebut] = useState('')
    const [date_fin, setDateFin] = useState('')
    const [statut_id, setStatut_id] = useState('')
    const [statuts, setStatuts] = useState([])

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                
                setStatuts(filterStatut(responseData.data));
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    ///Save form data
    const handleUpdate = async (event) => {
        event.preventDefault()
        let form = {
            annee, date_debut, date_fin, statut_id
        }

        await CalendriersGlobalsService.update(location.state.ref, form, token).then((response) => {
            if (response.data.status !== true) {
                Swal.fire({
                    icon: "error",
                    text: response.data.message + ' ' + response.data.data,
                    showConfirmButton: false,
                    timer: 2000
                })
                setValidationError(response.data.message)
            } else {
                //redirection sur index
                navigate(myroutes.calendriersglobals_index)
                Swal.fire({
                    icon: "success",
                    text: "Votre modification a été effectuée avec succès",
                    timer: 3000,
                });
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message
            })
            //affichage des erreurs sur le formulaire
            setValidationError(error.response.data.message)
            //console.log(error.response)
        });
    }

    const edit = async () => {
        try {
            const response = await CalendriersGlobalsService.edit(location.state.ref, token);
            //console.log("API response", response);
            if (response.data) {
                const data = response.data.data;
                // console.log("data", data);
                setAnnee(data.annee);
                setDateDebut(data.date_debut);
                setDateFin(data.date_fin);
                setStatut_id(data.statut_id);

            } else {
                console.log("Data not found in API response");
                // Gérer le cas où response.data ou response.data.statut est undefined ou null
            }
        } catch (error) {
            console.error("API error", error);
            Swal.fire({
                icon: "error",
                text: error.message
            });
            setValidationError(error.erreur);
        }
    };





    useEffect(() => {
        edit();
        fetchStatuts();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">


                        <BreadcrumbComponent params={{ isParametre: true, libelle: 'Calendriers Globals' }} routeName={myroutes.calendriersglobals_index}></BreadcrumbComponent>


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Modification </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleUpdate}>
                                    <Row>

                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date début<i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setDateDebut(e.target.value)}
                                                    value={date_debut} placeholder="" name="date_debut" aria-label="date_debut" maxLength={100}
                                                    minLength={3} type="date" required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date fin<i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setDateFin(e.target.value)}
                                                    value={date_fin} placeholder="" name="date_fin" aria-label="date_fin" maxLength={100}
                                                    minLength={3} type="date" required
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Annee juridique<i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setAnnee(e.target.value)}
                                                    value={annee}
                                                    placeholder="2023-2024"
                                                    name="annee"
                                                    aria-label="annee"
                                                    maxLength={9}
                                                    minLength={9}
                                                    type="text"
                                                    required

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Statut <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="statut_id"
                                                    name="statut_id"
                                                    value={statut_id}
                                                    // required
                                                    onChange={(e) => {
                                                        setStatut_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {statuts.length > 0 &&
                                                        statuts.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.calendriersglobals_index)}>Annuler</Button>
                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}