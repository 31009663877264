import { Link, useNavigate } from "react-router-dom";
import Layout from "../../include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import UtilisateursService from "../../../../services/UtilisateursService";
import ChambresmembresService from "../../../../services/ChambresmembresService";
import ChambresService from "../../../../services/ChambresService";

export default function ChambresmembresCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [chambre_id, setChambre_id] = useState('')
    const [chambres, setChambres] = useState([])
    const [utilisateur_id, setUtilisateur_id] = useState('')
    const [utilisateurs, setUtilisateurs] = useState('')
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('')

    //get liste chambres
    const fetchChambres = async () => {
        await ChambresService.index(token)
            .then((response) => {
                setChambres(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get liste utilisateurs
    const fetchUtilisateurs = async () => {
        await UtilisateursService.index(token)
            .then((response) => {
                setUtilisateurs(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            chambre_id, utilisateur_id, libelle, description
        }

        await ChambresmembresService.store(form, token).then((response) => {
            if (response.data.status == true) {
                navigate(myroutes.chambresmembres_index)
                Swal.fire({
                    icon: "success",
                    text: "Votre enregistrement a été effectué avec succès",
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.message + ' ' + response.data.data,
                    showConfirmButton: true,
                    timer: 3000,
                });
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message,
            });
            console.log(error);
        });
    }



    useEffect(() => {
        fetchChambres();
        fetchUtilisateurs();
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Chambres membres </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Enregistrement </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Type Chambre<i className="text-danger">*</i></Form.Label>
                                                <Form.Select className="" id="type_chambre_id" name="type_chambre_id" value={chambre_id}
                                                    required onChange={(e) => { setChambre_id(e.target.value); }}>
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {chambres.length > 0 &&
                                                        chambres.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Utilisateurs<i className="text-danger">*</i></Form.Label>
                                                <Form.Select className="" id="utilisateur_id" name="utilisateur_id" value={utilisateur_id}
                                                    required onChange={(e) => { setUtilisateur_id(e.target.value); }}>
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {utilisateurs.length > 0 &&
                                                        utilisateurs.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.nom}{"  "}{item.prenoms}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                    </Row>


                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.chambresmembres_index)}>Annuler</Button>

                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}