import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import dayjs from "dayjs";
import $ from "jquery"
import { convertDateToTexte } from "../utils/helpers";
import ChambresService from "../services/ChambresService";
import parse from "html-react-parser"




const TabAudiences = ({ dossier, calendrierprocedure }) => {
    const token = useSelector(state => state.auth.token)
    const [chambres, setChambres] = useState([])


    //get liste chambres
    const fetchChambres = async () => {
        await ChambresService.index(token).then((res) => {
            setChambres(res.data.data);
        }).catch(error => {
            console.log(error)
        })
    }

    const getChambre = (chambreId) => {
        let chambre = chambres.find(opt => parseInt(opt.id) === chambreId);
        //console.log(chambreId)
        if (chambre) return chambre.libelle
    }

    const sortedData = (data) => {
        if (data.length > 0) {
            const sortedData = [...data].sort((a, b) => b.id - a.id);
            return sortedData;
        }
    };

   
    useEffect(() => {
        fetchChambres()
        //$('.dataTable').DataTable();
    }, []);



    return (
        <div className="container">
            <div className="row">
                <div className="row mb-0  align-items-center justify-content-between">
                    <div className="card bg-warningx pt-1" style={{ color: "#1f84d6", backgroundColor: "#1f84d621", border: "1px solid white" }}>
                        <h6 className="">
                            <i className="fa fa-calendar me-2"></i>DATES D'AUDIENCES
                        </h6>
                    </div>
                </div>
                {dossier && dossier.get_date_audience && dossier.get_date_audience.length > 0 ? (
                    sortedData(dossier.get_date_audience).map((dateAudience, index) => (
                        <div key={index} className="row border-ligne mt-2">
                            <div className="col-md-3">
                                <div className="th-table d-flex">
                                    <i className="fa fa-calendar me-3" style={{ fontSize: "20px", color: "#1f84d6" }}></i>
                                    <p className="th-tablex fw-boldx">
                                        {dateAudience.get_calendrier && convertDateToTexte(dateAudience.get_calendrier.jour)}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="th-table d-flex">
                                    <p className="th-tablex">
                                        {getChambre(dateAudience.chambre_id)}
                                    </p>
                                </div>
                            </div>

                            {dateAudience.motif_renvoie ?
                                <><div className="col-md-5">
                                    <div className="th-table d-flex">
                                        <b>Motif de renvoi : </b>&nbsp;
                                        <p className="th-tablex">
                                            {parse(dateAudience.motif_renvoie)}
                                        </p>
                                    </div>
                                </div>
                                </>
                                : (dateAudience.observation && <><div className="col-md-5">
                                    <div className="th-table d-flex">
                                        <b>Observation : </b>&nbsp;
                                        <p className="th-tablex">
                                            {parse(dateAudience.observation)}
                                        </p>
                                    </div>
                                </div>
                                </>)}

                        </div>
                    ))
                ) : (
                    <div className="row border-ligne mt-3">
                        <div className="col-md-11">
                            <div className="th-table d-flex">
                                <p className="th-tablex fw-boldx">Aucune date d'audience disponible</p>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );

}


export default TabAudiences;