import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import $ from "jquery"
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'
import AssujettiesService from "../../../services/AssujettiesService";
import CivilitesService from "../../../services/CivilitesService";
import LayoutActeur from "../inc/layout_acteur";


export default function AssujettiesIndexActeur() {

    //##########################/// METHODES ##########################///
    const [statuts, setStatuts] = useState([])
    const [pays, setPays] = useState([])
    const [assujetties, setAssujetties] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const [visible, setVisible] = useState(false);
    const [paysTrouve, setPaysTrouve] = useState([]);
    const [statutTrouve, setStatutTrouve] = useState([]);
    const [civilites, setCivilites] = useState([]);


     //get liste statuts
     const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const statutsData = response.data.data;
            setStatuts(statutsData);
        } catch (error) {
            console.error("Erreur lors de la récupération  :", error);
        }
    };
    

    const getStatuts = (statut_id) => {
        // Convertir l'ID en nombre (si nécessaire)
        const idToFind = Number(statut_id);
    
        // Recherchez le libellé dans la liste des catégories de dossiers
        const Statut = statuts.find((item) => {
            return item.id == idToFind; 
        });
    
        return Statut ? Statut.libelle : "N/A";
    };


    //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCivilites = (civilite_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(civilite_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Civilite = civilites.find((item) => {
        return item.id == idToFind; 
    });

    return Civilite ? Civilite.libelle : "N/A";
};
   
      
    //get liste assujetties
    const fetchAssujetties = async () => {
        try {
          const response = await AssujettiesService.index(token);
          const responseData = response.data;
           // console.log("responseData", responseData)
          if (responseData.data && responseData.data.length > 0) {
            setAssujetties(responseData.data);
            console.log("responseData.data", responseData.data)
          } else {
            console.error("Erreur dans la réponse:", responseData.message);
          }
        } catch (error) {
          console.error("Erreur lors de la requête de statuts:", error);
        }
      
        // initialize datatable
        $(document).ready(function () {
          $('.data-table').DataTable();
        });
      };
      

    //redirection sur la page edit sans afficher les id dans l'url
    const goToEdit = (param) => {
        navigate(myroutes.assujetties_edit, { state: { ref: param } })
    }

    //suppression d'un élément
    const onDelete = (param) => {
        //confirm action
        Swal.fire({
            icon: "warning",
            text: "Voulez-vous confirmer cette action ?",
            showCancelButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui",
            confirmButtonColor: "red"
        }).then(result => {
            if (result.isConfirmed) {
                AssujettiesService.delete(param,token).then((response) => {
                    if (response.data.erreur) {
                        Swal.fire({
                            icon: "error",
                            text: response.data.erreur
                        })
                    } else {
                        Swal.fire({
                            icon: "success",
                            text: response.data.message
                        })
                        fetchAssujetties()
                    }
                }).catch(e => {
                    Swal.fire({
                        icon: "error",
                        text: e.message
                    })
                    console.log(e)
                })
            }
        })
    }

    useEffect(() => {
        fetchAssujetties();
        fetchStatuts();
        fetchCivilites();
        $("#submenu-4").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///


    return (
        <LayoutActeur>
              <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                <div className="container-xl app-card-body">

                    <div className="row g-3 align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="pagetitle">
                                <h1 className="app-page-title mb-0">Paramètres</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                        <li className="breadcrumb-item active">Assujetties </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/*//row*/}

                    <div className="row g-3 mb-4 align-items-center justify-content-between">
                        <div className="col-auto">
                            <h3 className="app-page-title mb-0">Liste des assujetties <Link className="btn btn-success btn-sm" to={myroutes.assujetties_create} title="Ajouter"><i className="bi bi-plus"></i>Ajouter</Link> </h3>
                        </div>
                    </div>{/*//row*/}

                    <div className="app-card app-card-orders-table shadow-lg mb-5">
                        <div className="app-card-body mx-3 py-2">
                            <div className="table-responsive mt-4">
                                
                                <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                                    <thead className="table-info mt-2">
                                        <tr>
                                            <th className="">N°</th>
                                            <th className="">Raison Sociale </th>
                                            <th className="">Nom & Prénom (s)</th>
                                            <th className="">Email</th>
                                            <th className="">Adresse</th>
                                            <th className="">Téléphone</th>
                                            <th className="">RCCM</th>
                                            <th className="">IFU</th>
                                            <th className="">AC (Oui/Non)</th>
                                            <th className="">statut</th>
                                            <th className="">Date modification</th>
                                            <th className="">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                                        {assujetties.length > 0 && assujetties.map((item, index) => (
                                            <tr key={index}>
                                            <td className="cell">{index + 1}</td>
                                            <td className={`cell ${!item.raison_sociale ? 'N/A' : ''}`}>
                                                {item.raison_sociale}
                                            </td>
                                            <td className="cell"><span className="fw-bold">{getCivilites(item.civilite_id)}</span> {item.nom} {item.prenoms}</td>
                                            <td className="cell">{item.email}</td>
                                            <td className="cell">{item.adresse}</td>
                                            <td className="cell">{item.telephone}</td>
                                            <td className="cell">{item.rccm}</td>
                                            <td className="cell">{item.ifu}</td>
                                            <td className="cell">{item.is_acteur === 1 ? 'oui' : 'non'}</td>
                                            <td className="cell">{getStatuts(item.statut_id)}</td>
                                            <td className="cell">
                                                <span>{dayjs(item.updated_at).format("DD/MM/YYYY H:m:s")}</span>
                                            </td>
                                            <td className="cell">
                                                <div className="d-flex">
                                                <Button className="btn btn-sm btn-primary" onClick={() => goToEdit(item.ref)} title="Modifier">
                                                <i className="bi bi-pen-fill"></i>
                                                </Button> &nbsp;&nbsp;
                                                <Button className="btn btn-sm btn-danger" onClick={() => onDelete(item.ref)} title="Supprimer">
                                                <i className="bi bi-trash"></i>
                                                </Button>
                                                </div>
                                                
                                            </td>
                                            </tr>
                                        ))}
                                        </tbody>

                                </table>
                            </div>{/*//table-responsive*/}

                        </div>{/*//app-card-body*/}
                    </div>
                    {/*//app-card*/}

                    {/*//End table-content*/}



                </div>{/*//container-fluid*/}
            </div>
           
            </main>
          {/*//app-content*/}
        </LayoutActeur>
    )
}
