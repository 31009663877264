import http from "./http"

class AdministrationService {
    clear_cache(token){
        return http.get("/api/v01/web/ClearCache",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    active_storage(token){
        return http.get("/api/v01/web/ActiveStorage",{ headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new AdministrationService()