import { Link, useNavigate } from "react-router-dom";
import Layout from "../../include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import CalendriersGlobalsService from "../../../../services/CalendriersGlobalsService";
import CalendriersService from "../../../../services/CalendriersService";
import ChambresService from "../../../../services/ChambresService";
import { faJournalWhills } from "@fortawesome/free-solid-svg-icons";
import { convertDateToTexte } from "../../../../utils/helpers";

export default function CalendriersCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)


    //Creation des ascesseurs getters et setters pour tout le formulaire

    const [calendrier_global_id, setCalendrierglobal_id] = useState('')
    const [calendriersglobals, setCalendriersGlobals] = useState([])
    const [chambre_id, setChambre_id] = useState('')
    const [chambres, setChambres] = useState([])
    const [jour_value, setJourValue] = useState('')
    const [selectedChambre, setSelectedChambre] = useState("");
    const jour = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    //get liste chambre_id_id
    const fetchChambres = async () => {
        try {
            const response = await ChambresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setChambres(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste calendries globals
    const fetchCalendriersGlobals = async () => {
        try {
            const response = await CalendriersGlobalsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setCalendriersGlobals(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };


    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            chambre_id, calendrier_global_id, jour: jour_value
        }

        await CalendriersService.store(form, token).then((response) => {
            //console.log(response.data)
            if (response.data.status == true) {
                navigate(myroutes.calendriers_index)
                Swal.fire({
                    icon: "success",
                    text: "Votre enregistrement a été effectué avec succès",
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.message + ' ' + response.data.data,
                    showConfirmButton: true,
                    timer: 3000,
                });
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.response.data.message
            });
            console.log(error);
        });
    }



    useEffect(() => {
        fetchChambres();
        fetchCalendriersGlobals();
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Calendrier </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Enregistrement </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                    <Row>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Chambre<i className="text-danger">*</i></Form.Label>
                                                <Form.Select className="" id="chambre_id" name="chambre_id" value={chambre_id}
                                                    required onChange={(e) => setChambre_id(e.target.value)}>
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {chambres.length > 0 && chambres.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.libelle}
                                                        </option>
                                                    ))}
                                                </Form.Select>

                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Jour<i className="text-danger">*</i></Form.Label>
                                                {/**
                                                 * <Form.Select className="" value={jour_value} id="jour" name="jour" onChange={(e) => setJourValue(e.target.value)}
                                                   >
                                                   <option value="" selected>
                                                       -- Sélectionnez --
                                                   </option>
                                                   {jour.map((jour, index) => (
                                                       <option key={index} value={jour}>{jour}</option>
                                                       ))}
                                                    </Form.Select>
                                                 */}
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setJourValue(e.target.value)}
                                                    value={jour_value}
                                                    placeholder=""
                                                    name="jour_value"
                                                    aria-label="jour_value"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="date"
                                                    required
                                                />

                                            </Form.Group>
                                        </Col>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date <i className="text-danger">*</i></Form.Label>
                                                <Form.Select className="" id="calendrier_global_id" name="calendrier_global_id" value={calendrier_global_id}
                                                    required onChange={(e) => { setCalendrierglobal_id(e.target.value); }}>
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {calendriersglobals.length > 0 &&
                                                        calendriersglobals.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {convertDateToTexte(item.date_debut) + " au " + convertDateToTexte(item.date_fin)}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                    </Row>


                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.calendriers_index)}>Annuler</Button>

                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}