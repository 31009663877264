import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { myroutes } from "../../../routes/webroute";
import DossiersService from "../../../services/DossiersService";
import StatutsdossiersService from "../../../services/StatutsdossiersService";
import { TabView, TabPanel } from 'primereact/tabview';
import TabResumeDossier from "../../../components/TabResumeDossier";
import TabProcedures from "../../../components/TabProcedures";
import TabAudiences from "../../../components/TabAudiences";
import TabFichiers from "../../../components/TabFichiers";
import TabDiscussions from "../../../components/TabDiscussions";
import $ from "jquery"
import LayoutActeur from "../inc/layout_acteur";
import TabCalendriersProcedures from "../../../components/TabCalendriersProcedures";




export default function DossiersShowActeurs() {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const { ref } = useParams(); // référence de l'url
  const [dossier, setDossier] = useState(null); // État pour stocker les détails du dossier
  const [statutsdossiers, setStatutsdossiers] = useState([])
  const [tabs, setTabs] = useState([])
  const profils = useSelector((state) => state.auth.profil);
  const [calendriers_procedures, setCalendriersProcedures] = useState(null);


  const scrollableTabs = Array.from({ length: 50 }, (_, i) => ({ title: `Tab ${i + 1}`, content: `Tab ${i + 1} Content` }))

 
  const tabsContent = [
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-card-list me-1"></i> Synthèse du dossier
        </span>
      ),
      content: <TabResumeDossier dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-exclamation"></i> Procédures
        </span>
      ),
      content: <TabProcedures dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-calendar-event me-1"></i> Celendriers des procédures
        </span>
      ),
      content: <TabCalendriersProcedures dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-book-half me-1"></i> Audiences
        </span>
      ),
      content: <TabAudiences dossier={dossier} />
    },
    {
      title: (
        <span className="title-tab">
          <i className="bi bi-file-earmark me-1"></i> Fichiers
        </span>
      ),
      content: <TabFichiers dossier={dossier} />
    },
    ...(profils.includes("AJ") ? [
      {
        title: (
          <span className="title-tab">
            <i className="bi bi-chat-quote me-1"></i> Discussions/Messages
          </span>
        ),
        content: <TabDiscussions dossier={dossier} />
      }
    ] : [])
  ];


  //get liste dossiers details
  const fetchDossierDetails = async () => {
    try {
      const response = await DossiersService.show(ref, token);
      setDossier(response.data.data.dossier);
      setCalendriersProcedures(response.data.data.calendrierprocedure)
      // alert(response.data.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des détails du dossier:", error);
    }
  };

  //get liste statuts dossiers
  const fetchStatutsdossiers = async () => {
    try {
      const response = await StatutsdossiersService.index(token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setStatutsdossiers(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };




  useEffect(() => {
    fetchDossierDetails();
    //fetchStatutsdossiers();
    $("#dossiers-nav").addClass("show")
  }, [ref]);


  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Gestion des dossiers / Détails du dossier</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard_acteur}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to={myroutes.dossiersacteurs_index}>Dossiers</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row g-3 mb-0  align-items-center justify-content-between">
              <div
                className="card bg-successx p-2" style={{ color: "#264f52", backgroundColor: "#3b7d821e", border: "1px solid white" }}>
                <div className="row">
                  <div className="col-md-9">
                    <h4 className="pt-1">
                      <i className="bi bi-caret-right-fill"></i> <span className="me-3"> {dossier && dossier.reference_dossier} </span>
                      <span className="badge text-bg-success">{dossier && dossier.get_statut_dossier && dossier.get_statut_dossier.libelle}</span>
                    </h4>
                  </div>
                  {(profils.includes("AJ")) &&
                    <>
                      <div className="col-md-3" style={{ textAlign: "right" }}>
                        <div className="dropdown">
                          <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Plus d'options
                          </button>
                          <ul className="dropdown-menu">
                            <li><Link className="dropdown-item" to={myroutes.dossiersacteurs_index}>Liste des dossiers</Link></li>
                          </ul>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="card" style={{ fontFamily: "montserrat" }}>
              <TabView scrollable>
                {tabsContent.map((tab, index) => (
                  <TabPanel key={index} header={tab.title} headerStyle={{ color: 'red' }} headerClassName="custom-tab-header">
                    {tab.content}
                  </TabPanel>
                ))}
              </TabView>
            </div>

          </div>
        </div>
      </main>
    </LayoutActeur>
  );
}
