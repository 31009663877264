import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import StatutsService from "../../../../services/StatutsService";
import $ from "jquery"
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";

export default function StatutsCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('')

    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            libelle, description
        }

        await StatutsService.store(form, token).then((response) => {
            if (response.status === 200) {
                navigate(myroutes.statuts_index)
                //alert ("ok")
                Swal.fire({
                    icon: "success",
                    text: "Bravo ! Votre enregistrement a été efectué avec succès. ",
                    timer: 3000,
                });
            } else {
                // alert (" non ok")
                //redirection sur index
                Swal.fire({
                    icon: "error",
                    text: response.data.message,
                    showConfirmButton: true,
                    timer: 3000,
                });
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message,
            });
            console.log(error);
        });
    }



    useEffect(() => {
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                    <BreadcrumbComponent params={{isParametre: true, libelle: 'Statuts'}} routeName={myroutes.statuts_index}></BreadcrumbComponent>


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Enregistrement </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                                    value={libelle}
                                                    placeholder="Libelle du statut"
                                                    name="libelle"
                                                    aria-label="libelle"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="text"
                                                    required

                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Description  <i className="text-danger"></i>
                                                </Form.Label>
                                                <textarea value={description} name="resume" id="" cols="30" onChange={(e) => setDescription(e.target.value)} rows="5" className="form-control summernote border border rounded-2" placeholder=""></textarea>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.statuts_index)}>Annuler</Button>
                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}