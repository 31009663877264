import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Editor } from "primereact/editor";
import StatutsService from "../services/StatutsService";
import { useSelector } from "react-redux";

const BasicEditForm = ({ onSubmit, routeBack, editData, typesproceduresData }) => {
    const [validationError, setValidationError] = useState({})
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const token = useSelector(state => state.auth.token)
    const [type_procedure_id, setTypeprocedureID] = useState('');

    const [formData, setFormData] = useState(editData);
    const [statuts, setStatuts] = useState([])

    useEffect(() => {
        setFormData(editData);
    }, [editData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeDescription = (e) => {
        setFormData(prevState => ({
            ...prevState,
            description: e.htmlValue
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };


    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setStatuts(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    useEffect(() => {
        fetchStatuts();
    }, []);

    return (
        <>
            <div className="row g-3 mb-4 align-items-center justify-content-between">
                <div className="col-auto">
                    <h1 className="app-page-title mb-0">Modification </h1>
                </div>
            </div>{/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
                <div className="app-card-body py-3">
                    <Row>
                        {
                            Object.keys(validationError).length > 0 && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-danger">
                                            <ul className="mb-0">
                                                {
                                                    <li>{validationError}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Row>
                    <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                        <Row>
                            {!typesproceduresData &&
                                <>
                                    <Col md={6} sm={6} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border  border rounded-2" onChange={handleChange}
                                                value={formData.libelle}
                                                placeholder=""
                                                name="libelle"
                                                aria-label="libelle"
                                                maxLength={100}
                                                minLength={2}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} sm={6} className="formx-groupx">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">
                                                Statut <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Select
                                                className="border  border rounded-2"
                                                id="statut_id"
                                                name="statut_id"
                                                value={formData.statut_id}
                                                required
                                                onChange={handleChange}
                                            >
                                                <option value="" selected>
                                                    --
                                                </option>
                                                {statuts.length > 0 &&
                                                    statuts.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.libelle}
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </>}

                            {typesproceduresData &&
                                <>
                                    <Col md={4} sm={4} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border  border rounded-2" onChange={handleChange}
                                                value={formData.libelle}
                                                placeholder=""
                                                name="libelle"
                                                aria-label="libelle"
                                                maxLength={100}
                                                minLength={2}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} sm={4} className="formx-groupx">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">
                                                Type procédure <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Select
                                                className="border  border rounded-2"
                                                id="type_procedure_id"
                                                name="type_procedure_id"
                                                value={formData.type_procedure_id}
                                                required
                                                onChange={handleChange}
                                            >
                                                <option value="" selected disabled>
                                                --  Sélectionnez une valeur --
                                                </option>
                                                {typesproceduresData.length > 0 &&
                                                    typesproceduresData.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.libelle}
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} sm={4} className="formx-groupx">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">
                                                Statut <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Select
                                                className="border  border rounded-2"
                                                id="statut_id"
                                                name="statut_id"
                                                value={formData.statut_id}
                                                required
                                                onChange={handleChange}
                                            >
                                                <option value="" selected>
                                                    --
                                                </option>
                                                {statuts.length > 0 &&
                                                    statuts.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.libelle}
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </>}
                        </Row>

                        <Row>
                            <Col md={12} sm={12} className="form-group">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Description  <i className="text-danger"></i>
                                    </Form.Label>
                                    <Editor value={formData.description || ''} onTextChange={handleChangeDescription} style={{ height: '100px' }} />

                                </Form.Group>
                            </Col>
                        </Row>

                        <Button type="submit" variant="warning me-3">Enregistrer</Button>
                        <Button variant="secondary" onClick={() => navigate(routeBack)}>Annuler</Button>

                    </Form>

                </div>{/*//app-card-body*/}
            </div>
        </>
    )
}

export default BasicEditForm;