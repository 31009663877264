import http from "./http"

class UtilisateursService {
    index(token){
        return http.get("/api/v01/web/utilisateurs/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    store(data, token){
        return http.post("/api/v01/web/utilisateurs/store", data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    edit(ref, token){
        return http.get(`/api/v01/web/utilisateurs/${ref}/edit`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    show(ref, token){
        return http.get(`/api/v01/web/utilisateurs/${ref}/show`,{ headers: { 'Authorization': 'Bearer '+token } })
    }


    update(ref, data, token){
        return http.put(`/api/v01/web/utilisateurs/${ref}/update`, data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    delete(ref, token){
        return http.delete(`/api/v01/web/utilisateurs/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    importExcel(data, token) {
        return http.post("/api/v01/web/utilisateurs/importexcel", data, { headers: { 'Authorization': 'Bearer '+token,  "Content-Type": "multipart/form-data" } });
    }

}

export default new UtilisateursService()