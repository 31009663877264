import http from "./http";

class PaiementsService {
    index(token){
        return http.get("/api/v01/web/paiements/allPaiements",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(code_transaction, data, token){
        return http.post(`/api/v01/web/paiement_store/${code_transaction}`, data, { headers: { 'Authorization': 'Bearer '+token } })
    } 

    checkPaiement(data, token){
        return http.post("/api/v01/web/check/paiement", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    changeStatut(data, token){
        return http.post("/api/v01/web/paiements/update_statut", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new PaiementsService()