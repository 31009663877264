import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { myroutes } from "../../../routes/webroute";
import Layout from "../../../backoffice/admin/include/layout";
import UtilisateursService from "../../../services/UtilisateursService";
import { Button } from "react-bootstrap";
import parse from "html-react-parser"

        


export default function UtilisateursShow() {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const { ref } = useParams(); // référence de l'url
  const [utilisateur, setUtilisateur] = useState(null); // État pour stocker les détails du dossier
  const [profil, setProfil] = useState([]); // État pour stocker les détails du dossier




//get liste utilisateurss details
const fetchUtilisateursDetails = async () => {
    try {
        const response = await UtilisateursService.show(ref, token);
        setUtilisateur(response.data.data);
        setProfil(response.data.profil)
       console.log("response",response.data.profil);
    } catch (error) {
        console.error("Erreur lors de la récupération des détails du dossier:", error);
    }
};

const goToEdit = (param) => {
  navigate(myroutes.utilisateurs_edit, { state: { ref: param } })
}
  


  useEffect(() => {
    fetchUtilisateursDetails();
  }, [ref]);


  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Gestion des utilisateurs</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to={myroutes.utilisateurs_index}>Utilisateurs</Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row g-3 mb-0  align-items-center justify-content-between">
              <div
                className="card bg-successx p-2"  style={{color: "#264f52",backgroundColor: "#3b7d821e", border: "1px solid white" }}>
                    <div className="row">
                        <div className="col-md-9">
                        <h4 className="pt-1">
                        <i class="bi bi-caret-right-fill"></i> 
                        Détails d'un utilisateur
                        </h4>
                        </div>
                    </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                  <div className="col-md-3 mt-4">
                      <img className="me-3" src="/NiceAdmin/assets/img/detailsusers.png" alt="user" style={{width:"300px", height:"300px"}} />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-8">
                      <div className="card" style={{minHeight:"300px"}}>
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 mt-3">
                            <div className="mb-3 m-2">
                              <label htmlFor="civilite" className="form-label">Civilité, Nom & Prénom (s)</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="civilite" 
                                value={(utilisateur && utilisateur.get_civilite && utilisateur.get_civilite.libelle) + ' ' + (utilisateur && utilisateur.nom) + ' ' + (utilisateur && utilisateur.prenoms)} 
                                disabled />
                          </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-8">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="email" className="form-label">Email</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="email" 
                                value={utilisateur && utilisateur.email} 
                                disabled 
                            />
                          </div>
                            </div>
                            <div className="col-md-4">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="pays" className="form-label">Pays de résidence</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="pays" 
                                value={utilisateur && utilisateur.get_pays && utilisateur.get_pays.nom_pays} 
                                disabled 
                            />
                          </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="email" className="form-label">Téléphone</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="tel" 
                                value={utilisateur && utilisateur.telephone} 
                                disabled 
                            />
                          </div>
                            </div>
                            <div className="col-md-4">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="rccm" className="form-label">RCCM</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="rccm" 
                                value={utilisateur && utilisateur.rccm} 
                                disabled 
                            />
                          </div>
                            </div>
                            <div className="col-md-4">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="ifu" className="form-label">IFU</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="ifu" 
                                value={utilisateur && utilisateur.ifu} 
                                disabled 
                            />
                          </div>
                            </div>
                          </div>
                          
                          <div className="row">
                            <div className="col-md-8">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="rccm" className="form-label">Profil</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="rccm" 
                                value={profil && profil.map(p => p.libelle).join(', ')}
                                disabled 
                            />
                          </div>
                            </div>
                            <div className="col-md-4">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="ifu" className="form-label">Statut</label>
                              <input 
                                type="text" 
                                className="form-control fw-bold" 
                                id="ifu" 
                                value={utilisateur && utilisateur.get_statut && utilisateur.get_statut.libelle} 
                                disabled 
                            />
                          </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                            <div className="mb-3 m-2 mt-2">
                              <label htmlFor="adresse" className="form-label">Adresse</label>
                              <fieldset 
                                type="text" 
                                className="form-control fw-bold texte" 
                                id="adresse" 
                                disabled 
                            >{utilisateur ? (utilisateur.adresse != null ? parse(utilisateur.adresse) : utilisateur.adresse) : ''}</fieldset>
                          </div>
                            </div>
                          </div>
                        </div>
                       </div>
                       <Button variant="warning" onClick={() => goToEdit(utilisateur.ref)} title="Modifier">
                      <i className="bi bi-pen-fill"></i> Modifier les informations</Button> &nbsp;&nbsp;
                  <Button variant="secondary" onClick={() => navigate(myroutes.utilisateurs_index)}>
                   Retour
                  </Button>
                  </div>
              </div>
          </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
