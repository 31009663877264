import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.css";
import DossiersService from "../../../services/DossiersService";
import DossierspartiesdocumentsService from "../../../services/DossierspartiesdocumentsService";
import { app_url } from "../../../services/http";
import { Dialog } from "primereact/dialog";
import LayoutActeur from "../inc/layout_acteur";
import { setDataTable } from "../../../utils/helpers";
import { saveAs } from 'file-saver';

export default function DossiersdocumentsIndexActeur() {
  //##########################/// METHODES ##########################///
  const [dossiers, setDossiers] = useState([]);
  const [statuts, setStatuts] = useState([]);
  const [pays, setPays] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [dossierspartiesdocuments, setDossierspartiesdocuments] = useState([]);

  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [dossierId, setDossierId] = useState(null);

  const handleShowDocumentModal = (documents, dossierId) => {
    setDocuments(documents);
    setDossierId(dossierId);
    setShowDocumentModal(true);
  };

  const [showModal, setShowModal] = useState(false); // État pour contrôler la visibilité du modal

  const handleShowModal = async (id) => {
    try {
      // Appel à votre service pour récupérer les documents par dossier
      const response = await DossierspartiesdocumentsService.getDossiersFiles(id, token);
      
      // Vérification de la réponse de l'API
      if (response.status === 200 && response.data && response.data.success) {
        // Extraction des données de la réponse
        const documents = response.data.data;
  
        // Mise à jour de l'état avec les documents récupérés
        setDocuments(documents);
  
        // Ouverture du modal pour afficher les documents
        setShowModal(true);
      } else {
        // Gestion des cas d'erreur de l'API
        console.error("Une erreur s'est produite lors de la récupération des documents par dossier :", response.data.message);
      }
    } catch (error) {
      // Gestion des erreurs de requête
      console.error("Une erreur s'est produite lors de la récupération des documents par dossier :", error);
    }
  };

    const handleCloseModal = () => setShowModal(false); // Fonction pour fermer le modal

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossiers(responseData.data);
       // console.log("responseData.datadossier", responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };

  const getDossiers = (dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Dossier = dossiers.find((item) => {
      return item.id == idToFind;
    });

    return Dossier ? Dossier.reference_dossier : "N/A";
  };

  //get liste dossiers
  const fetchDossiersfiles = async () => {
    try {
      const response = await DossierspartiesdocumentsService.index(token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossierspartiesdocuments(responseData.data);
       // console.log("responseData.datafiles", responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    setDataTable()
  };

  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const statutsData = response.data.data;
      setStatuts(statutsData);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des types chambres:",
        error
      );
    }
  };

  const getStatuts = (statut_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const Statut = statuts.find((item) => {
      return item.id == idToFind;
    });

    return Statut ? Statut.libelle : "N/A";
  };

  //redirection sur la page edit sans afficher les id dans l'url
  /*  const goToEdit = (param) => {
        navigate(myroutes.dossiers_edit, { state: { ref: param } })
    }*/

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        DossiersService.delete(param, token)
          .then((response) => {
            if (response.data.erreur) {
              Swal.fire({
                icon: "error",
                text: response.data.erreur,
              });
            } else {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
              fetchDossiers();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.message,
            });
            console.log(e);
          });
      }
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDownload = (path) => {
    //e.preventDefault();
    saveAs(`${app_url}storage/${path}`, path);
};

  useEffect(() => {
    fetchDossiers();
    fetchDossiersfiles();
    fetchStatuts();
    $("#dossiers-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard_acteur}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Documents relatifs aux dossiers{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Liste des documents{" "}
                  <Link
                    className="btn btn-success btn-sm"
                    to={myroutes.dossierspartiesdocumentsacteurs_create}
                    title="Ajouter"
                  >
                    <i className="bi bi-plus"></i>Ajouter
                  </Link>
                </h3>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Référence dossier</th>
                        <th className="">Documents</th>
                        <th className="">Statut</th>
                        <th className="">Date ajout</th>
                        <th className="">Activité</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {dossierspartiesdocuments.length > 0 &&
                        dossierspartiesdocuments.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              {getDossiers(item.dossier_id)}
                            </td>
                            <td className="">
                              <a
                                style={{
                                  color: "#0056b3",
                                  fontSize: "14px",
                                  textAlign: "center",
                                }}
                                href="#"
                                onClick={() => handleDownload(item.path)}
                                rel="noopener noreferrer"
                              >
                                <i className="bi bi-file-earmark-fill"></i> 
                              </a>
                            </td>
                            <td className="cell">
                              {getStatuts(item.statut_id)}
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <Button
                                className="btn btn-sm btn-danger"
                                onClick={() => onDelete(item.ref)}
                                title="Supprimer"
                              >
                                <i className="bi bi-trash"></i>
                              </Button>
                              
                            {/**   <Button
                                className="btn btn-sm btn-primary"
                                onClick={handleShowModal}
                                title="Voir les documents"
                              >
                                Voir les documents
                              </Button>*/}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
                <Dialog
                  header="Affecter le dossier à une chambre"
                  visible={showModal}
                  onHide={handleCloseModal}
                  style={{
                    width: "600px",
                    minHeight: "250px",
                    borderBottom: "1px solid grey",
                    color: "GrayText",
                  }}
                  headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
                >
                  {/* Contenu de votre modal */}

                  <div className="p-fluid">
                    {/* Contenu du corps du modal */}
                  </div>

                  <div className="p-dialog-footerx mt-4">
                    {/* Pied de page du modal */}
                    <Button type="submit" variant="warning me-3">
                      Enregistrer
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Annuler
                    </Button>
                    {/* Autres boutons ou actions si nécessaire */}
                  </div>
                </Dialog>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </LayoutActeur>
  );
}
