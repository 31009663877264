import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

function FileComponent() {
  const [files, setFiles] = useState([]);

  // Fonction de rappel appelée lorsqu'un fichier est déposé
  const onDrop = useCallback(acceptedFiles => {
    // Mettre à jour la liste des fichiers avec les fichiers déposés
    setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
  }, []);

  // Utiliser le hook useDropzone pour gérer les événements de dépôt de fichiers
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Fonction pour supprimer un fichier de la liste
  const handleRemoveFile = (fileName) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  return (
    <div>
      {/* Zone de dépôt de fichiers */}
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        <p>Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers</p>
        <p>
          <i className="bi bi-cloud-arrow-up-fill text-info" style={{ fontSize: "80px" }}></i>
        </p>
      </div>
      {/* Affichage des fichiers déposés */}
      <ul>
        {files.map(file => (
          <li key={file.name}>
            <p className='mb-4'>
              <span className="fw-bold">Nom:</span> {file.name} - <span className="fw-bold">Taille:</span> {Math.round(file.size / 1024)} KB
              <Button variant="danger" onClick={() => handleRemoveFile(file.name)}>x</Button>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FileComponent;
