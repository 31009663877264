// Step1.jsx
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CategoriesService from "../services/CategoriesService";
import StatutsdossiersService from "../services/StatutsdossiersService";
import { Checkbox } from 'primereact/checkbox';
import DossiersService from "../services/DossiersService";
import ObjetsService from "../services/ObjetsService";
import { RadioButton } from 'primereact/radiobutton';

        

const Step1 = ({
  reference_jugement,
  objet_id,
  date_jugement,
  ancienne_reference,
  categorie_id,
  categorie_dossier_id,
  nature_affaire,
  statut_dossier_id,
  numero_ordre,
  onChange,
  onNextStep,

}) => {
const token = useSelector(state => state.auth.token)
const [currentStep, setCurrentStep] = useState(0);
const [categories, setCategories] = useState([]); 
const [objets, setObjets] = useState([]); 
const [statutsdossiers, setStatutsdossiers] = useState([]); 
const [checked, setChecked] = useState(false);
const [validation, setValidation] = useState({
  reference_jugement: false,
  date_jugement: false,
  categorie_dossier_id: false,
  categorie_id: false
});



  //get liste categorie
  const fetchCategories = async () => {
    await CategoriesService.index(token)
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste categorie dossiers
  const fetchObjets = async () => {
    await ObjetsService.index(token)
      .then((response) => {
        setObjets(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get liste statuts dossiers
  const fetchStatutsdossiers = async () => {
    await StatutsdossiersService.index(token)
      .then((response) => {
        setStatutsdossiers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: value.trim() !== "",
    }));
    onChange(e);
  };


const items = [
  { label: 'Étape 1' },
  { label: 'Étape 2' },
  { label: 'Étape 3' },
  { label: 'Étape 4' }
];

  useEffect(() => {
    fetchCategories();
    fetchObjets();
    fetchStatutsdossiers();
  },
  []);

  return (
    <div className="p-4 mb-2">
      <div className="mb-2">
       {/** <h5 className="fw-bold" style={{ color: "#51678f", backgroundColor:"" }}>
          Renseignements sur l'affaire <i className="bi bi-pencil-square"></i>{" "}
          <hr />
        </h5> */}
      </div>
      <Row>
      {/*
      <Col md={12} sm={12} className="form-group flex justify-content-center mb-3 fw-bold">
      <span className="me-3 p-2 mb-2" style={{ color: "maroon", backgroundColor:"", fontSize:"18px" }}> Est-ce un ancien dossier ? </span>
      <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
      </Col>
      */}
      {checked && (
        <>
        <Row className="mt-2 mx-1 mb-3 p-3" style={{backgroundColor:"#ffb8ab68", border:"1px solid #ffb8ab68"}}>
          <Col md={6} sm={6} className="form-group">
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">
                Ancienne référence <i className="text-danger"></i> <i className="text-info bi bi-question-circle-fill" title="Référence du dossier à la Cour d'Appel de Commerce"></i>
              </Form.Label>
              <Form.Control
                className="border  border rounded-2"
                onChange={handleInputChange}
                value={ancienne_reference}
                placeholder="Renseignez la référence"
                name="ancienne_reference"
                aria-label="ancienne_reference"
                maxLength={100}
                minLength={3}
                type="text"
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={6} className="form-group">
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">
                N° d'ordre du dossier au Greffe <i className="text-danger"></i> <i className="text-info bi bi-question-circle-fill" title="N° d'ordre du dossier à la Cour d'Appel de Commerce"></i> 
              </Form.Label>
              <Form.Control
                className="border  border rounded-2"
                onChange={handleInputChange}
                value={numero_ordre}
                placeholder="Renseignez le numero d'ordre du greffe"
                name="numero_ordre"
                aria-label="numero_ordre"
                maxLength={100}
                minLength={3}
                type="number"
                
              />
            </Form.Group>
          </Col>
          </Row>
        </>
        )}
        <Col md={4} sm={4} className="form-group">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              Référence de la décision <i className="text-info bi bi-question-circle-fill" title="Référence du jugement au Tribunal de la Première Instance"></i>
            </Form.Label>
            <Form.Control
              className= "border border rounded-2"
              onChange={handleInputChange}
              value={reference_jugement}
              placeholder="Renseignez la référence de jugement"
              name="reference_jugement"
              aria-label="reference_jugement"
              maxLength={100}
              minLength={3}
              type="text"
              required
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={4} className="form-group">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              Date de la décision <i className="text-danger">*</i> <i className="text-info bi bi-question-circle-fill" title="Date de jugement au Tribunal de la Première Instance"></i>
            </Form.Label>
            <Form.Control
              className="border  border rounded-2"
              onChange={handleInputChange}
              value={date_jugement}
              placeholder="Renseignez la date de jugement"
              name="date_jugement"
              aria-label="date_jugement"
              maxLength={100}
              minLength={3}
              type="date"
              required
            />
          </Form.Group>
        </Col>
        <Col md={4} sm={4} className="form-group">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              Provenance <i className="text-danger">*</i> <i className="text-info bi bi-question-circle-fill" title="Tribunal"></i>
            </Form.Label>
            <Form.Control
              className= "border border rounded-2"
              onChange={handleInputChange}
              value={reference_jugement}
              placeholder="Juridiction de provenance de la décision attaquée"
              name="reference_jugement"
              aria-label="reference_jugement"
              maxLength={100}
              minLength={3}
              type="text"
              required
            />
          </Form.Group>
        </Col>
        <Col md={6} sm={6} className="formx-groupx">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              Objet <i className="text-danger">*</i>
            </Form.Label>
            <Form.Select
              className=""
              id="objet_id"
              name="objet_id"
              value={objet_id}
              required
              onChange={handleInputChange}
              >
              <option value="" selected>
                -- Sélectionnez dans la liste --
              </option>
              {objets && objets.length > 0 &&
                objets.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.libelle}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
        
        {/* 
        
        <Col md={6} sm={6} className="formx-groupx">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
            Type de procédure <i className="text-danger">*</i>
            </Form.Label>
            <Form.Select
              className=""
              id="categorie_id"
              name="categorie_id"
              value={categorie_id}
              required
              onChange={handleInputChange}
              >
              <option value="" selected>
                -- Sélectionnez dans la liste --
              </option>
              {categories && categories.length > 0 &&
                categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.libelle}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={12} sm={12} className="form-group">
          <Form.Group className="mb-3">
            <Form.Label className="fw-bold">
              Nature Affaire <i className="text-danger">*</i> <i className="text-info bi bi-question-circle-fill" title="Nature de l'affaire à la Cour d'Appel de Commerce"></i>
            </Form.Label>
            <textarea value={nature_affaire} name="nature_affaire" id="" cols="30" onChange={handleInputChange} rows="5" className="form-control summernote border border rounded-2" placeholder=""></textarea>
          </Form.Group>
        </Col>
        */}
         <div className="p-3" style={{ textAlign: "right" }}>
        </div> 
      </Row>
                             
    </div>
  );
};

export default Step1;
