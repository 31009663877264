import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../routes/webroute";
import Swal from "sweetalert2";
import AuthService from "../../services/AuthService";
import { Button } from "react-bootstrap";
import "../../styles/styles.css";

import useMediaQuery from '@mui/material/useMediaQuery';
import { afficherMessagesSuccess } from "../../utils/helpers";

export default function ForgotPassword() {
  ///############### METHODES #############################//

  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');
  const isBrowser = useMediaQuery('(min-width:600px)');

  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true)
    await AuthService.forgotPassword({email : email}).then(response => {
      if (response.status === 200) {

        afficherMessagesSuccess(response.data.message)
        navigate(myroutes.insert_codeotp, { state: { email: email } })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.message,
        })
      }
    }).catch(error => {
      console.log(error)
    })

    setLoading(false)
  };

  const handleBack = () => {
    navigate(myroutes.login);
  }


  useEffect(() => {
    // Vérifier si les identifiants sont sauvegardés dans le stockage local
    const savedEmail = localStorage.getItem("email");

    // Remplir automatiquement les champs du formulaire s'ils existent
    if (savedEmail) {
      setEmail(savedEmail);
    }

  }, []);
  ///############### END METHODES #############################//

  return (
    <React.Fragment>
      <section className="bg-login py-md-5 py-xl-8">
        <div className="container position-absolute top-50 start-50 translate-middle">
          <div className="row gy-4 align-items-center">

            <div className="col-12 col-md-6 col-xl-7">
              <div className="d-flex justify-content-center text-bg-login">
                <div className="col-12">
                  {/**Affichage pour ordinateur et tablet */}
                  {isBrowser &&
                    <div className="row">

                      <div className="col-md-12 mx-auto text-center">
                        <Link to={myroutes.login}>
                          <img
                            style={{
                              height: "270px"
                            }}
                            src="/NiceAdmin/assets/img/logo-blanc.png"
                            alt="logo-blanc"
                            className="img-fluid rounded mb-1" loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>}

                  {/**Affichage pour mobile uniquement */}
                  {isMobile && <>
                    <div className="row" style={{ marginTop: "300px" }}>
                      <div className="col-md-12 mx-auto text-center">
                        <Link to={myroutes.login}>
                          <img
                            style={{
                              height: "200px"
                            }}
                            src="/NiceAdmin/assets/img/logo-blanc.png"
                            alt="logo-blanc"
                            className="img-fluid rounded mb-4" loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>
                  </>}


                  <hr className="border-primary-subtle mb-4" />

                  <p className="h4 mb-5 text-warning text-center fw-bold">Plateforme d'Enrôlement et de Gestion des Procédures</p>

                  <div className="text-endx">
                    <svg className="text-white bi bi-grip-horizontal" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-5">
              <div className="card border-0 rounded-4">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-4">
                        <h3 className="fw-bold"><i className="fas fa-lock"></i> &nbsp;&nbsp;Réinitialiser votre mot de passe</h3>
                      </div>
                    </div>
                  </div>

                  <form
                    className="auth-form login-form"
                    onSubmit={handleChangePassword}
                  >
                    <div className="email mb-4">
                      <label className="sr-only fw-bold mb-2" htmlFor="signin-email1">
                        Identifiant (email) <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        id="signin-email1"
                        value={email}
                        minLength={3}
                        maxLength={100}
                        name="signin-email1"
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        className="form-control signin-email border border-border-secondary"
                        placeholder="Email"
                        required
                      />
                    </div>
                    {/*//form-group*/}

                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <Button type="submit" variant="warning" className="w-100" disabled={loading}>Valider</Button>
                      </div>
                      <div className="col-md-6 mt-2">
                        <Button variant="dark" onClick={handleBack} className="w-100"  disabled={loading}>Annuler</Button>
                      </div>
                    </div>

                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-login">
        <div className="col-md-12 d-flex w-100 ">
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#008559" }}
          ></div>
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#FFD400" }}
          ></div>
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#E43009" }}
          ></div>
        </div>
        <div className="container text-center py-2">
          <small className="copyright">
            &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE -
            COTONOU | Tous droits réservés.
            {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
          </small>
        </div>
      </section>
    </React.Fragment>
  );
}
