import React, { useEffect, useState } from "react";
import FileComponent from "./FileComponent";
import DossierspartiesdocumentsService from "../services/DossierspartiesdocumentsService";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { app_url } from "../services/http";
import { Button, Col, Form, Row } from "react-bootstrap";
import TypesDocumentsService from "../services/TypesDocumentsService";
import { afficherMessagesErreur, afficherMessagesSuccess, afficherOneMessageError } from "../utils/helpers";
import dayjs from "dayjs";
import { saveAs } from 'file-saver';
import StatutDossierEnum from "../enum/statutDossierEnum";



const TabFichiers = ({ dossier }) => {
  const token = useSelector(state => state.auth.token)
  const profils = useSelector((state) => state.auth.profil);
  const profil_habilitation = useSelector((state) => state.auth.profil_habilitation);

  const [dossierspartiesdocuments, setDossierspartiesdocuments] = useState([]);
  const [modalObjet, setModalObjet] = useState([])
  const [showModal, setShowModal] = useState(false); // État pour contrôler la visibilité du modal
  //Verifier si le dossier est déjà vidé
  const isVide = StatutDossierEnum.isStatusVide(dossier.statut_dossier_id);

  const [detailLabel, setDetailLabel] = useState("")

  const handleShowModal = () => setShowModal(true); // Fonction pour afficher le modal
  const handleCloseModal = () => setShowModal(false); // Fonction pour fermer le modal

  const [typesdocuments, setTypesdocuments] = useState([]);
  const [type_document_id, setTypedocument_id] = useState({});
  const [documents, setDocuments] = useState([{ id: 1 }]);
  const [docData, setDocData] = useState(documents.map(doc => ({ id: doc.id, type_document_id: '', file: null })));

  const addDocument = () => {
    const newDocId = docData.length ? docData[docData.length - 1].id + 1 : 1;
    setDocData([...docData, { id: newDocId, type_document_id: '', file: null }]);

  };

  const removeDocument = (docId) => {
    setDocData(prevDocData => prevDocData.filter(doc => doc.id !== docId));
  };

  //Gestion des types documents sélectionnés
  const handleChangeTypeDoc = (e, docId) => {

    const newDoc = parseInt(e.target.value);

    setDocData(prevDocData => prevDocData.map(doc =>
      doc.id === docId ? { ...doc, type_document_id: newDoc } : doc
    ));

  };

  const handleFileChange = (e, docId) => {
    const selectedFile = e.target.files[0];

    setDocData(prevDocData => prevDocData.map(doc =>
      doc.id === docId ? { ...doc, file: selectedFile } : doc
    ));

  };


  //get liste dossiers
  const fetchDossiersfiles = async () => {
    try {
      const response = await DossierspartiesdocumentsService.getDossiersFiles(dossier.id, token);
      const responseData = response.data;
      if (responseData.data && responseData.data.length > 0) {
        setDossierspartiesdocuments(responseData.data.reverse());
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  };


  //Afficher le modal de details
  const handleShowDetails = (option) => {
    setDetailLabel(option.ref)
    const filteredData = dossierspartiesdocuments.filter((opt) => opt.ref === option.ref)
    setModalObjet(filteredData)
    //console.log(dossierspartiesdocuments)
    handleShowModal()
  }

  //get liste types documents
  const fetchTypesdocuments = async () => {
    await TypesDocumentsService.index(token).then((response) => {
      //Filtrer les données
      const filteredData = response.data.data.filter((option) => (option.is_enrolement == false) || (option.is_enrolement == null));

      //Ordonnant par ordre alphabétique
      const sortedData = filteredData.sort((a, b) => {
        const nameA = a.libelle.toUpperCase(); // ignore upper and lowercase
        const nameB = b.libelle.toUpperCase(); // ignore upper and lowercase

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setTypesdocuments(sortedData);

    })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = {
      path: docData, //paths,
      dossier_id: dossier.id
    };

    await DossierspartiesdocumentsService.completeFile(form, token).then((response) => {
      if (response.data.status == true && response.status == 200) {
        afficherMessagesSuccess(response.data.message)
        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } else {
        afficherOneMessageError(response.data.message)
      }
    }).catch((error) => {
      console.log(error);
      afficherMessagesErreur(error.response.data)
    });

  }


  const handleDownload = (path) => {
    //e.preventDefault();
    saveAs(`${app_url}storage/${path}`, path);
  };

  useEffect(() => {
    fetchDossiersfiles();
    fetchTypesdocuments();
  }, []);



  return (
    <div style={{ fontFamily: "montserrat" }}>
      <div className="row g-3 mb-0  align-items-center justify-content-between">
        <div className="card bg-warningx pt-1" style={{ color: "#1f84d6", backgroundColor: "#1f84d621", border: "1px solid white", }}>
          <h6 className="pt-1">
            <i className="fa fa-file me-2"></i>FICHIERS CHARGÉS
          </h6>
        </div>
      </div>
      <div className="row">
        {dossierspartiesdocuments &&
          dossierspartiesdocuments.map((option, index) => (
            <div key={index} className="row border-ligne mt-3xxx">
              <div className="col-md-11">
                <div className="th-table d-flex">
                  <p className="th-tablex fw-boldx" onClick={() => handleShowDetails(option)} title="Détails"><a href="#" className="text-primary">
                    <i className="bi bi-file-earmark-text h4"></i> &nbsp;
                    {option.get_type_document && option.get_type_document.libelle}
                  </a> envoyé le <b>{dayjs(option.created_at).format("DD/MM/YYYY à H:m:s")}</b></p>
                </div>
              </div>
            </div>
          ))}
      </div>

      {!isVide && profil_habilitation.includes("ENVOYER-FICHIER") &&
        <>
          <div className="row g-3 mb-0  align-items-center justify-content-between">
            <div className="card bg-warningx pt-1" style={{ color: "#1f84d6", backgroundColor: "#1f84d621", border: "1px solid white", }}>
              <h6 className="pt-1">
                <i className="fa fa-file me-2"></i>ENVOYEZ DES FICHIERS
              </h6>
            </div>
          </div>
          <p className=""
            style={{ fontSize: '16px', fontWeight: 'bold' }}> Fichiers acceptés
            (PDF et WORD)</p>

          <Form onSubmit={handleSubmit}>

            {/**Start bloc */}

            {docData.map((doc, index) => (
              <div key={doc.id}>
                <Row>
                  <Col md={6}>
                    <label htmlFor="" className="fw-boldxx">Type de
                      documents <i className="text-danger">*</i></label>
                    <Form.Select
                      className="control-label form-control-lg"
                      id="type_document_id"
                      name={`type_document_id-${doc.id}`}
                      value={doc.type_document_id}
                      onChange={(e) => handleChangeTypeDoc(e, doc.id)}
                      style={{
                        padding: '10px',
                        border: '2px solid #ccc',
                        borderRadius: '5px',
                        backgroundColor: '#f8f9fa',
                        color: '#495057'
                      }}
                      required
                    >
                      <option value="" disabled>
                        -- Sélectionnez dans la liste --
                      </option>
                      {typesdocuments && typesdocuments.length > 0 &&
                        typesdocuments.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.libelle}
                          </option>
                        ))}
                    </Form.Select>
                  </Col>
                  <Col md={6} sm={12} className="form-group">
                    <label htmlFor="">Fichier <i className="text-danger">*</i></label>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="form-control-file"
                        style={{
                          border: '2px solid #ccc',
                          borderRadius: '5px',
                          backgroundColor: '#f8f9fa',
                          color: '#495057'
                        }}
                        onChange={(e) => handleFileChange(e, doc.id)}
                        name="path"
                        aria-label="path"
                        required
                        type="file"
                        accept=".pdf, .doc, .docx, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        id="path"
                        multiple={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {(index > 0) && (
                  <Button className="mb-3 me-3" variant="danger"
                    onClick={() => removeDocument(doc.id)}>Supprimer</Button>
                )}
                {index === docData.length - 1 && ( // Afficher les boutons uniquement pour le dernier bloc
                  <>
                    <Button className="mb-3" type="button"
                      variant="warning me-3" onClick={addDocument}>
                      <i className="bi bi-plus"></i> Ajouter plus
                    </Button>
                    <Button className="mb-3" type="submit"
                      variant="success me-3">
                      <i className="bi bi-check"></i> Envoyer
                    </Button>
                  </>
                )}

              </div>
            ))}
            <div className="border-success-subtle border-top py-2">
              {docData.map((item, index) => (
                item.file && (
                  <div key={index}>
                    <p>Nom du fichier : {item.file.name}</p>
                  </div>
                )
              ))}
            </div>

            {/**End bloc */}
          </Form>
        </>}
      {/*<FileComponent />*/}


      {/* Modal */}
      <Dialog
        header={"Fichier " + detailLabel}
        visible={showModal}
        onHide={handleCloseModal}
        style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
        headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
      >


        <div className="p-fluid">
          <div className="row">
            <hr />
            <p className="text-center fw-bold">Documents relatifs aux dossiers </p>
          </div>
        </div>
        <div className="row">
          {modalObjet.map((item, index) => (
            <>
              <p>
                <b>Auteur : </b> {item.get_createdby.nom} <br />
                <b>Type document : </b> {item.get_type_document.libelle}<br />
                <b>Date d'envoie : </b> {dayjs(item.created_at).format("DD-MM-YYYY à H:m:s")}
              </p>
              <a href="#" onClick={() => handleDownload(item.path)} className="text-primary fw-bold" >Télécharger le fichier</a>
            </>
          ))}
        </div>

        <div className="p-dialog-footer mt-3">
          <Button variant="secondary" onClick={handleCloseModal}>Fermer</Button>
        </div>
      </Dialog>

    </div>

  );
};

export default TabFichiers;
