import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import StatutsService from "../../../../services/StatutsService";
import { useSelector } from "react-redux";
import ProceduresService from "../../../../services/ProceduresService";
import UtilisateursService from "../../../../services/UtilisateursService";
import ChambresmembresService from "../../../../services/ChambresmembresService";
import ChambresService from "../../../../services/ChambresService";
import { filterStatut } from "../../../../utils/helpers";

export default function ChambresmembresEdit() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [chambre_id, setChambre_id] = useState('')
    const [chambres, setChambres] = useState([])
    const [utilisateur_id, setUtilisateur_id] = useState('')
    const [utilisateurs, setUtilisateurs] = useState('')
    const [statut_id, setStatut_id] = useState('')
    const [statuts, setStatuts] = useState([])


    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                
                setStatuts(filterStatut(responseData.data));
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste chambres
    const fetchChambres = async () => {
        await ChambresService.index(token)
            .then((response) => {
                setChambres(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get liste utilisaturs
    const fetchUtilisateurs = async () => {
        try {
            const response = await UtilisateursService.index(token);
            const responseData = response.data;
            if (responseData.data && responseData.data.length > 0) {
                setUtilisateurs(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };


    ///Save form data
    const handleUpdate = async (event) => {
        event.preventDefault()
        let form = {
            statut_id, chambre_id, utilisateur_id
        }

        await ChambresmembresService.update(location.state.ref, form, token).then((response) => {
            //redirection sur index
            navigate(myroutes.chambresmembres_index)
            Swal.fire({
                icon: "success",
                text: response.data.message,
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            })

            setValidationError(response.data.message)

        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message
            })
            //affichage des erreurs sur le formulaire
            setValidationError(error.response.data.message)
        });
    }

    const edit = async () => {
        try {
            const response = await ChambresmembresService.edit(location.state.ref, token);
            //console.log("API response", response);
            if (response.data) {
                const data = response.data.data;
                setChambre_id(data.chambre_id);
                setUtilisateur_id(data.utilisateur_id);
                setStatut_id(data.statut_id);
            } else {
                console.log("Data not found in API response");
                // Gérer le cas où response.data ou response.data.statut est undefined ou null
            }
        } catch (error) {
            console.error("API error", error);
            Swal.fire({
                icon: "error",
                text: error.message
            });
            setValidationError(error.response.data.message)
        }
    };





    useEffect(() => {
        fetchUtilisateurs();
        fetchStatuts();
        fetchChambres();
        edit();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">


                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Chambres membres </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Modification </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleUpdate}>
                                    <Row>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Chambre <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="chambre_id" name="chambre_id" value={chambre_id}
                                                    required 
                                                    onChange={(e) => {
                                                        setChambre_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {chambres.length > 0 &&
                                                        chambres.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Utilisateurs <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="utilisateur_id" name="utilisateur_id" value={utilisateur_id}
                                                     required 
                                                    onChange={(e) => {
                                                        setUtilisateur_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {utilisateurs.length > 0 &&
                                                        utilisateurs.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.nom}{" "}{item.prenoms}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={12} sm={12} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Statut <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="statut_id" name="statut_id" value={statut_id}
                                                     required
                                                    onChange={(e) => {
                                                        setStatut_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {statuts.length > 0 &&
                                                        statuts.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.chambresmembres_index)}>Annuler</Button>                            </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}