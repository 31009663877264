import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Editor } from '@tinymce/tinymce-react';
import "../styles/styles.css";
import DecisionsService from "../services/DecisionsService";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { myroutes } from "../routes/webroute";
import StatutsdossiersService from "../services/StatutsdossiersService";
import parse from "html-react-parser"



const TabPublications = ({ dossier }) => {
  const [content, setContent] = useState('');
  const [date_decision, setDateDecision] = useState('');
  const [numero, setNumero] = useState('');
  const editorRef = useRef(null);
  const token = useSelector(state => state.auth.token)
  const navigate = useNavigate();
  const [statuts_dossiers, setStatutsDossiers] = useState([]);
  const [statut_dossier_id, setStatutDossier_id] = useState("")
  const [olddecisions, setOldDecisions] = useState([]);

  const handleEditorChange = (e) => {
    setContent(e.target.getContent());
  };

  //get liste statuts
  const fetchStatutsDossiers = async () => {
    await StatutsdossiersService.index(token).then((res) => {
      setStatutsDossiers(res.data.data)
    }).catch(error => {
      console.log(error)
    })
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = { decision_prise: content, date_decision, numero_decision: numero, dossier_id: dossier.id, statut_dossier_id };
    await DecisionsService.store(form, token).then(response => {
      if (response.status === 200) {
        //navigate(myroutes.dossiers_index);
        setTimeout(() => {
          window.location.reload();
        }, 2500);

        Swal.fire({
          icon: "success",
          text: response.data.message,
          timer: 3000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message + ' ' + response.data.data,
          showConfirmButton: true,
          timer: 3000,
        });
      }
    }).catch((error) => {
      Swal.fire({
        icon: "error",
        text: error.response.data.message,
      });
      console.log(error)
    })
  }

  const getStatutLibelle = (statut_dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const statutDossier = statuts_dossiers.find((item) => {
      return item.id == idToFind;
    });

    return statutDossier ? statutDossier.libelle : "N/A";
  };


  //Verifie si le dossier est dejà radié ou vidé
  const checkDisplayForm = (statutDossier_id) => {
    if (getStatutLibelle(statutDossier_id) === 'Radié' || getStatutLibelle(statutDossier_id) === 'Vidé') {
      return false
    }

    return true
  }


  //Recupérer les décisions du dossiers
  const fetchDecisions = async () => {
    await DecisionsService.findByIdDossier(dossier.id, token).then((res) => {
      setOldDecisions(res.data.data)
    }).catch(error => {
      console.log(error)
    })
  }




  useEffect(() => {
    fetchStatutsDossiers()
    checkDisplayForm(dossier.statut_dossier_id) && fetchDecisions()
    setStatutDossier_id(dossier.statut_dossier_id)
  }, []);

  return (
    <div style={{ fontFamily: "montserrat" }}>
      <div className="row g-3 mb-0  align-items-center justify-content-between">
        <div className="card bg-warningx pt-1" style={{ color: "#1f84d6", backgroundColor: "#1f84d621", border: "1px solid white", }}>
          <h6 className="pt-1">
            <i className="bi bi-file-post-fill me-2"></i>Publications (Décisions, Récipissé/...)
          </h6>
        </div>
      </div>
      {checkDisplayForm(dossier.statut_dossier_id) ? <>

        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-4">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                  <i className="pi pi-calendar"></i>
                </span>
                <InputText required maxLength={100} minLength={3}
                  type="date" name="date_decision" value={date_decision}
                  onChange={(e) => setDateDecision(e.target.value)} />

              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">N°</span>
                <InputText placeholder="Numero" value={numero}
                  onChange={(e) => setNumero(e.target.value)} />
              </div>
            </div>

            <div className="col-4">
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon"><i className="bi bi-check2-square"></i></span>
                <Form.Select
                  className=""
                  id="statut_dossier_id"
                  name="statut_dossier_id"
                  value={statut_dossier_id}
                  required
                  onChange={(e) => {
                    setStatutDossier_id(e.target.value);
                  }}
                >
                  <option value="" selected>
                    -- Statut du dossier --
                  </option>
                  {statuts_dossiers.length > 0 &&
                    statuts_dossiers.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <Editor
              apiKey='quxjd5vswwl6oxto466w44izsbdc3w35w5u9mz5hur625yp4'
              onInit={(evt, editor) => editorRef.current = editor}
              init={{
                height: 500,
                menubar: true,
                theme: 'silver',
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
              initialValue="<p>Tapez un texte...</p>"
              onChange={handleEditorChange}
            />
          </div>
          <Button type="submit" variant="success me-3">Enregistrer</Button>
          {/**<Button variant="warning">Visualiser</Button> */}
        </Form>
      </> : <>
        {/**Afficher les informations de la decision */}

        {olddecisions.length > 0 && olddecisions.map((option, index) => (
          <>
          <hr />
            <div className="row" key={index}>
              <div className="col-4">
                <label htmlFor="date_decision" className="fw-bold">Date de la décision</label>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">
                    <i className="pi pi-calendar"></i>
                  </span>
                  <InputText readOnly id="date_decision"
                    type="text" name="date_decision" value={option.date_decision} />

                </div>
              </div>
              <div className="col-4">
                <label htmlFor="date_decision" className="fw-bold">Numero de la décision</label>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon">N°</span>
                  <InputText readOnly
                    type="text" name="" value={option.numero_decision} />
                </div>
              </div>

              <div className="col-4">
                <label htmlFor="date_decision" className="fw-bold">Statut du dossier</label>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon"><i className="bi bi-check2-square"></i></span>
                  <InputText readOnly
                    type="text" name="" value={getStatutLibelle(dossier.statut_dossier_id)} />
                </div>
              </div>
            </div>

            <div className="mt-4 mb-4 p-4 border">
              <label className="fw-bold text-decoration-underline">Décision</label>
                <p className="th-table d-flex">
                  {parse(option.decision_prise)}
                </p>
            </div>
          </>
        ))}

      </>}

    </div>
  );
};

export default TabPublications;