import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { MessageBox, ChatList, Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import DiscussionsService from '../services/DiscussionsService';
import { afficherMessagesSuccess, afficherOneMessageError } from '../utils/helpers';
import { useSelector } from 'react-redux';
import $ from 'jquery';

function ChatComponent({ dossier }) {
  const [message, setMessage] = useState();
  const [oldmessages, setOldMessages] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector(state => state.auth.user);

  const handleChange = (e) => {
    setMessage(e.target.value);
  }

  //Enregistrer le message
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!message || message.length <= 1){
      return false;
    }

    let form = { message: message, ref: dossier?.ref }

    await DiscussionsService.store(form, token).then(response => {
      if (response.data.status == true && response.status == 200) {
        afficherMessagesSuccess(response.data.message)
        setMessage('')
        //Vider le champs de saisi
        $('.rce-input').val('');
        getOldMessage();
      } else {
        afficherOneMessageError(response.data.message)
      }
    }).catch(error => {
      console.log(error)
      afficherOneMessageError(error.response.data.message)
    })

  }

  //Recupérer les anciens messages sur le dossier
  const getOldMessage = async () => {
    let ref = dossier ? dossier.ref : '';

    await DiscussionsService.index(ref, token).then(response => {
      if (response.data.status == true && response.status == 200) {
        setOldMessages(response.data.data)
      } else {
        afficherOneMessageError(response.data.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(() => {
    getOldMessage();
  }, [])



  return (
    <div>

      {oldmessages && oldmessages.length > 0 && oldmessages.map((item, index) => (
        (item.created_by == user.id) ? <>
          {/***Afficher les messages du user connecté avec MessageBox */}
          <MessageBox
            position={'right'}
            type={'text'}
            text={item.message}
            dateString={item.created_at}
          />
        </> : <>
          {/***Afficher les messages des autres users avec ChatList */}
          <ChatList
            className="chat-list"
            dataSource={[
              {
                avatar: '/NiceAdmin/assets/img/images.png',
                alt: item.get_createdby?.nom,
                title: item.get_createdby?.nom,
                subtitle: item.message,
                date: item.created_at,
                unread: 0,
              }
            ]}
          />
        </>
      ))}


      <Form onSubmit={handleSubmit}>
        <Input placeholder="Ecrivez votre message ici..." autofocus multiline={true} className='border border-success my-4' onChange={handleChange}
          rightButtons={
            <Button variant="success" type="submit">
              <i className='fas fa-paper-plane'></i>&nbsp;Envoyer
            </Button>
          }
        />
      </Form>

    </div>
  );
}

export default ChatComponent;
