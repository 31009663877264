import http from "./http";

class CalendriersService {
    index(token){
        return http.get("/api/v01/web/calendriers/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    listeByProfil(token){
        return http.get("/api/v01/web/calendriers/liste-by-profil",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(data, token){
        return http.post("/api/v01/web/calendriers/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    edit(ref, token){
        return http.get(`/api/v01/web/calendriers/${ref}/edit`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    show(ref, token){
        return http.get(`/api/v01/web/calendriers/${ref}/show`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    update(ref, data, token){
        return http.put(`/api/v01/web/calendriers/${ref}/update`, data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    delete(ref,token){
        return http.delete(`/api/v01/web/calendriers/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    filterByCalendrierGlobal(calendrier_global_id,token){
        return http.get(`/api/v01/web/calendriers/filter_by_calendrierglobal/${calendrier_global_id}`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    importExcel(data, token) {
        return http.post("/api/v01/web/importexcel", data, { headers: { 'Authorization': 'Bearer '+token,  "Content-Type": "multipart/form-data" } });
    }
}

export default new CalendriersService()