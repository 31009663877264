import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import ChambresService from "../../../../services/ChambresService";
import { Fab } from "@mui/material";
import PolesService from "../../../../services/PolesService";

export default function ChambresCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [libelle, setLibelle] = useState('')
    const [pole_id, setPole_id] = useState('')
    const [poles, setPoles] = useState([])
    const [description, setDescription] = useState('')
    const [code, setCode] = useState('')

     //get liste poles
     const fetchPoles = async () => {
        try {
          const response = await PolesService.index(token);
          const responseData = response.data;
      
          if (responseData.status && responseData.data && responseData.data.length > 0) {
            setPoles(responseData.data);
          } else {
            console.error("Erreur dans la réponse:", responseData.message);
          }
        } catch (error) {
          console.error("Erreur lors de la requête de poles:", error);
        }
      
      };

    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            libelle, description, pole_id, code
        }

        try {
            const response = await ChambresService.store(form, token);
      
            if (response.data.status == true) {
                navigate(myroutes.chambres_index);
                Swal.fire({
                    icon: "success",
                    text: response.data.message,
                    timer: 3000,
                });
            } else {
              // Redirection sur index
              Swal.fire({
                icon: "error",
                text: response.data.message +' '+ response.data.data,
                timer: 3000,
              });
            }
          } catch (error) {
            Swal.fire({
              icon: "error",
              text: error.message,
            });
            console.log(error);
          }
        };

       

    useEffect(() => {
        fetchPoles();
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
           <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                <div className="container-xl app-card-body">

                <div className="row g-3 align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="pagetitle">
                                <h1 className="app-page-title mb-0">Paramètres</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                        <li className="breadcrumb-item active">Chambres </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/*//row*/}

                    <div className="row g-3 mb-4 align-items-center justify-content-between">
                        <div className="col-auto">
                            <h1 className="app-page-title mb-0">Enregistrement </h1>
                        </div>
                    </div>{/*//row*/}

                    <div className="app-card app-card-settings shadow-lg mb-5">
                        <div className="app-card-body py-3">
                            <Row>
                                {
                                    Object.keys(validationError).length > 0 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-danger">
                                                    <ul className="mb-0">
                                                        {
                                                            <li>{validationError}</li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Row>
                            <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                <Row>
                                <Col md={4} sm={4} className="form-group">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="fw-bold">Pôles<i className="text-danger">*</i></Form.Label>
                                        <Form.Select
                                            className=""
                                            id="pole_id"
                                            name="pole_id"
                                            value={pole_id}
                                            required
                                            onChange={(e) => {
                                            setPole_id(e.target.value);
                                            }}
                                        >
                                            <option value="" selected>
                                            -- Sélectionnez --
                                            </option>
                                            {poles.length > 0 &&
                                            poles.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                {item.libelle}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    </Col>
                                    <Col md={5} sm={5} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                                value={libelle}
                                                placeholder="Libelle de la chambre"
                                                name="libelle"
                                                aria-label="libelle"
                                                maxLength={100}
                                                minLength={3}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={3} sm={3} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Code <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border border rounded-2" onChange={(e) => setCode(e.target.value)}
                                                value={code}
                                                placeholder="Code de la chambre"
                                                name="code"
                                                aria-label="code"
                                                maxLength={100}
                                                minLength={3}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12} sm={12} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Description  <i className="text-danger"></i>
                                            </Form.Label>
                                            <textarea value={description} name="resume" id="" cols="30" onChange={(e) => setDescription(e.target.value)} rows="5" className="form-control summernote border border rounded-2" placeholder=""></textarea>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                <Button variant="secondary" onClick={() => navigate(myroutes.chambres_index)}>Annuler</Button>

                            </Form>

                        </div>{/*//app-card-body*/}
                    </div>
                    {/*//app-card*/}

                </div>{/*//container-fluid*/}
            </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}