import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../../backoffice/admin/include/layout";
import $ from "jquery"
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import clipboardCopy from 'clipboard-copy';
import ProceduresService from '../../../../services/ProceduresService';
import ChambresmembresService from '../../../../services/ChambresmembresService';
import { setDataTable } from '../../../../utils/helpers';


                

export default function ChambresmembresIndex() {

    //##########################/// METHODES ##########################///
    const [chambresmembres, setChambresmembres] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const [visible, setVisible] = useState(false);
    const [copiedReferences, setCopiedReferences] = useState(null);

    const handleCopyReference = (reference, itemId) => {
        clipboardCopy(reference);
        setCopiedReferences((prevCopiedReferences) => ({
          ...prevCopiedReferences,
          [itemId]: reference,
        }));
    
        // Efface la référence copiée après 3 secondes (3000 millisecondes)
        setTimeout(() => {
          setCopiedReferences((prevCopiedReferences) => ({
            ...prevCopiedReferences,
            [itemId]: null,
          }));
        }, 3000);
      };

    //get liste chambres membres
    const fetchChambresmembres = async () => {
        try {
          const response = await ChambresmembresService.index(token);
          const responseData = response.data;
      
          if (responseData.status && responseData.data && responseData.data.length > 0) {
            setChambresmembres(responseData.data);
          } else {
            console.error("Erreur dans la réponse:", responseData.message);
          }
        } catch (error) {
          console.error("Erreur lors de la requête de statuts:", error);
        }
      
       
    //Afficher dataTable
    setDataTable();
      };
      

    //redirection sur la page edit sans afficher les id dans l'url
    const goToEdit = (param) => {
        navigate(myroutes.chambresmembres_edit, { state: { ref: param } })
    }

    //suppression d'un élément
    const onDelete = (param) => {
        //confirm action
        Swal.fire({
            icon: "warning",
            text: "Voulez-vous confirmer cette action ?",
            showCancelButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui",
            confirmButtonColor: "red"
        }).then(result => {
            if (result.isConfirmed) {
                ChambresmembresService.delete(param,token).then((response) => {
                    if (response.data.erreur) {
                        Swal.fire({
                            icon: "error",
                            text: response.data.erreur
                        })
                    } else {
                        Swal.fire({
                            icon: "success",
                            text: response.data.message
                        })
                        fetchChambresmembres()
                    }
                }).catch(e => {
                    Swal.fire({
                        icon: "error",
                        text: e.message
                    })
                    console.log(e)
                })
            }
        })
    }

    useEffect(() => {
        fetchChambresmembres();
        $("#parametres-nav").addClass("show")
    }, [])


    return (
        <Layout>
              <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                <div className="container-xl app-card-body">

                    <div className="row g-3 align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="pagetitle">
                                <h1 className="app-page-title mb-0">Paramètres</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                        <li className="breadcrumb-item active">Chambres membres </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/*//row*/}

                    <div className="row g-3 mb-4 align-items-center justify-content-between">
                        <div className="col-auto">
                            <h3 className="app-page-title mb-0">Liste des membres de chambre<Link className="btn btn-success btn-sm" to={myroutes.chambresmembres_create} title="Ajouter"><i className="bi bi-plus"></i>Ajouter</Link> </h3>
                        </div>
                    </div>{/*//row*/}

                    <div className="app-card app-card-orders-table shadow-lg mb-5">
                        <div className="app-card-body mx-3 py-2">
                            <div className="table-responsive mt-4">
                                
                                <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                                    <thead className="table-info mt-2">
                                        <tr>
                                            <th className="text-center">N°</th>
                                            <th className="text-center">Chambre</th>
                                            <th className="text-center">Utilisateurs</th>
                                            <th className="text-center">Statut</th>
                                            <th className="text-center">Date modification</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider" style={{textAlign : "left"}}>
                                        {chambresmembres.length > 0 && chambresmembres.map((item, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="text-center">{item.get_chambre.libelle}</td>
                                                <td className="text-center">{item.get_utilisateur.nom}{"  "}{item.get_utilisateur.prenoms}</td>
                                                <td className="text-center">
                                                <span className={`badge ${item.get_statut.libelle === 'ACTIF' ? 'text-bg-success' : 'text-bg-danger'}`}>
                                                    {item.get_statut.libelle}
                                                </span>
                                                </td>
                                                <td className="text-center"><span>{dayjs(item.updated_at).format("DD/MM/YYYY H:m:s")}</span>
                                                </td>
                                                <td className="text-center">
                                                    <Button className="btn btn-sm btn-primary" onClick={() => goToEdit(item.ref)} title="Modifier">
                                                        <i className="bi bi-pen-fill"></i></Button> &nbsp;&nbsp;
                                                    <Button className="btn btn-sm btn-danger" onClick={() => onDelete(item.ref)} title="Supprimer">
                                                        <i className="bi bi-trash"></i></Button>  &nbsp;&nbsp;
                                                        <Button
                                                        className="btn btn-sm btn-success"
                                                        onClick={() => handleCopyReference(item.ref, item.id)}                                                        >
                                                    <i className="bi bi-clipboard2-fill"></i> Ref            
                                                    </Button>
                                                    {copiedReferences && (
                                                        <div className="alert alert-success" role="alert">
                                                         {copiedReferences[item.id]}
                                                        </div>
                                                    )}
                                                    
                                                </td>
                                               
                                            </tr>
                                            
                                        ))}


                                    </tbody>
                                </table>
                            </div>{/*//table-responsive*/}

                        </div>{/*//app-card-body*/}
                    </div>
                    {/*//app-card*/}

                    {/*//End table-content*/}



                </div>{/*//container-fluid*/}
            </div>
           
            </main>
          {/*//app-content*/}
        </Layout>
    )
}
