import React from "react"
import SideBar from "./sideBar"
import Footer from "./footer"
import "../../assets/css/style.css"
import "../../assets/js/main"
import Header from "./header"

import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

export default function Layout({ children }) {
    const navigate = useNavigate()

    useEffect(() => {

    }, [navigate])

    return (
        <React.Fragment>
            <header id="header" className="header fixed-top d-flex align-items-center" style={{ backgroundColor: "" }}>
                <Header />
            </header>

                <SideBar />
                {children}

            <Footer style={{ height: "90px" }} />
        </React.Fragment>
    )
}
