import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CalendriersGlobalsService from "../../../../services/CalendriersGlobalsService";
import { myroutes } from "../../../../routes/webroute";
import Layout from "../../include/layout";


export default function CalendriersGlobalsImportxlsx() {
    // State
    const [file, setFile]= useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token);
    const [excelData, setExcelData] = useState(null);
   


    // Fonction de gestion du changement de fichier
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        //setSelectedFile(e.target.files[0]);  Mise à jour de l'état avec le fichier sélectionné
         // Ajout du fichier au FormData


    };


    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        const formData = new FormData();
        formData.append("file", file);
        
        try {
            const response = await CalendriersGlobalsService.importExcel(formData, token);
            console.log(response)
            return false;
            
            if (response.data.status === true) {
                navigate(myroutes.calendriersglobals_index);
                setExcelData(response.data.data); 
                
                Swal.fire({
                    
                    icon: "success",
                    text: response.data.message || "Bravo ! Votre enregistrement a été effectué avec succès.",
                    timer: 3000,
                });
                
            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.message || "Une erreur s'est produite lors de l'importation.",
                    timer: 3000,
                });
            }
        } catch (error) {
            console.error("Une erreur s'est produite :", error);
            Swal.fire({
                icon: "error",
                text: "Une erreur s'est produite lors de la communication avec le serveur.",
                timer: 3000,
            });
        }
    };
    
       
    


    return (
        <Layout>
            <main id="main" className="main">
                <div className="container">
                 


                    <div className="row">
                       
                            <div className="card col-12">
                                <div className="card-header">
                                    <h3>Import Excel</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit} encType="multipart/form-data" >
                                        <div className="form-group pt-2 mb-3 col-8">
                                           
                                            <input type="file" name="file" className="form-control-file importxlsx" id="file" onChange={handleFileChange} accept=".xlsx, .xls"  />
                                        </div>
                                        <Button type="submit" className="btn btn-primary">Envoyer</Button>
                                    </form>
                                </div>
                            </div>
                       
                    </div>
                </div>
            </main>
        </Layout>
    );
}



