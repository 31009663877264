import {
    createSlice
} from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const initialState = {
    user: null,
    token: "",
    refreshToken: "",
    isAuthenticate: false, // default value
    profil: [],
    renseignementAffaire: [],
    appelants: [],
    intimes: [],
    fichiers: [],
    profil_habilitation: []
};

export const auth = createSlice({
    name: 'auth',
    initialState,

    reducers: {
        // authentification 
        loginUser(state, action) {
            state.token = "";
            state.isAuthenticate = false;
            const loginRequest = action.payload;
            state.user = loginRequest.info; // Mettre à jour l'utilisateur avec les données reçues
            // alert("Données de l'utilisateur reçues : " + JSON.stringify(state.user)); // Afficher les données de l'utilisateur dans une alerte
            state.token = loginRequest.token;
            state.refreshToken = loginRequest.refreshToken;
            state.isAuthenticate = true;

            //Recupérer les profils de l'utilisateur
            const profilUtilisateur = loginRequest.profil;
            state.profil = profilUtilisateur.map(item => item.code);
            
            // Récupérer les codes des habilitations dans une variable listeHabilitation
            const habilitation = loginRequest.profil_habilitation;
            state.profil_habilitation = habilitation.map(item => item.get_habilitation.code);
            //alert(JSON.stringify(state.profil))
        },


        //Mettre à jour le token
        updateToken(state, action) {
            state.token = "";
            state.isAuthenticate = false;
            const loginRequest = action.payload;

            state.token = loginRequest.token;
            state.refreshToken = loginRequest.refreshToken;
            state.isAuthenticate = true;
        },

        
        // authentification 
        resetAuthData(state) {
            state.user = null;
            state.token = '';
            state.isAuthenticate = false;
            state.appelants = [];
            state.intimes = [];
            state.renseignementAffaire = [];
            state.fichiers = [];
            state.profil = [];
            state.profil_habilitation = [];
            state.refreshToken = '';
            // return navigate(myroutes.login)      
        },

        // authentification 
        checkAuthenticate(state) {
            let statut = false;
            if (state.token) {
                const decodedToken = jwtDecode(state.token);
                const currentDate = new Date().getTime() / 1000; // Convertir la date actuelle en secondes 
                if ((decodedToken.exp < currentDate) && !state.refreshToken) {
                    // Le token a expiré
                    statut = false;
                } else {
                    statut = true
                }
            } else {
                statut = false;
            }

            state.isAuthenticate = statut;

            if (!statut) {
                state.user = null;
                state.token = "";
                state.isAuthenticate = false;
                state.appelants = [];
                state.intimes = [];
                state.renseignementAffaire = [];
                state.fichiers = [];
                state.profil = [];
                state.profil_habilitation = [];
                state.refreshToken = '';
            }

            // redirect to dashboard         
        },

        //function pour garder en mémoire les données saisies pour le save du dossier
        setRenseignementAffaire(state, action) {
            const data = action.payload;
            state.renseignementAffaire = data
        },

        setAppelants(state, action) {
            const data = action.payload;
            state.appelants = data;
            //console.log(data)
            //console.log("Appelant state"  + JSON.stringify(state.appelants))
        },

        setIntimes(state, action) {
            const data = action.payload;
            state.intimes = data;
            //console.log(data)
        },

        resetDossierFormulaire(state){
            state.appelants = [];
            state.intimes = [];
            state.renseignementAffaire = [];
            state.fichiers = [];
        }
    },
});

export const {
    loginUser,
    checkAuthenticate,
    resetAuthData,
    setRenseignementAffaire,
    setAppelants,
    setIntimes,
    resetDossierFormulaire,
    updateToken
} = auth.actions;

export default auth.reducer;