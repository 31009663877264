import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../styles/styles.css";
import { myroutes } from "../routes/webroute";
import { Modal } from "react-bootstrap";
import { ProgressSpinner } from "primereact/progressspinner";
import ModesPaiementsService from "../services/ModesPaiementsService";
import PaiementsService from "../services/PaiementsService";
import { afficherOneMessageError, getPaiementKey } from "../utils/helpers";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener, } from "kkiapay";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { RadioButton } from "primereact/radiobutton";
import ButtonWithSpinner from "../components/ButtonWithSpinner";
import $ from 'jquery'

export default function PaiementDossier() {
    ///############### METHODES #############################//
    const [loading, setLoading] = useState(false);
    const [modespaiements, setModesPaiements] = useState([]);
    const [mode_paiement_id, setModePaiement_Id] = useState("");
    const [montant_payer, setMontantPayer] = useState(10000);
    const [reference_dossier, setReferenceDossier] = useState('');
    const token = useSelector(state => state.auth.token)
    const auth = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [isDisabled, setIsDisabled] =useState(false);

    //get liste mode de paiement
    const fetchModepaiements = async () => {
        await ModesPaiementsService.indexFrontoffice(token)
            .then((response) => {
                //Récupérer uniquement les modes de paiements actif
                setModesPaiements(response.data.data.filter((mode) => mode.statut_id === 1))
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSetModePaiement = (e) => {
        setModePaiement_Id(e.target.value)
    }

   

    const storePaiement = () => {
        if (mode_paiement_id == '' || isDisabled) {
            return false
        }

        //Afficher le hook de paiement
        //Cle kiosque digital
        var key = getPaiementKey();
        openKkiapayWidget({
            amount: montant_payer,
            position: "center",
            data: { reference_dossier, mode_paiement_id },
            //name: auth.user.fullname,
            theme: "green",
            api_key: key,
            sandbox: true,
            //email: auth.user.email,
            //phone: parseInt(auth.user.telephone)
        });
    }

    //Get kkiapay reponse after paiement success
    const successHandler = async (response) => {

        setLoading(true); // Début du chargement

        //Recuperation du code de la transaction de kkiapay
        var code_transaction = response.transactionId
        // Enregistrer le paiement
        let paiementsInfo = { montant: montant_payer, reference_dossier: response.data.reference_dossier, modepaiement_id: response.data.mode_paiement_id }
        await PaiementsService.store(code_transaction, paiementsInfo, token).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                //Redirection sur la page de login
                navigate(myroutes.login)
                Swal.fire({
                    icon: 'success',
                    title: 'Enregistrement réussi',
                    text: res.data.data.transaction.message,
                });

                // Actualiser la page
                /*setTimeout(() => {
                    window.location.reload();
                }, 1000);*/
            } else if (res.data.data.echec) {
                setLoading(false); // Fin du chargement en cas d'erreur de validation

                Swal.fire({
                    icon: 'warning',
                    title: 'Une erreur s\'est produite !',
                    text: res.data.data.echec,
                });
            } else if (res.data.erreur) {
                setLoading(false); // Fin du chargement en cas d'erreur de validation

                Swal.fire({
                    icon: 'error',
                    title: 'Erreur!',
                    text: res.data.message,
                });
            }
        }).catch(error => {
            setLoading(false); // Fin du chargement en cas d'erreur de validation
            console.log("Error", error);
        });

    }


    useEffect(() => {
        //checkPaiement();
        fetchModepaiements();

        const checkPaiement = async () => {
            const url = location.pathname; // Utilisation de location.pathname pour obtenir l'URL sans le nom de domaine
            const referenceDossier = url.split("paiement/")[1]; // Obtenez la partie de l'URL après "paiement/"
            if (referenceDossier) {
                setLoading(true);
                try {
                    //console.log(referenceDossier);

                    const response = await PaiementsService.checkPaiement({ reference_dossier: referenceDossier }, token);
                    if (response.status === 200) {
                        console.log(response.data.data);
                        setModePaiement_Id(response.data.data.mode_paiement_id);
                        setReferenceDossier(response.data.data.reference_dossier);
                        setLoading(false); 
                        $("#button-payer").css("display", "");
                    }
                } catch (error) {
                    //Swal.close();
                    $("#button-payer").css("display", "none");

                    //console.log(error);
                    setLoading(false); 
                    setIsDisabled(true);

                    afficherOneMessageError(error.response.data.message);
                }
            }
        }

        checkPaiement(); // Appel de la fonction au chargement de la page

        addKkiapayListener('success', successHandler)
        return () => {
            removeKkiapayListener('success', successHandler)
        };
    }, []);
    ///############### END METHODES #############################//

    return (
        <React.Fragment>
            <main
                style={{
                    backgroundImage: "url('../NiceAdmin/assets/img/log3.webp')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <section className="header-login mb-3">
                    <div className="container p-1 pt-3">
                        <div className="row align-items">
                            <div className="col-md-2">
                                <Link to={myroutes.accueil}>
                                    <img
                                        style={{
                                            height: "100px",
                                            width: "120px",
                                            paddingLeft: "20px",
                                        }}
                                        src="/NiceAdmin/assets/img/logo-blanc.png"
                                        alt="logo-blanc"
                                    />
                                </Link>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-6">
                                <h3
                                    className="fw-bold"
                                    style={{
                                        fontSize: "24px",
                                        textAlign: "center",
                                    }}
                                >
                                    COUR D'APPEL DE COMMERCE - COTONOU
                                </h3>
                                <hr />
                                <p
                                    className="fw-bold text-warning"
                                    style={{
                                        fontWeight: "lighter",
                                        fontSize: "21px",
                                        textAlign: "center",
                                    }}
                                >
                                    Plateforme d'Enrôlement et de Gestion des Procédures
                                </p>
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-2">
                                <img
                                    style={{
                                        height: "70px",
                                        width: "230px",
                                        paddingRight: "50px",
                                        paddingTop: "20px",
                                    }}
                                    src="/NiceAdmin/assets/img/ministere.png"
                                    alt="logo-blanc"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <section className="mt-3">
                    <div className="container mt-2">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div className="container mt-0"></div>
                                <div className="card mb-3 mt-3" style={{ width: "550px" }}>
                                    <div className="card-body">
                                        <div className="pt-3 pb-2">
                                            <div className="pt-0 pb-2 text-center mb-3">
                                                <h5
                                                    className="card-title text-center pb-0 fs-4"
                                                    style={{ color: "#3B7D82" }}
                                                >
                                                    Espace de paiement
                                                </h5>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <strong className="mb-4 me-3">Mode de paiements disponibles</strong>
                                            </div>

                                            <div
                                                className="auth-formxx"
                                            >

                                                <div className="row mb-4 pt-2 p-fluid">
                                                    {modespaiements.length > 0 && modespaiements.map((item, index) => (
                                                        <div className="col-md-4" key={index}>
                                                            <div className="p-field">
                                                                <RadioButton className="me-2" inputId={`option-${index}`} required value={item.code} onChange={handleSetModePaiement} checked={mode_paiement_id === item.code} />
                                                                <label htmlFor={`option-${index}`} className="fw-bold">{item.libelle}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="text-center">
                                                    <ButtonWithSpinner loading={loading} type="submit" id="button-payer" onClick={storePaiement} style={{ color: "#000", fontSize: "18px" }} className="btn app-btn-dark bg-warning  text-black w-100 mx-auto fw-bold" >
                                                        Payer
                                                    </ButtonWithSpinner>
                                                </div>
                                                <br />
                                                <br />
                                            </div>

                                            

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </section>
                <section className="footer-login">
                    <div className="col-md-12 d-flex w-100 ">
                        <div
                            className="col-md-4"
                            style={{ height: "8px", backgroundColor: "#008559" }}
                        ></div>
                        <div
                            className="col-md-4"
                            style={{ height: "8px", backgroundColor: "#FFD400" }}
                        ></div>
                        <div
                            className="col-md-4"
                            style={{ height: "8px", backgroundColor: "#E43009" }}
                        ></div>
                    </div>
                    <div className="container text-center py-2">
                        <small className="copyright">
                            &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE -
                            COTONOU | Tous droits réservés.
                            {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
                        </small>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}
