import http from "./http";

class DemandeArretsService {
    index(token) {
        return http.get("/api/v01/web/demandesarrets/index", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    countDossiersByStatut(token) {
        return http.get("/api/v01/web/demandes_arrets/count", { headers: { 'Authorization': 'Bearer ' + token } })
    }

    store(data, token) {
        return http.post("/api/v01/web/demandesarrets/store", data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    storePaiement(code_transaction, data, token) {
        return http.post(`/api/v01/web/demandearret_paiement_store/${code_transaction}`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    changeStatut(ref, table, statut, token) {
        return http.get(`/api/v01/web/update/${ref}/${table}/${statut}`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    get_stats(token) {
        return http.get("/api/v01/web/demandesarrets/count_by_month", { headers: { 'Authorization': 'Bearer ' + token } })
    }

}

export default new DemandeArretsService()