import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import Swal from "sweetalert2";
import { logoutRequest } from "../../auth/logout";
import { resetAuthData } from "../../../app/providers/authSlice";

export default function SideBar() {
  const auth = useSelector((state) => state.auth);
  const profils = useSelector((state) => state.auth.profil);

  const hasAccessToSensitiveInfo = profils.includes("USER")
  const hasAccessToSensitiveInfoActeur = profils.includes("AJ")

  //console.log(hasAccessToSensitiveInfo)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // se déconnecter
  const handleLogout = () => {
    Swal.fire({
      icon: "question",
      text: "Voulez-vous vraiment vous déconnecter ? Cela terminera votre session actuelle.",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutRequest(auth.token);
        dispatch(resetAuthData());
        navigate(myroutes.login);
        Swal.fire({
          icon: "success",
          text: "Vous vous êtes déconnecté avec succès",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          background: "green",
          color: "white",
          iconColor: "white",
        });
      }
    });
  };

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <React.Fragment>
      {/* ======= Sidebar ======= */}
      <aside id="sidebar" className="sidebar" style={{ textTransform: "" }}>
        <p
          className="fw-bold text-warning"
          style={{
            fontWeight: "lighter",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          Plateforme d'Enrôlement et de Gestion des Procédures
        </p>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              className={`nav-link ${currentPath === myroutes.dashboard ? "active" : ""}`}
              to={hasAccessToSensitiveInfo ? myroutes.dashboard_newuser : myroutes.dossiersacteurs_creer}
            >
              <i className="bi bi-grid"></i>
              <span>Accueil</span>
            </Link>
          </li>

          {/* End Dashboard Nav */}
          {hasAccessToSensitiveInfoActeur && (
            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#dossiers-nav"
                data-bs-toggle="collapse"
              >
                <i className="bi bi-journal-text"></i>
                <span>Dossiers</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </Link>
              <ul
                id="dossiers-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={myroutes.dossiersacteurs_index} className={`${currentPath == myroutes.dossiersacteurs_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Mes dossiers</span>
                  </Link>
                </li>
                {/**
                 * <li>
                  <Link to={myroutes.dossierspartiesdocumentsacteurs_index} className={`${currentPath == myroutes.dossierspartiesdocumentsacteurs_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Liste des pièces</span>
                  </Link>
                </li>
                 */}
                <li>
                  <Link to={myroutes.dossierspartiesdocumentsacteurs_create} className={`${currentPath == myroutes.dossierspartiesdocumentsacteurs_create && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Ajouter une pièce</span>
                  </Link>
                </li>
              </ul>
            </li>
          )}

          {hasAccessToSensitiveInfoActeur &&
            <li className="nav-item">
              <Link
                className="nav-link collapsed" to={myroutes.dashboard_acteur}>
                <i className="bi bi-speedometer"></i>
                <span>Tableau de bord</span>
              </Link>
            </li>
          }

          {profils.includes("AGEC") || profils.includes("APDT") ? (

            <li className="nav-item">
              <Link
                className="nav-link collapsed"
                data-bs-target="#dossiers-nav"
                data-bs-toggle="collapse"
              >
                <i className="bi bi-journal-text"></i>
                <span>Dossiers</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </Link>
              <ul
                id="dossiers-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={myroutes.dossiersacteurs_index}>
                    <i className="bi bi-circle"></i>
                    <span>Mes dossiers</span>
                  </Link>
                </li>
              </ul>
            </li>
          ) : null}


          {/***
           * {!profils.includes("USER") && (
            <li className="nav-item">
              <Link
                className="nav-link collapsed" to={myroutes.demande_arret_create}>
                <i className="bi bi-envelope-paper"></i>
                <span>Demande de copie ou grosse</span>
              </Link>
            </li>
          )}
           */}

          {/* End Forms Nav */}


          {/* End Register Page Nav */}
          <li className="nav-item">
            <Link
              to={myroutes.change_passwordacteur}
              className="nav-link collapsed"
            >
              <i className="bi bi-p-square"></i>
              <span>Changer mot de passe</span>
            </Link>
          </li>

          <li>
            <a
              onClick={() => handleLogout()}
              className="nav-link collapsed"
              href="#"
            >
              <i className="bi bi-box-arrow-right"></i>
              <span>Se déconnecter</span>
            </a>
          </li>
          {/* End Login Page Nav */}

          {/* End Error 404 Page Nav */}

          {/* End Blank Page Nav */}
        </ul>
      </aside>
      {/* End Sidebar*/}
    </React.Fragment>
  );
}
