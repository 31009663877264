class StatutDossierEnum {
    // Méthode statique pour accéder à l'énumération des statuts des dossiers
    static StatutsDossiers = Object.freeze({
        NOUVEAU: { id: 1, label: 'Nouveau' },
        RENVOYE: { id: 2, label: 'Renvoyé' },
        EN_DELIBERE: { id: 3, label: 'En délibéré' },
        VIDE: { id: 4, label: 'Vidé' },
        RADIE: { id: 5, label: 'Radié' }
    });

    // Fonction pour vérifier si un statut correspond au statut "VIDE"
    static isStatusVide(statut_dossier_id) {
        return statut_dossier_id === StatutDossierEnum.StatutsDossiers.VIDE.id;
    }

    static isStatusNouveau(statut_dossier_id) {
        return statut_dossier_id === StatutDossierEnum.StatutsDossiers.NOUVEAU.id;
    }

    static isStatusRenvoye(statut_dossier_id) {
        return statut_dossier_id === StatutDossierEnum.StatutsDossiers.RENVOYE.id;
    }

    static isStatusEnDelibere(statut_dossier_id) {
        return statut_dossier_id === StatutDossierEnum.StatutsDossiers.EN_DELIBERE.id;
    }

    static isStatusRadie(statut_dossier_id) {
        return statut_dossier_id === StatutDossierEnum.StatutsDossiers.RADIE.id;
    }
}

export default StatutDossierEnum;