import http from "./http";

class PartiesprofilsService {
    index(token){
        return http.get("/api/v01/web/partiesprofils/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    store(data, token){
        return http.post("/api/v01/web/partiesprofils/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    delete(ref,token){
        return http.delete(`/api/v01/web/partiesprofils/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }
}

export default new PartiesprofilsService()