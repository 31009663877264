import http from "./http"

class DiscussionsService {
    index(ref, token){
        return http.get(`/api/v01/web/discussions/index/${ref}`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(data, token){
        return http.post("/api/v01/web/discussions/store", data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new DiscussionsService()