import http from "./http";

class ChambresService {
    index(token){
        return http.get("/api/v01/web/chambres/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    store(data, token){
        return http.post("/api/v01/web/chambres/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    edit(ref,token){
        return http.get(`/api/v01/web/chambres/${ref}/edit`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    update(ref, data, token){
        return http.put(`/api/v01/web/chambres/${ref}/update`, data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    delete(ref,token){
        return http.delete(`/api/v01/web/chambres/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }
}

export default new ChambresService()