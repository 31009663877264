import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import StatutsService from "../../../../services/StatutsService";
import { useSelector } from "react-redux";
import ProfilsService from "../../../../services/ProfilsService";
import HabilitationsService from "../../../../services/HabilitationsService";
import { MultiSelect } from "primereact/multiselect";
import { filterStatut } from "../../../../utils/helpers";

export default function ProfilsEdit() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('')
    const [code, setCode] = useState('')
    const [statut_id, setStatut_id] = useState('')
    const [statuts, setStatuts] = useState([])

    const [selectedOptionHabilitations, setSelectedOptionHabilitations] = useState([]);
    const [habilitations, setHabilitations] = useState([]);

    //get liste habilitations
    const fetchHabilitations = async () => {
        await HabilitationsService.index(token)
            .then((res) => {
                setHabilitations(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const datahabilitations = habilitations.map((item) => {
        return {
            label: item.libelle,
            value: item.id,
        };
    });

    const handleSelectedHabilitationsChange = (selectedOptionHabilitations) => {
        setSelectedOptionHabilitations(selectedOptionHabilitations);
    };

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                
                setStatuts(filterStatut(responseData.data));
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };



    ///Save form data
    const handleUpdate = async (event) => {
        event.preventDefault()

        if (selectedOptionHabilitations.length == 0 || libelle.length < 3 || statut_id.length == 0 || code.length < 2) {
            setValidationError("Vous n'avez pas renseigner tous les champs obligatoires")
            return;
        }
        let form = {
            libelle, code, description, statut_id, selectedOptionHabilitations
        }

        await ProfilsService.update(location.state.ref, form, token).then((response) => {
            //redirection sur index
            if (response.status === 200) {
                navigate(myroutes.profils_index)
                Swal.fire({
                    icon: "success",
                    text: response.data.message,
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                })

            } else {
                setValidationError(response.data.message)
            }

        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.response.data.message
            })
            //affichage des erreurs sur le formulaire
            setValidationError(error.erreur)
        });
    }

    const edit = async () => {
        try {
            const response = await ProfilsService.edit(location.state.ref, token);
            //console.log("API response", response);
            if (response.data) {
                const data = response.data.data;
                const habilitations = response.data.habilitations;
                // console.log("data", data);
                setLibelle(data.libelle);
                setDescription(data.description);
                setCode(data.code);
                setStatut_id(data.statut_id);
                setSelectedOptionHabilitations(habilitations.map(habilitation => habilitation.id));


            } else {
                console.log("Data not found in API response");
                // Gérer le cas où response.data ou response.data.statut est undefined ou null
            }
        } catch (error) {
            console.error("API error", error);
            Swal.fire({
                icon: "error",
                text: error.message
            });
            setValidationError(error.erreur);
        }
    };


    useEffect(() => {
        fetchStatuts();
        fetchHabilitations();
        edit();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">


                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Profils </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Modification </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleUpdate}>
                                    <Row>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Libelle <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                                    value={libelle}
                                                    name="libelle"
                                                    aria-label="libelle"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="text"
                                                    required

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Code <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setCode(e.target.value)}
                                                    value={code}
                                                    name="code"
                                                    aria-label="code"
                                                    maxLength={100}
                                                    minLength={2}
                                                    type="text"
                                                    required

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} sm={12} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Statut <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="statut_id"
                                                    name="statut_id"
                                                    value={statut_id}
                                                    // required
                                                    onChange={(e) => {
                                                        setStatut_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {statuts.length > 0 &&
                                                        statuts.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-4">
                                                <Form.Label className="fw-bold">
                                                    Ajouter le(s) habilitation(s) du profil <i className="text-danger">*</i>{" "}
                                                </Form.Label>
                                                <MultiSelect
                                                    value={selectedOptionHabilitations}
                                                    onChange={(e) => {
                                                        setSelectedOptionHabilitations(e.value);
                                                        handleSelectedHabilitationsChange(e.value);
                                                    }}
                                                    options={datahabilitations}
                                                    optionLabel="label"
                                                    placeholder="Sélectionner"
                                                    maxSelectedLabels={10}
                                                    className="w-full md:w-25rem"
                                                    style={{ width: "100%", height: "40px" }}
                                                    filter
                                                    filterPlaceholder="Rechercher"
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label>Description  <i className="text-danger"></i>
                                                </Form.Label>
                                                <textarea value={description} name="resume" id="" cols="30" onChange={(e) => setDescription(e.target.value)} rows="5" className="form-control summernote border border rounded-2" placeholder=""></textarea>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.profils_index)}>Annuler</Button>                            </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}