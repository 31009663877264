import http from "./http";

class DossierspartiesdocumentsService {
    index(token) {
        return http.get("/api/v01/web/dossierspartiesdocuments/index", { headers: { "Content-Type": "multipart/form-data", 'Authorization': 'Bearer ' + token } })
    }

    getDossiersFiles(id, token) {
        return http.get(`/api/v01/web/dossierspartiesdocuments/dossier/${id}`, { headers: { 'Authorization': 'Bearer ' + token } })
    }


    store(data, token) {
        return http.post("/api/v01/web/dossierspartiesdocuments/store", data, { headers: { "Content-Type": "multipart/form-data", 'Authorization': 'Bearer ' + token } })
    }

    completeFile(data, token) {
        return http.post("/api/v01/web/dossierspartiesdocuments/completeFile", data, { headers: { "Content-Type": "multipart/form-data", 'Authorization': 'Bearer ' + token } })
    }


    delete(ref, token) {
        return http.delete(`/api/v01/web/dossierspartiesdocuments/${ref}/delete`, { headers: { 'Authorization': 'Bearer ' + token } })
    }
}

export default new DossierspartiesdocumentsService()