import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../include/layout";
import $ from "jquery";
//import 'datatables.net-dt/js/dataTables.dataTables';
//import 'datatables.net-dt/css/jquery.dataTables.css';
import UtilisateursService from "../../../services/UtilisateursService";
import PaysService from "../../../services/PaysService";
import CivilitesService from "../../../services/CivilitesService";
import parse from "html-react-parser"
import ProfilsUtilisateursService from '../../../services/ProfilsUtilisateursService';
import { afficherMessagesSuccess, afficherOneMessageError, setDataTable } from '../../../utils/helpers';
import ButtonWithSpinner from '../../../components/ButtonWithSpinner';




export default function UtilisateursIndex() {

    //##########################/// METHODES ##########################///
    const [statuts, setStatuts] = useState([])
    const [pays, setPays] = useState([])
    const [utilisateurs, setUtilisateurs] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const [visible, setVisible] = useState(false);
    const [paysTrouve, setPaysTrouve] = useState([]);
    const [statutTrouve, setStatutTrouve] = useState([]);
    const [civilites, setCivilites] = useState([]);
    const [profilsUtilisateurs, setProfilsUtilisateurs] = useState([]);
    const [file, setFile] = useState('');
    const [loadingImport, setLoadingImport] = useState(false);



    //get liste civilites
    const fetchCivilites = async () => {
        await CivilitesService.index(token)
            .then((response) => {
                setCivilites(response.data.data);

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getCivilites = (civilite_id) => {
        // Convertir l'ID en nombre (si nécessaire)
        const idToFind = Number(civilite_id);

        // Recherchez le libellé dans la liste des catégories de dossiers
        const Civilite = civilites.find((item) => {
            return item.id == idToFind;
        });

        return Civilite ? Civilite.libelle : "N/A";
    };

    const fetchProfilsUtilisateurs = async () => {
        await ProfilsUtilisateursService.index(token)
            .then((response) => {
                setProfilsUtilisateurs(response.data.data);

            })
            .catch((error) => {
                console.log(error);
            });
    };



    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const statutsData = response.data.data;
            setStatuts(statutsData);
        } catch (error) {
            console.error("Erreur lors de la récupération des parties membres :", error);
        }
    };


    const getStatuts = (statut_id) => {
        // Convertir l'ID en nombre (si nécessaire)
        const idToFind = Number(statut_id);

        // Recherchez le libellé dans la liste des catégories de dossiers
        const Statut = statuts.find((item) => {
            return item.id == idToFind;
        });

        return Statut ? Statut.libelle : "N/A";
    };

    // console.log(getStatutLibelle(1))


    //get liste pays
    const fetchPays = async () => {
        await PaysService.index(token).then((res) => {
            setPays(res.data.data);
            // console.log("res.data.data", res.data.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNomPays = (paysId) => {
        const paysTrouve = pays.find(pays => pays.id === paysId);
        return paysTrouve ? paysTrouve.nom_pays : "---";
    };



    //get liste utilisaturs
    const fetchUtilisateurs = async () => {
        try {
            const response = await UtilisateursService.index(token);
            const responseData = response.data;
            // console.log("responseData", responseData)
            if (responseData.data && responseData.data.length > 0) {
                setUtilisateurs(responseData.data);
                // console.log("responseData.data", responseData.data)
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

        //Afficher dataTable
        setDataTable();
    };


    //redirection sur la page edit sans afficher les id dans l'url
    const goToEdit = (param) => {
        navigate(myroutes.utilisateurs_edit, { state: { ref: param } })
    }

    const goToShow = (ref) => {
        navigate(`${myroutes.utilisateurs_show}/${ref}`);
    }

    //suppression d'un élément
    const onDelete = (param) => {
        //confirm action
        Swal.fire({
            icon: "warning",
            text: "Voulez-vous confirmer cette action ?",
            showCancelButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui",
            confirmButtonColor: "red"
        }).then(result => {
            if (result.isConfirmed) {
                UtilisateursService.delete(param, token).then((response) => {
                    //console.log(response.data.data)
                    if (response.data.erreur) {
                        Swal.fire({
                            icon: "error",
                            text: response.data.erreur
                        })
                    } else {
                        Swal.fire({
                            icon: "success",
                            text: response.data.message
                        })
                        fetchUtilisateurs()
                    }
                }).catch(e => {
                    Swal.fire({
                        icon: "error",
                        text: e.message
                    })
                    console.log(e)
                })
            }
        })
    }

     // Fonction de gestion du changement de fichier
     const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("file", file);
        setLoadingImport(true)

        try {
            const response = await UtilisateursService.importExcel(formData, token);
            //console.log(response)
            if (response.status === 200) {
                setLoadingImport(false)
                fetchUtilisateurs();

                afficherMessagesSuccess(response.data.message || "Bravo ! Votre enregistrement a été effectué avec succès.");

                setTimeout(() => {
                    window.location.reload();
                }, 2000);

            } else {
                setLoadingImport(false)
                afficherOneMessageError(response.data.message || "Une erreur s'est produite lors de l'importation.")
            }
        } catch (error) {
            console.error("Une erreur s'est produite :", error);
            setLoadingImport(false)
            Swal.fire({
                icon: "error",
                text: "Une erreur s'est produite lors de l'importation.",
                timer: 3000,
            });
        }
    }; 



    useEffect(() => {
        fetchUtilisateurs();
        fetchStatuts();
        fetchPays();
        fetchCivilites();
        fetchProfilsUtilisateurs();
        $("#submenu-4").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///


    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Utilisateurs </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h3 className="app-page-title mb-0">Liste des Utilisateurs <Link className="btn btn-success btn-sm" to={myroutes.utilisateurs_create} title="Ajouter"><i className="bi bi-plus"></i>Ajouter</Link> </h3>
                            </div>
                            <div className="col-auto">
                                <Form className="settings-form mb-3" onSubmit={handleSubmit} encType="multipart/form-data">
                                    <div className="d-flex align-items-end">
                                        <input type="file" className="form-control border-success" accept=".xlsx, .xls" name="file" onChange={handleFileChange} required />
                                        <ButtonWithSpinner type="submit" loading={loadingImport} color={"success"} title="Importer le calendrier" className="ms-2"><i className='bi bi-upload'></i>&nbsp;Importer</ButtonWithSpinner>
                                    </div>
                                </Form>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-orders-table shadow-lg mb-5">
                            <div className="app-card-body mx-3 py-2">
                                <div className="table-responsive mt-4">

                                    <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                                        <thead className="table-info mt-2">
                                            <tr>
                                                <th className="">N°</th>
                                                <th className="">Civilités</th>
                                                <th className="">Nom </th>
                                                <th className="">Contacts</th>
                                                <th className="">Pays</th>
                                                <th className="">Profils</th>
                                                <th className="">Autres</th>
                                                <th className="">Date</th>
                                                <th className="">Statut</th>
                                                <th className="">Activité</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                                            {utilisateurs.length > 0 && utilisateurs.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="cell">{index + 1}</td>
                                                    <td className="cell">
                                                        {item.get_civilite && item.get_civilite.libelle ? item.get_civilite.libelle : "---"}
                                                    </td>

                                                    <td className="cell"><span className="fw-bold">{item.prenoms} {item.nom}</span></td>
                                                    <td className="cell">
                                                        E-mail : {item.email} <br />
                                                        Tél . : {item.telephone} <br />
                                                        Adresse : {item.adresse != null ? parse(item.adresse) : item.adresse}
                                                    </td>
                                                    <td className="cell">{getNomPays(item.pays_id)}</td>
                                                    <td className="cell">
                                                        <span>{item.get_profil_utilisateur.map((profilUtilisateur) => (
                                                            <span className='badge bg-secondary' key={profilUtilisateur.id}>{profilUtilisateur.get_profil.libelle}</span>
                                                        ))}</span>
                                                    </td>

                                                    <td className="cell">
                                                        RCCM : {item.rccm}<br />
                                                        IFU : {item.ifu}
                                                    </td>
                                                    <td className="cell"><span>{dayjs(item.created_at).format("YYYY-MM-DD")}</span>
                                                    </td>
                                                    <td className="cell">
                                                        {
                                                            (item.get_statut && item.get_statut.libelle) ?
                                                                (
                                                                    <span className={`badge ${getStatuts(item.statut_id) === 'ACTIF' ? 'bg-success' : 'bg-danger'}`}>
                                                                        { getStatuts(item.statut_id) }
                                                                    </span>
                                                                )
                                                                : <span className="badge bg-secondary">N/A</span>
                                                        }
                                                    </td>

                                                    <td className="cell">
                                                        <div className='d-flex icon-font'>
                                                            <Button className="btn btn-sm btn-primary" onClick={() => goToEdit(item.ref)} title="Modifier">
                                                                <i className="bi bi-pen-fill"></i></Button> &nbsp;&nbsp;
                                                            <Button className="btn btn-sm btn-info me-1" onClick={() => goToShow(item.ref)} title="Voir">
                                                                <i className="bi bi-eye-fill text-white"></i></Button>
                                                            <Button className="btn btn-sm btn-danger" onClick={() => onDelete(item.ref)} title="Supprimer">
                                                                <i className="bi bi-trash"></i></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </div>{/*//table-responsive*/}

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                        {/*//End table-content*/}



                    </div>{/*//container-fluid*/}
                </div>

            </main>
            {/*//app-content*/}
        </Layout>
    )
}
