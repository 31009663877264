import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";


const TabProcedures =({ dossier }) => {
  useEffect(() => {
 
}, [])
 return (
   <div className="container">
     <div className="row border-ligne" >
       <div className="col-md-8">
       <p className="th-table fw-bold">Type procédure</p>
       </div>
       <div className="col-md-4">
           <p className="th-table" style={{}}><span className="badge text-bg-secondary">{dossier && dossier.get_procedure.get_type_procedure.libelle}</span></p>
      </div>
     </div>


     <div className="row border-ligne" >
       <div className="col-md-8">
       <p className="th-table fw-bold">Procédure</p>
       </div>
       <div className="col-md-4">
           <p className="th-table" style={{}}><span className="badge text-bg-secondary">{dossier && dossier.get_procedure.libelle}</span></p>
      </div>
     </div>
   </div>
 );
};




export default TabProcedures;