import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DossiersService from "../../services/DossiersService";
import { useSelector } from "react-redux";
import DatesaudiencesService from "../../services/DatesaudiencesService";
import UtilisateursService from "../../services/UtilisateursService";
import StatutsdossiersService from "../../services/StatutsdossiersService";
import CategoriesdossiersService from "../../services/ObjetsService";
import CategoriesService from "../../services/CategoriesService";
import $ from "jquery";
import dayjs from "dayjs";
import LayoutActeur from "./inc/layout_acteur";
import { myroutes } from "../../routes/webroute";
import { getColorStatus } from "../../utils/helpers";

export default function DashboardActeur() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [filter, setFilter] = useState("today");
  const auth = useSelector((state) => state.auth);
  const profils = useSelector((state) => state.auth.profil);
  //const libelles_profils = useSelector((state) => state.auth.libelles_profils);


  const hasAccessToSensitiveInfo = (profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("USER"))
  const hasAccessToSensitiveInfoActeur = profils.includes("AJ")

  const [dossiers, setDossiers] = useState([]);
  const [dossierscount, setDossierscount] = useState(0);
  const [datesaudiences, setDatesaudiences] = useState(0);
  const [utilisateurs, setUtilisateurs] = useState(0);

  const [categoriesdossiers, setCategoriesdossiers] = useState([]);
  const [statutsdossiers, setStatutsdossiers] = useState([]);
  const [categories, setCategories] = useState([]);

  const [dossierData, setDossierData] = useState({
    nouveaudossiers: 0,
    dossiersrenvoyes: 0,
    dossiersendelibere: 0,
    dossiersvides: 0
  });

  //get liste statuts
  const fetchStatutsdossiers = async () => {
    await StatutsdossiersService.index(token)
      .then((res) => {
        setStatutsdossiers(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatutLibelle = (statut_dossier_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(statut_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const statutDossier = statutsdossiers.find((item) => {
      return item.id == idToFind;
    });

    return statutDossier ? statutDossier.libelle : "N/A";
  };

  //get liste categories dossiers
  const fetchCategoriesdossiers = async () => {
    await CategoriesdossiersService.index(token)
      .then((res) => {
        setCategoriesdossiers(res.data.data);
        // console.log("res.data.datacd", res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLibelleCategorieDossier = (categorie_dossier_id) => {
    // Vérifie si 'categoriesdossiers' est défini
    if (!categoriesdossiers) {
      console.error("La liste des catégories de dossiers n'est pas définie.");
      return "";
    }

    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(categorie_dossier_id);

    // Recherchez le libellé dans la liste des catégories de dossiers
    const categorieDossier = categoriesdossiers.find((item) => {
      return item.id === idToFind;
    });

    return categorieDossier ? categorieDossier.libelle : "";
  };

  //get liste categories dossiers
  const fetchCategories = async () => {
    await CategoriesService.index(token)
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLibelleCat = (categorie_id) => {
    // Convertir l'ID en nombre (si nécessaire)
    const idToFind = Number(categorie_id);

    // Recherchez le libellé dans la liste des catégories
    const categorie = categories.find((item) => {
      return item.id == idToFind;
    });

    return categorie ? categorie.libelle : "";
  };

  //get liste dossiers
  const fetchDossiers = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      const dossiersData = response.data.data;
      setDossiers(dossiersData);
      // console.log("Dossiers", dossiersData);
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  //get liste dossiers
  const fetchDossierscount = async () => {
    try {
      const response = await DossiersService.listeDossierPayer(token);
      if (response.data && response.data.data) {
        const dossiersDatacount = response.data.data.length;
        setDossierscount(dossiersDatacount);
      } else {
        console.error("La réponse de l'API ne contient pas de données.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  //get liste dates audiences
  const fetchDatesaudiences = async () => {
    try {
      const response = await DatesaudiencesService.index(token);
      if (response.data && response.data.data) {
        const dossiersData = response.data.data.length;
        setDatesaudiences(dossiersData);
        // console.log("Dossiers", dossiersData);
      } else {
        console.error("La réponse de l'API ne contient pas de données.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  //get liste utilisateurs
  const fetchUtilisateurs = async () => {
    try {
      const response = await UtilisateursService.index(token);
      if (response.data && response.data.data) {
        const utilisateursData = response.data.data.length;
        setUtilisateurs(utilisateursData);
      } else {
        console.error("La réponse de l'API ne contient pas de données.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des utilisateurs:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  const fetchDossierStats = async () => {
    try {
      const response = await DossiersService.countDossiersByStatut(token);
      const responseData = response.data.data;

      setDossierData({
        nouveaudossiers: responseData.nouveaudossiers,
        dossiersrenvoyes: responseData.dossiersrenvoyes,
        dossiersendelibere: responseData.dossiersendelibere,
        dossiersvides: responseData.dossiersvides,
        dossiersradies: responseData.dossiersradies,
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des statistiques :", error);
    }
  };

  useEffect(() => {
    fetchDossiers();
    fetchStatutsdossiers();
    fetchCategoriesdossiers();
    fetchDatesaudiences();
    //fetchCategories();
    //fetchUtilisateurs();
    fetchDossierscount();
    fetchDossierStats();
    $("#submenu-4").addClass("show");
  }, []);
  ///############### END METHODES #############################//

  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div style={{ height: "15px", color: "#2256A6" }}></div>
        <div className="pagetitle">
          <h1 style={{}}>Tableau de bord</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={myroutes.dashboard_acteur}>Accueil</Link>
              </li>
              <li className="breadcrumb-item active">Tableau de bord</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}

        <section className="section dashboard">

          <div className="row mb-4 justify-content-end">
            <div className="col-auto">
              {!profils.includes("HS") && !profils.includes("EXP") && !profils.includes("PR") && !profils.includes("AGR") && !profils.includes("CCC") && (
                <Link className="btn btn-success btn-sm btn-block" to={myroutes.dossiersacteurs_creer} title="Ajouter"><i className="bi bi-plus"></i>Ajouter un dossier</Link>
              )}
            </div>
          </div>
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row g-3">
                {/* Dossiers */}
                <div className="col-md-4 col-lg-2">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-primary text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-card-list"></i>
                      </div>
                      <h5 className="card-title">Mes Dossiers</h5>
                      <h6 className="card-value">{dossierscount ?? 0}</h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-2">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-success text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-wallet"></i>
                      </div>
                      <h5 className="card-title">Dossiers payés</h5>
                      <h6 className="card-value">{dossierData.dossierspayes ?? 0}</h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-2">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-info text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-calendar"></i>
                      </div>
                      <h5 className="card-title">Nouveaux dossiers</h5>
                      <h6 className="card-value">{dossierData.nouveaudossiers ?? 0}</h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-2">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-warning text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-card-checklist"></i>
                      </div>
                      <h5 className="card-title">Dossiers en délibéré</h5>
                      <h6 className="card-value">{dossierData.dossiersendelibere ?? 0}</h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-2">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-danger text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-file-earmark-font"></i>
                      </div>
                      <h5 className="card-title">Dossiers renvoyés</h5>
                      <h6 className="card-value">{dossierData.dossiersrenvoyes ?? 0}</h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-2">
                  <div className="card info-card text-center">
                    <div className="card-body">
                      <div className="card-icon bg-secondary text-white rounded-circle mx-auto mb-3">
                        <i className="bi bi-calendar2-x"></i>
                      </div>
                      <h5 className="card-title">Dossiers radiés et vidés</h5>
                      <h6 className="card-value">{dossierData.dossiersradies ?? 0} / {dossierData.dossiersvides ?? 0}</h6>
                      <span className="text-muted small">Total</span>
                    </div>
                  </div>
                </div>

                {/* End dossiers */}

                {/* Recent Sales */}
                <div className="col-md-12">
                  <div className="card recent-sales overflow-auto">
                    <div className="filter">
                      <a className="icon" href="#" data-bs-toggle="dropdown">
                        <i className="bi bi-three-dots"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                        <li className="dropdown-header text-start">
                          <h6>Filtrer</h6>
                        </li>

                        <li>
                          <a className="dropdown-item" href="#">
                            Aujourd'hui
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Ce mois
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            Cette année
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="card-body">
                      <h5 className="card-title">Mes enrôlements</h5>

                      <table className="table table-bordered table-hover app-table-hover mb-0 data-table">
                        <thead
                          className="table-primary mt-2"
                          style={{ backgroundColor: "#abbcd5" }}
                        >
                          <tr>
                            <th className="">#</th>
                            <th className="">Numéro dossier</th>
                            <th className="">Jugement attaqué</th>
                            <th className="">Partie et conseil </th>
                            <th className="">Procédure</th>
                            <th className="">Statut</th>
                            <th className="">Date d'audience</th>
                          </tr>
                        </thead>
                        <tbody className="table" style={{ textAlign: "left" }}>
                          {dossiers.length > 0 &&
                            dossiers.slice(0, 10).map((item, index) => (
                              <tr key={index}>
                                <td className="cell">{index + 1}</td>
                                <td className="cell">
                                  {item.reference_dossier}
                                </td>
                                <td className="cell">
                                  {item.jugement_attaque} du {formatDate(item.date_jugement)} au {item.provenance}
                                </td>
                                <td className="cell">
                                  {item.get_partie.length > 0 &&
                                    Object.values(item.get_partie.reduce((acc, item) => {
                                      const type_partie_id = item.type_partie_id;
                                      if (!acc[type_partie_id]) {
                                        acc[type_partie_id] = [];
                                      }
                                      acc[type_partie_id].push(item);
                                      return acc;
                                    }, {})).map((group, idx) => (
                                      <div key={idx} style={{ fontSize: "11px" }}>
                                        <p className="text-center">
                                          {group[0].type_partie_id != 1 && 'C/'}
                                        </p>
                                        {group.map((item, index) => (
                                          <p key={index}>
                                            {item.is_avocat ? "(" + item.get_assujetie.raison_sociale + ")" : item.get_assujetie.raison_sociale}
                                          </p>
                                        ))}
                                      </div>
                                    ))
                                  }
                                </td>
                                <td className="cell">
                                  {item.get_procedure.get_type_procedure ? item.get_procedure.get_type_procedure.libelle : 'N/A'
                                  }</td>
                                <td className="cell">
                                  <span className="badge text-white" style={{ backgroundColor: getColorStatus(item.statut_dossier_id)}}>
                                    {getStatutLibelle(item.statut_dossier_id)}
                                  </span>
                                </td>
                                <td className="cell">
                                  <span>
                                    {dayjs(item.date_jugement).format(
                                      "DD/MM/YYYY")}
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* End Recent Sales */}
              </div>
            </div>
            {/* End Left side columns */}

            {/* Right side columns */}
            <div className="col-lg-4"></div>
            {/* End Right side columns */}
          </div>
        </section>
      </main>
      {/* End #main */}
    </LayoutActeur>
  );
}
