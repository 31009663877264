import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../services/AuthService";
import { resetAuthData } from "../../app/providers/authSlice";
import { myroutes } from "../../routes/webroute";


const logoutRequest = async (token) => {
    await AuthService.logout(token).then((response) => {
    }).catch(error => {
        console.log(error.message)
    });
}

export default function Logout(params) {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)

};

export {logoutRequest} ;

