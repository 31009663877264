import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import $ from "jquery";
import { Button, Col, Form, Row } from "react-bootstrap";
import DossiersService from "../services/DossiersService";
import Layout from "./admin/include/layout";
import TableaudebordService from "../services/TableaudebordService";
import { convertDateToTexte, setDataTable } from "../utils/helpers";
import DatesaudiencesService from "../services/DatesaudiencesService";
import parse from "html-react-parser"



export default function TableaudebordStats() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [filter, setFilter] = useState("today");
  const auth = useSelector((state) => state.auth);
  const profils = useSelector((state) => state.auth.profil);
  const [tableaudeboard, setTableaudeboard] = useState([])
  const [datesaudiences, setDatesaudiences] = useState([])


  //get liste dates audiences
  const fetchDatesaudiences = async () => {
    try {
      const response = await DatesaudiencesService.index(token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setDatesaudiences(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  useEffect(() => {
    fetchDatesaudiences();
    $("#outils-nav").addClass("show");
  }, []);
  ///############### END METHODES #############################//

  return (
    <Layout>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Tableau de bord</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Accueil</a>
              </li>
              <li className="breadcrumb-item active">Tableau de bord</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <div className="row g-3 mb-0  align-items-center justify-content-between">
          <div className="card bg-warningx pt-1" style={{ color: "#264f52", backgroundColor: "#3b7d821e", border: "1px solid white", }}>
            <h5 className="pt-1">
              <i className="bi bi-caret-right-fill"></i> LISTE DES DOSSIERS PAR CHAMBRE ET PAR AUDIENCE
            </h5>
          </div>
        </div>
        <div className="app-card app-card-orders-table shadow-lg mb-5">
          <div className="app-card-body mx-3 py-2">
            <div className="table-responsive mt-4">
              <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                <thead className="table mt-2">
                  <tr>
                    <th className="">N°</th>
                    <th className="">DATE D'AUDIENCE</th>
                    <th className="">DOSSIER AU RÔLE</th>
                    <th className="">CHAMBRE</th>
                    <th className="">STATUT DOSSIER</th>
                    <th className="">OBSERVATION</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                  {datesaudiences.length > 0 && datesaudiences.map((item, index) => (
                    <tr key={index}>
                      <td className="cell">{index + 1}</td>
                      <td className="cell">{item.get_calendrier && convertDateToTexte(item.get_calendrier.jour)}</td>
                      <td className="cell">{item.get_dossier && item.get_dossier.reference_dossier}</td>
                      <td className="cell">{item.get_chambre && item.get_chambre.libelle}</td>
                      <td className="cell">
                        <span className="badge text-bg-secondary">{item.get_dossier.get_statut_dossier.libelle}</span>
                      </td>
                      <td className="cell" ><span className="text-truncate">{item.observation && parse(item.observation)}</span></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>{/*//table-responsive*/}

          </div>{/*//app-card-body*/}
        </div>
      </main>
      {/* End #main */}
    </Layout>
  );
}
