import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Chart } from 'primereact/chart';
import Layout from "./admin/include/layout";
import $ from "jquery"
import DemandeArretsService from "../services/DemandeArretsService";
import DossiersService from "../services/DossiersService";
import RolesaudiencesService from "../services/RolesaudiencesService";


export default function Statistiques() {
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const [filter, setFilter] = useState("today");
    const auth = useSelector((state) => state.auth);
    const profils = useSelector((state) => state.auth.profil);

    const [chartRoleAudience, setChartRoleAudience] = useState({});
    const [chartRoleAudienceOptions, setChartRoleAudienceOptions] = useState({});

    const [chartDossier, setChartDossier] = useState({});
    const [chartDossierOptions, setChartDossierOptions] = useState({});

    const [chartDemandeArret, setChartDemandeArret] = useState({});
    const [chartdemandeArretOptions, setChartDemandeArretOptions] = useState({});

    //Graphe roles_d'audiences
    const fetchRolesaudiences = async () => {
        await RolesaudiencesService.get_stats(token).then((response) => {
            if (response.status === 200) {
                const resData = response.data;
                const abscisse = resData.map(option => option.month)
                const chartData = {
                    labels: abscisse,
                    datasets: [
                        {
                            label: 'Roles d\'audiences',
                            backgroundColor: [
                                'rgba(0, 128, 0, 0.2)',    // Vert
                                'rgba(255, 255, 0, 0.2)',  // Jaune
                                'rgba(255, 69, 0, 0.2)',    // Rouge
                                'rgba(34, 139, 34, 0.2)',  // Vert foncé
                                'rgba(218, 165, 32, 0.2)', // Or
                                'rgba(255, 215, 0, 0.2)',  // Or
                                'rgba(173, 255, 47, 0.2)', // Vert chartreuse
                                'rgba(255, 140, 0, 0.2)',  // Orange
                                'rgba(0, 255, 127, 0.2)',  // Vert printemps
                                'rgba(255, 218, 185, 0.2)',// Pêche
                                'rgba(0, 255, 255, 0.2)',  // Cyan
                                'rgba(255, 182, 193, 0.2)' // Rose
                            ],
                            borderColor: [
                                'rgb(0, 100, 0)',     // Vert
                                'rgb(128, 128, 0)',   // Jaune
                                'rgb(178, 34, 34)',   // Rouge
                                'rgb(0, 100, 0)',     // Vert foncé
                                'rgb(184, 134, 11)',  // Or
                                'rgb(255, 140, 0)',   // Or
                                'rgb(50, 205, 50)',   // Vert chartreuse
                                'rgb(255, 69, 0)',    // Orange
                                'rgb(0, 128, 0)',     // Vert printemps
                                'rgb(255, 218, 185)', // Pêche
                                'rgb(0, 255, 255)',   // Cyan
                                'rgb(255, 105, 180)'  // Rose
                            ],
                            borderWidth: 1,
                            data: resData.map(option => option.count)
                        }
                    ]
                };

                setChartRoleAudience(chartData)
            }
        }).catch(error => console.log(error))
    };

    const roleAudienceOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    //Graphe dossiers
    const fetchStatDossiers = async () => {
        await DossiersService.get_stats(token).then((response) => {
            if (response.status === 200) {
                const resData = response.data;
                const abscisse = resData.map(option => option.month)
                const chartData = {
                    labels: abscisse,
                    datasets: [
                        {
                            label: 'Dossiers',
                            backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                            borderColor: documentStyle.getPropertyValue('--pink-500'),
                            data: resData.map(option => option.count),
                            fill: false,
                            pointRadius: 6
                        }
                    ]
                };

                setChartDossier(chartData)
            }
        }).catch(error => console.log(error))
    };


    const dossierOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    fontColor: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    };

    const documentStyle2 = getComputedStyle(document.documentElement);
    const textColor2 = documentStyle2.getPropertyValue('--text-color');
    const textColorSecondary2 = documentStyle2.getPropertyValue('--text-color-secondary');
    const surfaceBorder2 = documentStyle2.getPropertyValue('--surface-border');

    //Graphe demande de copie d'arrêt
    const fetchStatDemandes = async () => {
        await DemandeArretsService.get_stats(token).then((response) => {
            if (response.status === 200) {
                const resData = response.data;
                const abscisse = resData.map(option => option.month)
                const chartData = {
                    labels: abscisse,
                    datasets: [
                        {
                            label: 'Demande de copie d\'arrêt',
                            backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                            borderColor: documentStyle.getPropertyValue('--blue-500'),
                            data: resData.map(option => option.count),
                            fill: false,
                            pointRadius: 6
                        }
                    ]
                };

                setChartDemandeArret(chartData)
                //console.log(abscisse)
            }
        }).catch(error => console.log(error))
    };


    const data2 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'First Dataset',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: documentStyle2.getPropertyValue('--blue-500'),
                tension: 0.4
            },
            {
                label: 'Second Dataset',
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                borderColor: documentStyle2.getPropertyValue('--pink-500'),
                tension: 0.4
            }
        ]
    };


    const demandeArretOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor2
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary2
                },
                grid: {
                    color: surfaceBorder2
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary2
                },
                grid: {
                    color: surfaceBorder2
                }
            }
        }
    };

    useEffect(() => {
        fetchRolesaudiences();
        setChartRoleAudienceOptions(roleAudienceOptions);
        setChartDossierOptions(dossierOptions);
        setChartDemandeArretOptions(demandeArretOptions);
        fetchStatDemandes();
        fetchStatDossiers();
        $("#outils-nav").addClass("show");
    }, []);
    ///############### END METHODES #############################//

    return (
        <Layout>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Tableau de bord</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="#">Accueil</a>
                            </li>
                            <li className="breadcrumb-item active">Tableau de bord</li>
                        </ol>
                    </nav>
                </div>
                {/* End Page Title */}
                <div className="row g-3 mb-0  align-items-center justify-content-between">
                    <div className="card bg-warningx pt-1" style={{ color: "#264f52", backgroundColor: "#3b7d821e", border: "1px solid white", }}>
                        <h5 className="pt-1">
                            <i className="bi bi-caret-right-fill"></i>STATISTIQUES DES DOSSIERS
                        </h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6" >
                        <div className="card" >
                            <Chart type="line" data={chartDemandeArret} options={chartdemandeArretOptions} style={{ height: "420px" }} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card p-4">
                            <Chart type="bar" data={chartDossier} options={chartDossierOptions} />
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="card p-4">
                        <Chart type="bar" data={chartRoleAudience} options={chartRoleAudienceOptions} />
                    </div>
                </div>
            </main>
            {/* End #main */}
        </Layout>
    );
}
