import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../services/StatutsService";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery"
//import 'datatables.net-bs5/js/dataTables.bootstrap5.min.mjs'
//import 'datatables.net-bs5/css/dataTables.bootstrap5.css'
import PaiementsService from "../../../services/PaiementsService";
import { afficherMessagesSuccess, afficherOneMessageError, setDataTable } from "../../../utils/helpers";
import { Dialog } from "primereact/dialog";


export default function PaiementsIndex() {

    //##########################/// METHODES ##########################///
    const [statuts, setStatuts] = useState([])
    const [pays, setPays] = useState([])
    const [paiements, setPaiements] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const [detailLabel, setDetailLabel] = useState('')
    const [statut_id, setStatut_id] = useState('')
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => setShowModal(false);
    const [titreModal, setTitreModal] = useState('')
    const [paiement_ref, setPaiementRef] = useState('')

    const [dossierData, setDossierData] = useState({
        //nouveaudossiers: 0,
        demandes_en_cours: 0,
        demandes_traitees: 0,
        demandes_remises: 0,
    });

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const liste_statuts = response.data.data;
            const filteredData = liste_statuts.filter((option) => (option.libelle == "PAYE" || option.libelle == "INTIE" || option.libelle == "EN-ATTENTE" || option.libelle == "INITIE"))

            setStatuts(filteredData);
        } catch (error) {
            console.error("Erreur lors de la récupération  :", error);
        }
    };


    const getStatuts = (statut_id) => {
        // Convertir l'ID en nombre (si nécessaire)
        const idToFind = Number(statut_id);

        // Recherchez le libellé dans la liste des catégories de dossiers
        const Statut = statuts.find((item) => {
            return item.id == idToFind;
        });

        return Statut ? Statut.libelle : "N/A";
    };


    //get liste paiements
    const fetchPaiements = async () => {
        try {
            const response = await PaiementsService.index(token);
            const responseData = response.data;
            if (responseData.data && responseData.data.length > 0) {
                setPaiements(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

        //Afficher dataTable
        setDataTable();
    };

    const changeStatut = (ref, statut, reference_paiement) => {
        setDetailLabel(ref)
        setStatut_id(statut)
        setShowModal(true)
        setTitreModal(reference_paiement)
        setPaiementRef(ref)
    }

    //Changer le statut
    const handleChangeStatut = async (e) => {
        e.preventDefault();
        let form = { ref: paiement_ref, statut_id}
        await PaiementsService.changeStatut(form).then((response) => {
            if(response.status === 200){
                setShowModal(false)
                afficherMessagesSuccess(response.data.message)
                fetchPaiements();
            }
        }).catch(error => {
            console.log(error)
            afficherOneMessageError(error.response.data.message)
        })
    }

   

    useEffect(() => {
        fetchPaiements();
        fetchStatuts();
        $("#submenu-4").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///


    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Liste des paiements </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h3 className="app-page-title mb-0">Liste des paiements</h3>
                            </div>
                        </div>{/*//row*/}



                        <div className="app-card app-card-orders-table shadow-lg mb-5">
                            <div className="app-card-body mx-3 py-2">
                                <div className="table-responsive mt-4">

                                    <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                                        <thead className="table-info mt-2">
                                            <tr>
                                                <th className="">N°</th>
                                                <th className="">Reference paiement</th>
                                                <th className="">Téléphone</th>
                                                <th className="">Mode de paiement</th>
                                                <th className="">Montant</th>
                                                <th className="">Réference transaction</th>
                                                <th className="">Statut</th>
                                                <th className="">Date</th>
                                                <th className="">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                                            {paiements.length > 0 && paiements.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="cell">{index + 1}</td>
                                                    <td className="cell fw-bold">{item.reference}</td>
                                                    <td className="cell">
                                                        {item.telephone}
                                                    </td>
                                                    <td className="cell">{item.get_mode_paiement && item.get_mode_paiement.libelle}</td>
                                                    <td className="cell">{item.montant} F CFA</td>
                                                    <td className="cell fw-bold">{item.reference_transaction}</td>
                                                    <td className="cell"><span className={getStatuts(item.statut_id) == "PAYE" ? "badge bg-success" : 'badge bg-danger'}>{getStatuts(item.statut_id)}</span></td>
                                                    <td className="cell">
                                                        <span>{dayjs(item.created_at).format("DD/MM/YYYY H:m:s")}</span>
                                                    </td>
                                                    <td className="cell">
                                                        <div className="d-flex">
                                                            {getStatuts(item.statut_id) !== "PAYE" ? <><Button className="btn btn-sm btn-primary" onClick={() => changeStatut(item.ref, item.statut_id, item.reference)} title="Changer le statut du paiement">
                                                                <i className="bi bi-arrow-repeat"></i>
                                                            </Button>&nbsp;</> : <>
                                                                {/**<Button className="btn btn-sm btn-danger" title="Télécharger la quittance">
                                                                    <i className="bi bi-file-pdf"></i>
                                                                </Button> */} </>}
                                                        </div>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>{/*//table-responsive*/}

                                {/* Modal */}
                                <Dialog
                                    header={titreModal}
                                    visible={showModal}
                                    onHide={handleCloseModal}
                                    style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
                                    headerStyle={{ background: "#f0f0f0" }}
                                >

                                    <div className="p-fluid">
                                        <div className="row">
                                            <hr />
                                            <p className="text-center fw-bold">Changer le statut de la demande </p>
                                        </div>
                                    </div>
                                    <Form onSubmit={handleChangeStatut}>
                                        <div className="row">
                                            <Col md={12} sm={12} className="formx-groupx">
                                                <Form.Group className="mb-3">
                                                    <Form.Label className="fw-bold">Statut <i className="text-danger">*</i></Form.Label>
                                                    <Form.Select
                                                        className=""
                                                        id="statut_id"
                                                        name="statut_id"
                                                        value={statut_id}
                                                        required
                                                        onChange={(e) => {
                                                            setStatut_id(e.target.value);
                                                        }}
                                                    >
                                                        <option value="" selected>
                                                            -- Sélectionnez --
                                                        </option>
                                                        {statuts.length > 0 &&
                                                            statuts.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.libelle}
                                                                </option>
                                                            ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </div>

                                        <div className="p-dialog-footerx mt-4">
                                            {/* Pied de page du modal */}
                                            <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                            <Button variant="secondary" onClick={handleCloseModal}>Annuler</Button>
                                        </div>
                                    </Form>
                                </Dialog>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                        {/*//End table-content*/}



                    </div>{/*//container-fluid*/}
                </div>

            </main>
            {/*//app-content*/}
        </Layout>
    )
}
