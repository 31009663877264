import React, { useEffect, useState } from "react";
import AppelanteForm from "./AppelanteForm";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setAppelants } from "../app/providers/authSlice";

const AppelanteFormContainer = () => {
  const [users, setUsers] = useState([{ id: 1 }]);
  const dispatch = useDispatch();
  const [dataFormBloc, setDataFormBloc] = useState([]);

  const addUser = () => {
    const newUser = { id: users.length + 1 };
    setUsers([...users, newUser]);
  };

  const removeUser = (userId) => {
    setUsers(users.filter((user) => user.id !== userId));
  };

  const handleDataFromChild = (data) => {
    const newDataFormBloc = [...dataFormBloc]; // Copie du tableau d'état

    newDataFormBloc[data.position] = data.form;
    //Verifie si le dataFormBloc est vide
    /*if (data.index < newDataFormBloc.length) {
      // L'index existe déjà
      newDataFormBloc[data.index] = data.form;
    } else {
      // L'index n'existe pas, ajouter les données
      newDataFormBloc.push(data.form);
    }*/
    setDataFormBloc(newDataFormBloc); // Mise à jour de l'état avec le nouveau tableau
    //console.log(dataFormBloc)
    // Transmission des données au reducer
    dispatch(setAppelants(newDataFormBloc));

  }

  useEffect(() => {

  }, []);

  return (
    <div>
      {users.map((user, index) => (
        <div key={user.id}>
          <div className="row g-3 mb-0  align-items-center justify-content-between">
            <div className="card bg-warningx pt-1" style={{ color: "#264f52", backgroundColor: "#3b7d821e", border: "1px solid white", }}>
              <h6 className="pt-1">
                <i className="bi bi-caret-right-fill"></i> APPELANT-E ({user.id})
              </h6>
            </div>
          </div>
          
          <AppelanteForm index={index} sendData={handleDataFromChild} />
          {(index > 0) && (
            <Button className="mb-3 me-3" variant="danger" onClick={() => removeUser(user.id)}>Supprimer</Button>
          )}
          {index === users.length - 1 && ( // Afficher les boutons uniquement pour le dernier utilisateur
            <>
              <Button className="mb-3" type="submit" variant="success me-3" onClick={addUser}>
                <i className="bi bi-plus"></i> Ajouter plus
              </Button>
              {/*<Button className="mb-3" variant="warning">Annuler</Button> */}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default AppelanteFormContainer;
