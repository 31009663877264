import Swal from "sweetalert2";
import $ from "jquery"
import StatutDossierEnum from "../enum/statutDossierEnum";

// Fonction pour afficher les messages d'erreur avec SweetAlert
export function afficherMessagesErreur(errors) {
    let messageErreur = '';

    // Parcourir les clés de l'objet erreur
    for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
            // Concaténer les messages d'erreur pour chaque clé
            messageErreur += `${key}: ${errors[key].join('<br>')}\n`; // Utilisation de '<br>' pour aller à la ligne
        }
    }

    // Afficher les messages d'erreur dans une boîte de dialogue SweetAlert
    Swal.fire({
        icon: 'error',
        title: 'Erreur de validation',
        html: messageErreur,
        toast: true,
        position: "top-end",
        timer: 10000,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        timerProgressBar: true,
    });
}



export function afficherMessagesSuccess(message) {
    Swal.fire({
        icon: "success",
        title: message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        background: "green",
        color: "white",
        iconColor: "white",
    });
}


export function afficherOneMessageError(message) {
    Swal.fire({
        icon: "error",
        title: message,
    });
}

export function copyToClipboard(ref) {
    //var copyText = document.getElementById(elementId);

    navigator.clipboard.writeText(ref);
    // afficher alert copied            
    afficherMessagesSuccess('Référence copié ! : ' + ref);

}


export function convertDateToTexte(dateString) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return new Date(dateString).toLocaleDateString('fr-FR', options);
}


export function getLastPartWithoutExtension(str) {
    // Trouver l'index de la dernière barre oblique "/"
    const lastSlashIndex = str.lastIndexOf('/');

    // Extraire la partie après la dernière barre oblique
    let lastPart = str.substring(lastSlashIndex + 1);

    // Supprimer l'extension ".pdf"
    lastPart = lastPart.replace('.pdf', '');

    return lastPart;
}

export function getPaiementKey(code) {
    if (code = "KKIAPAY") {
        return '34629910dec311ee875b1f010e87805c';
    } else {
        //Par defaut retourner la clé de KKIAPAY
        return '34629910dec311ee875b1f010e87805c';
    }
}

export function setDataTable() {
    // initialize datatable
    $(document).ready(function () {
        $('.data-table').DataTable({
            buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
            layout: {
                topStart: 'buttons'
            },
            "ordering": true,
            "language": {
                "sProcessing": "Traitement en cours ...",
                "sLengthMenu": "Afficher _MENU_ lignes",
                "sZeroRecords": "Aucun résultat trouvé",
                "sEmptyTable": "Aucune donnée disponible",
                "sLengthMenu": "Afficher &nbsp; _MENU_ &nbsp;",
                "sInfo": "_START_ ... _END_/_TOTAL_ &eacute;l&eacute;ments",
                "sInfoEmpty": "Aucune ligne affichée",
                "sInfoFiltered": "(Filtrer un maximum de _MAX_)",
                "sInfoPostFix": "",
                "sSearch": "Recherche",
                "sUrl": "",
                "sInfoThousands": ",",
                "sLoadingRecords": "Chargement...",
                "oPaginate": {
                    "sFirst": "Premier",
                    "sLast": "Dernier",
                    "sNext": "Suivant",
                    "sPrevious": "Précédent"
                },
                "oAria": {
                    "sSortAscending": ": Trier par ordre croissant",
                    "sSortDescending": ": Trier par ordre décroissant"
                }

            },
            dom: '<"float-left"l><"float-right"f>Brti<"float-right"p>',
            //   stateSave : true,
            order: [
                [0, "asc"]
            ],
            processing: true,
            serverSide: false,
            retrieve: true,
            //paging: false
        });

    });
}

export function filterStatut(liste_statuts) {
    const filteredData = liste_statuts.filter((option) => (option.libelle == "ACTIF" || option.libelle == "INACTIF" || option.libelle == "BROUILLON"))

    return filteredData;
}

export function checkProcedureHelper(procedure_id, procedures) {
    if (procedure_id) {
        const checkFirstValue = procedures.find((option) => option.libelle == 'AVENIR D\'AUDIENCE' && option.id == procedure_id);
        const checkSecondValue = procedures.find((option) => option.libelle == 'AUTRES PROCEDURES EN APPEL' && option.id == procedure_id);

        if (checkFirstValue || checkSecondValue) {
            return true;
        }
    }

    return false;
}


export function checkIsAutreProcedureHelper(procedure_id, procedures) {
    if (procedure_id) {
        const checkFirstValue = procedures.find((option) => option.libelle == 'AUTRES PROCEDURES EN APPEL' && option.id == procedure_id);

        //Verifie s'il s'agit de AUTRES PROCEDURES EN APPEL
        if (checkFirstValue) {
            //setIsAutreProcedure(true)
            return true;
        }
    }

    //setIsAutreProcedure(false)
    return false;
}

//Ordonner les dates par ordre croissant
export function sortedDates(dateString) {
    // Trier les dates par ordre croissant
    const sortedDates = dateString.sort((a, b) => {
        const dateA = new Date(a.jour);
        const dateB = new Date(b.jour);
        return dateA - dateB;
    });

    return sortedDates;
}


export function afficherErrorToast(message) {
    Swal.fire({
        icon: "error",
        title: message,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        background: "red",
        color: "white",
        iconColor: "white",
    });
}


//fonction pour adapter les couleurs selon le statut du dossier 
export function getColorStatus(status) {
    switch (true) {
        case StatutDossierEnum.isStatusNouveau(status):
            return '#378006';
        case StatutDossierEnum.isStatusRenvoye(status):
            return '#dc3545';
        case StatutDossierEnum.isStatusEnDelibere(status):
            return '#FF9800';
        case StatutDossierEnum.isStatusRadie(status):
            return '#7DCBC9';
        case StatutDossierEnum.isStatusVide(status):
            return '#5c636a';
        default:
            return '#000000'; // Couleur par défaut si aucun statut ne correspond
    }
}


