import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import AuthService from "../../services/AuthService";
import { useSelector } from "react-redux";
import Layout from "../admin/include/layout";

export default function ChangePassword() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [formData, setFormData] = useState({
    current_password: "",
    password: "",
    password_confirmation: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getPasswordStrength = () => {
    const passwordToCheck = formData.password;

    // Your regex for checking uppercase, lowercase, digit, and special characters
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[@/./?/#/!]/;

    let strength = "";

    // Ajoutez une condition pour vérifier que le mot de passe a au moins 8 caractères
    if (passwordToCheck.length < 8) {
      strength += "au moins 8 caractères, ";
    }

    if (!uppercaseRegex.test(passwordToCheck)) {
      strength += "une lettre majuscule, ";
    }

    if (!lowercaseRegex.test(passwordToCheck)) {
      strength += "une lettre minuscule, ";
    }

    if (!digitRegex.test(passwordToCheck)) {
      strength += "un chiffre, ";
    }

    if (!specialCharRegex.test(passwordToCheck)) {
      strength += "un caractère spécial (@, /, ., ?, #), ";
    }

    strength = strength.replace(/,\s*$/, "");

    if (strength === "") {
      return (
        <span style={{ color: "green", fontWeight: "bold" }}>
          Mot de passe fort
        </span>
      );
    } else {
      return (
        <span style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>
          Le mot de passe doit contenir au moins {strength}
        </span>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const strength = getPasswordStrength();
    setPasswordStrength(strength);

    try {
      const response = await AuthService.change_password(formData, token);
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "",
          text: response.data.message,
        });
        navigate(myroutes.dashboard);
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: response.data.error,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur s'est produite lors de la requête",
      });
      console.log(error.response);
    }
  };

  const toggleShowPassword = (field) => {
    switch (field) {
      case "current_password":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "password":
        setShowNewPassword(!showNewPassword);
        break;
      case "password_confirmation":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  // Update password strength on password change
  useEffect(() => {
    setPasswordStrength(getPasswordStrength(formData.password));
  }, [formData.password]);
  

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">
                    Changement de mot de passe
                  </h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Changer mon mot de passe
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h1 className="app-page-title mb-0">Enregistrement </h1>
              </div>
            </div>
            <div className="app-card app-card-settings shadow-lg mb-5">
              <div className="app-card-body py-3">
                <Row>
                  {error && <div style={{ color: "red" }}>{error}</div>}
                  {successMessage && (
                    <div style={{ color: "green" }}>{successMessage}</div>
                  )}
                </Row>
                <Form
                  className="mx-3 settings-form p-4"
                  style={{ textAlign: "left" }}
                  onSubmit={handleSubmit}
                >
                  <Row className="">
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Mot de passe actuel <i className="text-danger">*</i>
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            className="border border rounded-2"
                            type={showCurrentPassword ? "text" : "password"}
                            name="current_password"
                            value={formData.current_password}
                            onChange={handleChange}
                            required
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary toggle-password"
                            onClick={() =>
                              toggleShowPassword("current_password")
                            }
                          >
                            {showCurrentPassword ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={1} sm={1} className="form-group"></Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Nouveau mot de passe <i className="text-danger">*</i>
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            className="border border rounded-2"
                            type={showNewPassword ? "text" : "password"}
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <button
                            type="button"
                            className=" btn btn-outline-secondary toggle-password"
                            onClick={() => toggleShowPassword("password")}
                          >
                            {showNewPassword ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {passwordStrength && (
                            <div className="text-muted">{passwordStrength}</div>
                          )}
                          {passwordError && (
                            <Form.Control.Feedback type="invalid">
                              {passwordError}
                            </Form.Control.Feedback>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Confirmer mot de passe{" "}
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            className="border border rounded-2"
                            type={showConfirmPassword ? "text" : "password"}
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                          />
                          <button
                            type="button"
                            className=" btn btn-outline-secondary toggle-password"
                            onClick={() =>
                              toggleShowPassword("password_confirmation")
                            }
                          >
                            {showConfirmPassword ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Control.Feedback type="invalid">
                    {passwordError}
                  </Form.Control.Feedback>
                  <Button type="submit" variant="warning me-3">
                    Enregistrer
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => navigate(myroutes.dashboard)}
                  >
                    Annuler
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
