import React, { useState } from "react";
import ChatComponent from "./ChatComponent";



const TabDiscussions = ({dossier}) => {

  return (
    
    <div style={{fontFamily:"montserrat", color:"brown"}}>        
        <ChatComponent dossier={dossier} />
    </div>
  );
};

export default TabDiscussions;
