import http from "./http"

class DecisionsService {
    index(token){
        return http.get("/api/v01/web/decisions/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(data, token){
        return http.post("/api/v01/web/decisions/store", data,{ headers: { 'Authorization': 'Bearer '+token } })
    }
    edit(ref, token){
        return http.get(`/api/v01/web/decisions/${ref}/edit`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    show(ref, token){
        return http.get(`/api/v01/web/decisions/${ref}/show`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    update(ref, data, token){
        return http.put(`/api/v01/web/decisions/${ref}/update`, data,{ headers: { 'Authorization': 'Bearer '+token } })
    }

    findByIdDossier(dossier_id, token){
        return http.get(`/api/v01/web/decisions/${dossier_id}/infos`,{ headers: { 'Authorization': 'Bearer '+token } })
    }
    
    delete(ref, token){
        return http.delete(`/api/v01/web/decisions/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new DecisionsService()