import { Link, useLocation, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import StatutsService from "../../../services/StatutsService";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery"
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { Tag } from 'primereact/tag';

import parse from "html-react-parser"
import { Button, Col, Form } from "react-bootstrap";
import { convertDateToTexte, copyToClipboard, setDataTable } from "../../../utils/helpers";
import ChambresService from "../../../services/ChambresService";
import ChambresmembresService from "../../../services/ChambresmembresService";

export default function DatesaudiencesIndex() {

    //##########################/// METHODES ##########################///
    const [datesaudiences, setDatesaudiences] = useState([])
    const [alldatesaudiences, setAllDatesaudiences] = useState([])
    const [statuts, setStatuts] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const profils = useSelector((state) => state.auth.profil);
    const [chambres, setChambres] = useState([])
    const [chambre_id, setChambre_id] = useState('')
    const user = useSelector(state => state.auth.user);
    const location = useLocation();

    //get liste dates audiences
    const fetchDatesaudiences = async () => {
        try {
            const response = await DatesaudiencesService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                if (location.state && location.state.chambre_id) {
                    const chambreSelected = location.state.chambre_id;
                    setChambre_id(chambreSelected)
                    //Afficher dataTable
                    setDataTable();

                    //Filtrer les dates audiences par chambre
                    const filteredDatesAudiences = responseData.data.filter((item) => parseInt(item.chambre_id) === parseInt(chambreSelected))
                    setDatesaudiences(filteredDatesAudiences)
                }
                setAllDatesaudiences(responseData.data);
                //setDatesaudiences(responseData.data)

            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

        //Afficher dataTable
        //setDataTable();
    };

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const statutsData = response.data.data;
            setStatuts(statutsData);
        } catch (error) {
            console.error("Erreur lors de la récupération des parties membres :", error);
        }
    };



    //redirection sur la page edit sans afficher les id dans l'url
    const goToEdit = (param) => {
        navigate(myroutes.datesaudiences_edit, { state: { ref: param } })
    }


    const goToDossier = (dossier_ref) => {
        navigate(`${myroutes.dossiers_show}/${dossier_ref}`);
        //navigate(myroutes.dossiers_show, { state: { ref: dossier_ref } })
    }

    const handleCopyReference = (reference, itemId) => {
        copyToClipboard(reference)
    };


    //get liste chambres
    const fetchChambres = async () => {
        try {
            const response = await ChambresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                if ((profils.includes("GEC") || profils.includes("AGEC") || profils.includes("ADMIN") || profils.includes("SUPERADMIN"))) {
                    setChambres(responseData.data);
                }
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste chambres de l'utilisateur
    const fetchChambresMembres = async () => {
        try {
            const response = await ChambresmembresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                if ((!profils.includes("GEC") && !profils.includes("AGEC") && !profils.includes("ADMIN") && !profils.includes("SUPERADMIN"))) {
                    const filtered = responseData.data.filter((item) => parseInt(item.utilisateur_id) === parseInt(user.id));
                    setChambres(filtered.map((opt) => {
                        return {
                            id: opt.chambre_id,
                            libelle: opt.get_chambre && opt.get_chambre.libelle,
                        }
                    }));
                }

            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    const handleSelectChambre = (e) => {
        const chambreSelected = e.target.value;
        setChambre_id(chambreSelected)
        //Afficher dataTable
        //setDataTable();

        //Filtrer les dates audiences par chambre
        const filteredDatesAudiences = alldatesaudiences.filter((item) => parseInt(item.chambre_id) === parseInt(chambreSelected))
        setDatesaudiences(filteredDatesAudiences)
    }

    useEffect(() => {
        fetchDatesaudiences();
        fetchStatuts();
        fetchChambresMembres();
        fetchChambres();
        $("#datesaudiences-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///


    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: false, libelle: "Dates d'audiences", title: "Audiences" }} routeName={myroutes.datesaudiences_index}></BreadcrumbComponent>


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h3 className="app-page-title mb-0">Dossiers aux rôles &nbsp;
                                    {profils.includes("GEC") && <Link className="btn btn-success btn-sm" to={myroutes.datesaudiences_create} title="Ajouter"><i className="bi bi-plus"></i>Ajouter</Link>}
                                </h3>
                            </div>

                            <Col md={6} sm={6} className="form-group">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Chambre<i className="text-danger">*</i></Form.Label>
                                    <Form.Select
                                        className=""
                                        id="chambre_id"
                                        name="chambre_id"
                                        value={chambre_id}
                                        required
                                        onChange={handleSelectChambre}
                                    >
                                        <option value="" selected>
                                            -- Sélectionnez --
                                        </option>
                                        {chambres.length > 0 &&
                                            chambres.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </div>{/*//row*/}

                        <div className="app-card app-card-orders-table shadow-lg mb-5">
                            <div className="app-card-body mx-3 py-2">
                                <div className="table-responsive mt-4">

                                    <table className="table table-striped table-font table-bordered table-hover app-table-hover mb-0 data-table">
                                        <thead className="table-info mt-2">
                                            <tr>
                                                <th className="">N°</th>
                                                <th className="">Date d'audience</th>
                                                <th className="">Référence dossier</th>
                                                <th className="">Chambre</th>
                                                <th className="">Statut du dossier</th>
                                                <th className="">Motif renvoi</th>
                                                <th className="">Observation</th>
                                                {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
                                                    <th className="text-center">Activité</th>}

                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                                            {datesaudiences.length > 0 && datesaudiences.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="cell">{index + 1}</td>
                                                    <td className="cell">{convertDateToTexte(item.get_calendrier.jour)}</td>
                                                    <td className="cell">
                                                        {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) ?
                                                            <Tag severity="warning" value={item.get_dossier.reference_dossier} icon="pi pi-file" onClick={() => goToDossier(item.get_dossier.ref)} title="Afficher le dossier" />
                                                            : <Tag severity="warning" value={item.get_dossier.reference_dossier} icon="pi pi-file" />}
                                                    </td>
                                                    <td className="cell">{item.get_chambre.libelle}</td>
                                                    <td className="cell"><span className={`badge ${item.get_dossier?.get_statut_dossier.libelle === 'Nouveau' ? 'text-bg-success' : (item.get_dossier?.get_statut_dossier.libelle === 'Renvoyé' ? 'text-bg-danger' : 'text-bg-secondary')}`}>
                                                        {item.get_dossier?.get_statut_dossier.libelle}
                                                    </span></td>
                                                    <td className="cell">{item.motif_renvoie != null ? parse(item.motif_renvoie) : item.motif_renvoie}</td>
                                                    <td className="cell">{item.observation != null ? parse(item.observation) : item.observation}</td>
                                                    {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
                                                        <>
                                                            <td className="text-center">
                                                                <div className="d-flex justify-content-evenly">
                                                                    <Button className="btn btn-sm btn-primary" onClick={() => goToEdit(item.ref)} title="Modifier">
                                                                        <i className="bi bi-pen-fill"></i></Button> &nbsp;&nbsp;
                                                                    <Button
                                                                        className="btn btn-sm btn-success"
                                                                        onClick={() => handleCopyReference(convertDateToTexte(item.get_calendrier.jour), item.id)}                                                        >
                                                                        <i className="bi bi-clipboard2-fill"></i> Ref
                                                                    </Button>
                                                                </div>

                                                            </td>
                                                        </>}
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </div>{/*//table-responsive*/}

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                        {/*//End table-content*/}



                    </div>{/*//container-fluid*/}
                </div>

            </main>
            {/*//app-content*/}
        </Layout>
    )
}
