import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import { Editor } from "primereact/editor";


const BasicCreateForm = ({ onSubmit, routeBack, typesproceduresData }) => {
    const [validationError, setValidationError] = useState({})
    //navigate pour faire les redirections
    const navigate = useNavigate()

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('');
    const [type_procedure_id, setTypeprocedureID] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ libelle, description, type_procedure_id });
        //setLibelle('');
        //setDescription('');
    };


    return (
        <>
            
        <div className="row g-3 mb-4 align-items-center justify-content-between">
            <div className="col-auto">
                <h1 className="app-page-title mb-0">Enregistrement </h1>
            </div>
        </div>
            
            {/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
                <div className="app-card-body py-3">
                    <Row>
                        {
                            Object.keys(validationError).length > 0 && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-danger">
                                            <ul className="mb-0">
                                                {
                                                    <li>{validationError}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Row>
                    <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                        <Row>
                            {!typesproceduresData && <Col md={12} sm={12} className="form-group">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                    </Form.Label>
                                    <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                        value={libelle}
                                        placeholder=""
                                        name="libelle"
                                        aria-label="libelle"
                                        maxLength={100}
                                        minLength={2}
                                        type="text"
                                        required

                                    />
                                </Form.Group>
                            </Col>}

                            {typesproceduresData &&
                                <>
                                    <Col md={6} sm={6} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                                value={libelle}
                                                placeholder=""
                                                name="libelle"
                                                aria-label="libelle"
                                                maxLength={100}
                                                minLength={2}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} sm={6} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="fw-bold">Type procédure <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Select
                                                className="border  border rounded-2"
                                                id="type_procedure_id"
                                                name="type_procedure_id"
                                                value={type_procedure_id}
                                                required
                                                onChange={(e) => setTypeprocedureID(e.target.value)}
                                            >
                                                <option value="" selected disabled>
                                                    --  Sélectionnez une valeur --
                                                </option>
                                                {typesproceduresData.length > 0 &&
                                                    typesproceduresData.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.libelle}
                                                        </option>
                                                    ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </>}
                        </Row>

                        <Row>
                            <Col md={12} sm={12} className="form-group">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Description  <i className="text-danger"></i>
                                    </Form.Label>
                                    <Editor value={description} onTextChange={(e) => setDescription(e.htmlValue)} style={{ height: '100px' }} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Button type="submit" variant="warning me-3">Enregistrer</Button>
                        <Button variant="secondary" onClick={() => navigate(routeBack)}>Annuler</Button>

                    </Form>

                </div>{/*//app-card-body*/}
            </div>
        </>
    )
}

export default BasicCreateForm;