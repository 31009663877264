import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../backoffice/admin/include/layout";
import { myroutes } from "../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import StatutsService from "../../../services/StatutsService";
import $ from "jquery"
import { useSelector } from "react-redux";
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { Tag } from 'primereact/tag';

import { Accordion, AccordionTab } from 'primereact/accordion';
import ChambresService from "../../../services/ChambresService";
import {
    afficherMessagesSuccess,
    convertDateToTexte,
    getLastPartWithoutExtension,
    setDataTable,
    sortedDates
} from '../../../utils/helpers';
import RolesaudiencesService from "../../../services/RolesaudiencesService";
import parse from "html-react-parser"
import { app_url } from "../../../services/http";
import { Dialog } from 'primereact/dialog';


export default function RolesaudiencesCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)
    const [dossier_id, setDossier_id] = useState('')
    const [date_audience_id, setDateaudience_id] = useState('')
    const [dates_audiences, setDatesaudiences] = useState([])
    const [statuts, setStatuts] = useState([])
    const [statut_id, setStatut_id] = useState('')
    const [chambres, setChambres] = useState([])
    const [chambre_id, setChambre_id] = useState('')

    const [rolesaudiences, setRolesaudiences] = useState([])
    const [pdfData, setPdfData] = useState(null);
    const [visible, setVisible] = useState(false);
    const [loadingGenerate, setLoadingGenerate] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [loadingValidate, setLoadingValidate] = useState(false);


    const [showTelecharger, setShowTelecharger] = useState(false);
    const [type_role_audience, setTypeRoleAudience] = useState('')

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                //Afficher uniquement le statut ACTIF dans le select
                setStatuts(responseData.data.filter(option => option.libelle == "ACTIF"));
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste chambres
    const fetchChambres = async () => {
        try {
            const response = await ChambresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setChambres(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste dates audiences
    const fetchDatesaudiences = async () => {
        try {
            const response = await DatesaudiencesService.distinct(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                //Ne pas afficher les dates anciennes
                //Filtrer pour récupérer les dates qui sont superieures ou egale à la date du jour
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // S'assurer que l'heure est mise à 00:00:00 pour une comparaison correcte

                const filteredDates = responseData.data.filter(item => {
                    const date = new Date(item.get_calendrier.jour);
                    date.setHours(0, 0, 0, 0); // S'assurer que l'heure est mise à 00:00:00 pour une comparaison correcte
                    return date >= currentDate;
                });

                //console.log(filteredDates)
                setDatesaudiences(filteredDates);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };


    const handleSelectDateAudience = (e) => {
        setDateaudience_id(e.target.value)
        //Filtrer les données pour afficher les chambre ayant programmé des audience à la date sélectionnée
        const filterData = dates_audiences.filter(option => option.calendrier_id == e.target.value)
        setChambres(filterData.map(opt => opt.get_chambre))
        setRolesaudiences('')
        //console.log(filterData)
    }


    
    //Filter form data pour afficher les dossiers concernés
    const handleFilter = async (event) => {
        event.preventDefault()
        let form = { date_audience_id, chambre_id, statut_id, type_role_audience }
        setLoadingFilter(true);

        await RolesaudiencesService.filter(form, token)
            .then((response) => {
                //console.log(response.data)
                if (response.status == 200) {
                    const data = response.data.data
                    setRolesaudiences(data)
                    afficherMessagesSuccess(response.data.message)

                    setShowTelecharger(true);
                    
                    //Afficher DataTable
                    //setDataTable();

                } else {
                    Swal.fire({
                        icon: "error",
                        text: response.data.error,
                        showConfirmButton: true,
                        timer: 3000,
                    });
                }
            }).catch(error => {
                Swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
                console.log(error);
            }).finally(() => {
                setLoadingFilter(false);
            });
    }


    const generateFile = async () => {

        try {
            setLoadingGenerate(true);
            const response = await RolesaudiencesService.store({ rolesaudiences: rolesaudiences }, token);
            if (response.status === 200) {
                const data = response.data.data

                afficherMessagesSuccess(response.data.message)

                setPdfData(data);
                //Lancer le techargement du document
                lauchTelechargement(data.path)

            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.message
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: error.response.data.message
            });
            console.log(error);
        }

        setLoadingGenerate(false);

    };


    const validateRoleAudience = async (ref) => {
        //setVisible(false)
        setLoadingValidate(true)
        try {
            const response = await RolesaudiencesService.validate(ref, { rolesaudiences: rolesaudiences }, token);
            if (response.status === 200) {
                //console.log(response.data)
                const data = response.data.data
                if (data.statut_id == 1) {
                    navigate(myroutes.rolesaudiences_index)
                    afficherMessagesSuccess(response.data.message)
                }
            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.message
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                text: error.response.data.message
            });
            //console.log(error);
        }
        setLoadingValidate(false)

    }

    const ordonnerDates = (data) => {
        // Trier les dates par ordre croissant
        const sortedDates = data.sort((a, b) => {
            const dateA = new Date(a.get_calendrier.jour);
            const dateB = new Date(b.get_calendrier.jour);
            return dateA - dateB;
        });

        return sortedDates;
    }

    const lauchTelechargement = (path) => {
        const url = `${app_url}/storage/${path}`;
        const link = document.createElement('a');
        link.href = url;

        // Trouver l'index de la dernière barre oblique "/"
        const lastSlashIndex = path.lastIndexOf('/');

        // Extraire la partie après la dernière barre oblique
        let lastPart = path.substring(lastSlashIndex + 1);

        link.download = lastPart; // Ajoutez un nom de fichier si nécessaire
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }



    useEffect(() => {
        fetchStatuts();
        fetchDatesaudiences();
        fetchChambres();
        $("#roles_audience-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">
                        <BreadcrumbComponent params={{ isParametre: false, libelle: "Rôles d'audience", title: "Rôles d'audiences" }} routeName={myroutes.rolesaudiences_index} />

                        <div className="card">
                            <Accordion activeIndex={0}>
                                <AccordionTab header="Générer un rôle d'audience">
                                    <p className="m-0">
                                        <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleFilter}>

                                            <Row>
                                                <Col md={3} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Type de rôle<i className="text-danger">*</i></Form.Label>
                                                        <Form.Select
                                                            className=""
                                                            id="type_role_audience"
                                                            name="type_role_audience"
                                                            value={type_role_audience}
                                                            required
                                                            onChange={(e) => setTypeRoleAudience(e.target.value)}
                                                        >
                                                            <option value="" selected>
                                                                -- Sélectionnez --
                                                            </option>
                                                            <option value="avant">Rôle avant</option>
                                                            <option value="apres">Rôle après</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={4} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Date audience<i className="text-danger">*</i></Form.Label>
                                                        <Form.Select
                                                            className=""
                                                            id="date_audience"
                                                            name="date_audience"
                                                            value={date_audience_id}
                                                            required
                                                            onChange={handleSelectDateAudience}
                                                        >
                                                            <option value="" selected>
                                                                -- Sélectionnez --
                                                            </option>
                                                            {dates_audiences.length > 0 &&
                                                                ordonnerDates(dates_audiences).map((item) => (
                                                                    <option key={item.calendrier_id} value={item.calendrier_id}>
                                                                        {convertDateToTexte(item.get_calendrier.jour)}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={5} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Chambre<i className="text-danger">*</i></Form.Label>
                                                        <Form.Select
                                                            className=""
                                                            id="chambre_id"
                                                            name="chambre_id"
                                                            value={chambre_id}
                                                            required
                                                            onChange={(e) => {
                                                                setChambre_id(e.target.value);
                                                            }}
                                                        >
                                                            <option value="" selected>
                                                                -- Sélectionnez --
                                                            </option>
                                                            {chambres.length > 0 &&
                                                                chambres.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.libelle}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>

                                            </Row>

                                            <div className="row g-3 mb-0 align-items-center justify-content-between">
                                                <div
                                                    className="p-2"
                                                    style={{
                                                        color: "#264f52",
                                                        backgroundColor: "white",
                                                        border: "1px solid white",
                                                    }}
                                                >
                                                    <Button disabled={loadingFilter} type="submit" variant="success me-3"><i className="fa fa-list"></i> &nbsp;Lister</Button>

                                                    {showTelecharger && <><Button disabled={loadingGenerate} onClick={() => generateFile()} title="Télécharger le document">
                                                        <i className="fa fa-file-word"></i>&nbsp;Télécharger en word</Button>&nbsp;&nbsp;</>}

                                                    {pdfData && <>
                                                        <Button disabled={loadingValidate} variant="success" onClick={() => validateRoleAudience(pdfData.ref)} className=""><i className="bi bi-check-circle-fill"></i>&nbsp;&nbsp;Valider</Button>
                                                        &nbsp;&nbsp;</>}

                                                    &nbsp;&nbsp;<Button className="btn btn-md btn-secondary" type="button" onClick={() => navigate(myroutes.rolesaudiences_index)} title="Retour">
                                                        ↩ Retour</Button>

                                                </div>
                                            </div>
                                        </Form>

                                    </p>
                                </AccordionTab>
                            </Accordion>
                        </div>



                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">

                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>

                                <div className="table-responsive mt-4 px-4">

                                    <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table table-bordered">
                                        <thead className="table-info mt-2">
                                            <tr>
                                                <th className="">N°</th>
                                                <th className="">Chambre</th>
                                                <th className="">Date audience</th>
                                                <th className="">Numero dossier</th>
                                                <th className="">Identité des parties</th>
                                                <th className="">Observation</th>
                                                <th className="">Statut</th>
                                                <th className="">Horaire</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                                            {rolesaudiences.length > 0 && rolesaudiences.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="cell">{index + 1}</td>
                                                    <td className="cell">{item.get_chambre.libelle}</td>
                                                    <td className="cell">{convertDateToTexte(item.get_calendrier.jour)}</td>
                                                    <td className="cell">
                                                        <Tag severity="warning" value={item.get_dossier.reference_dossier} icon="pi pi-file" />
                                                    </td>
                                                    <td className="cell">
                                                        {item.get_dossier.get_partie.length > 0 &&
                                                            Object.values(item.get_dossier.get_partie.reduce((acc, item) => {
                                                                const type_partie_id = item.type_partie_id;
                                                                if (!acc[type_partie_id]) {
                                                                    acc[type_partie_id] = [];
                                                                }
                                                                acc[type_partie_id].push(item);
                                                                return acc;
                                                            }, {})).map((group, idx) => (
                                                                <div key={idx} style={{ fontSize: "11px" }}>
                                                                    <p className="text-center">
                                                                        {group[0].type_partie_id != 1 && 'C/'}
                                                                    </p>
                                                                    {group.map((opt, index) => (
                                                                        <p key={index}>
                                                                            {opt.is_avocat ? "(" + opt.get_assujetie.raison_sociale + ")" : opt.get_assujetie.raison_sociale}
                                                                        </p>
                                                                    ))}
                                                                </div>
                                                            ))
                                                        }

                                                    </td>
                                                    <td className="cell">{item.observation ? parse(item.observation) : item.observation}</td>
                                                    <td className="cell"><span className={`badge ${item.get_dossier?.get_statut_dossier?.libelle === 'Nouveau' ? 'text-bg-success' : (item.get_dossier?.get_statut_dossier?.libelle === 'Renvoyé' ? 'text-bg-danger' : 'text-bg-secondary')}`}>
                                                        {item.get_dossier?.get_statut_dossier?.libelle}
                                                    </span></td>
                                                    <td className="cell">{item.creneau_debut} - {item.creneau_fin}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>


                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}