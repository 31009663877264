import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DossiersService from "../services/DossiersService";
import { useSelector } from "react-redux";
import $ from "jquery";
import dayjs from "dayjs";
import LayoutActeur from "./acteurs/inc/layout_acteur";

export default function DashboardNewUser() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [filter, setFilter] = useState("today");
  const auth = useSelector((state) => state.auth);
  const user = useSelector(state => state.auth.user);

  const profils = useSelector((state) => state.auth.profil);
  //const libelles_profils = useSelector((state) => state.auth.libelles_profils);


  const hasAccessToSensitiveInfo = (profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("USER"))
  const hasAccessToSensitiveInfoActeur = profils.includes("AJ")

  useEffect(() => {
    
  }, []);
  ///############### END METHODES #############################//

  return (
    <LayoutActeur>
      <main id="main" className="main">
        <div style={{height:"15px", color:"#2256A6"}}></div>
        <div className="pagetitle">
          <p style={{lineHeight: "1.6", textAlign: "justify",  maxWidth: "800px"}}>

            Bonjour <span style={{color:"#2256A6"}}>{user ? user.prenoms: 'Utilisateur non connecté'}{" "}{user ? user.nom : 'Utilisateur non connecté'}</span>, <br/><br/>

            Bienvenue sur la plateforme d'enrôlement et de gestion des dossiers de la Cour d'Appel de Commerce de Cotonou ! <br /> <br />

            Votre compte est bien créé et confirmé, mais vous n'avez pas encore les accès nécessaires pour accéder aux fonctionnalités ! <br /><br />

            Veuillez contactez l'administrateur pour plus d'informations et assistance.<br /><br />

            Meilleures salutations.

        </p>
        </div>
        {/* End Page Title */}

        <section className="section dashboard">
          
        </section>
      </main>
      {/* End #main */}
    </LayoutActeur>
  );
}
