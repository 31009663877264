import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import PolesService from "../../../../services/PolesService";
import { afficherMessagesSuccess, afficherOneMessageError } from "../../../../utils/helpers";
import BasicCreateForm from "../../../../components/BasicCreateForm";
import TypesproceduresService from "../../../../services/TypesproceduresService";

export default function PolesCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const token = useSelector(state => state.auth.token)
    const [typesprocedures, setTypesprocedures] = useState([])

    //get liste Typesprocedures
    const fetchTypesprocedures = async () => {
        try {
            const response = await TypesproceduresService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setTypesprocedures(responseData.data);
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de types procedures:", error);
        }

    };

    ///Save form data
    const handleFormSubmit = async (formData) => {

        await PolesService.store(formData, token).then((response) => {
            if (response.data.status == true) {
                navigate(myroutes.poles_index)
                afficherMessagesSuccess("Bravo ! Votre enregistrement a été efectué avec succès.");
            } else {
                afficherOneMessageError(response.data.message + ' ' + response.data.data)
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message,
            });
            console.log(error);
        });
    }



    useEffect(() => {
        fetchTypesprocedures();
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: true, libelle: 'Pôles' }} routeName={myroutes.poles_index}></BreadcrumbComponent>
                        {/**Appel du formulaire de create */}
                        <BasicCreateForm onSubmit={handleFormSubmit} routeBack={myroutes.poles_index} typesproceduresData={typesprocedures}></BasicCreateForm>

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}