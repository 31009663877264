import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import AssujettiesService from '../services/AssujettiesService';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import PartiesService from '../services/PartiesService';
import { convertDateToTexte } from '../utils/helpers';
import ChambresService from '../services/ChambresService';
import ChambresmembresService from '../services/ChambresmembresService';


const TabResumeDossier = ({ dossier }) => {
    const token = useSelector(state => state.auth.token);
    const [assujetties, setAssujetties] = useState([]);
    const [parties, setParties] = useState([]);
    const [chambres, setChambres] = useState([]);
    const [chambres_membres, setChambresMembres] = useState([]);
    const profils = useSelector((state) => state.auth.profil);


    //get liste assujetties
    const fetchAssujetties = async () => {
        try {
            const response = await AssujettiesService.index(token);
            const responseData = response.data;
            if (responseData.data && responseData.data.length > 0) {
                // Filtrer les assujetties liées au dossier
                const assujettiesFiltres = responseData.data.filter(assujetti =>
                    dossier &&
                    dossier.get_partie &&
                    dossier.get_partie.length > 0 &&
                    dossier.get_partie.some(partie => partie.get_assujetie && partie.get_assujetie.ref === assujetti.ref)
                );
                setAssujetties(assujettiesFiltres);
            } else {
                console.error('Erreur dans la réponse:', responseData.message);
            }
        } catch (error) {
            console.error('Erreur lors de la requête de statuts:', error);
        }
    };
    //ORdonner et retourner celle ayant l'id la plus élevé
    const sortedData = (data) => {
        if (data.length > 0) {
            //const sortedData = [...data].sort((a, b) => b.id - a.id);
            const itemWithMaxId = data.reduce((max, item) => (item.id > max.id ? item : max), data[0]);
            //console.log(itemWithMaxId.get_calendrier.jour)
            return itemWithMaxId.get_calendrier;
        }
    };


    const onDelete = (param) => {
        AssujettiesService.delete(param, token)
            .then(() => {
                fetchAssujetties();
            })
            .catch((error) => {
                console.error('Error deleting item:', error);
                // Gérer l'erreur ici si nécessaire
            });
    };
    //get liste parties
    const fetchParties = async () => {
        try {
            const response = await PartiesService.index(token, dossier.id);
            const responseData = response.data;
            // console.log("responseData", responseData)
            if (responseData.data && responseData.data.length > 0) {
                setParties(responseData.data);
                //console.log("responseData.data", responseData.data)
            } else {
                console.error('Erreur dans la réponse:', responseData.message);
            }
        } catch (error) {
            console.error('Erreur lors de la requête de statuts:', error);
        }
    };

    //get liste chambres
    const fetchChambres = async () => {
        await ChambresService.index(token).then((res) => {
            setChambres(res.data.data);
        }).catch(error => {
            console.log(error);
        });
    };


    const getChambre = (chambreId) => {
        let chambre = chambres.find(opt => parseInt(opt.id) === chambreId);
        //console.log(chambreId)
        if (chambre) return chambre.libelle;
    };

    //get liste membres chambres
    const fetchChambresMembres = async () => {
        await ChambresmembresService.index(token).then((res) => {
            setChambresMembres(res.data.data);
        }).catch(error => {
            console.log(error);
        });
    };

    // Retourner les membres distincts d'une chambre à partir de son ID
    const getActeurChambre = (chambreId) => {
        // Filtrer les membres pour ne garder que ceux qui ont le même ID de chambre
        let membres = chambres_membres.filter(opt => parseInt(opt.chambre_id) === chambreId);
        // Si aucun membre n'est trouvé, retourner "Pas de membre"
        if (membres.length === 0) {
            return null;
        }
        // Créer un tableau pour stocker les noms des membres distincts
        let nomsMembres = [];

        let countMembre = new Set();

        // Parcourir les membres filtrés
        membres.forEach(membre => {
            // Vérifier si le nom complet du membre n'a pas déjà été ajouté à la liste
            let nomComplet = `${membre.get_utilisateur.nom} ${membre.get_utilisateur.prenoms}`;
            if (!countMembre.has(membre.utilisateur_id)) {
                // Si l'id de l'utilisateur n'est pas déjà dans la liste, l'ajouter
                nomsMembres.push(nomComplet);
                countMembre.add(membre.utilisateur_id);

            }

            /*if (!nomsMembres.includes(nomComplet)) {
              // Si le nom complet n'est pas déjà dans la liste, l'ajouter
              nomsMembres.push(nomComplet);
            }*/
        });
        //console.log(countMembre.size);

        // Retourner les noms des membres distincts sous forme de chaîne séparée par des virgules
        return nomsMembres.join(', ');
    };


    useEffect(() => {
        fetchAssujetties();
        fetchParties();
        fetchChambres();
        fetchChambresMembres();

    }, []);

    return (
        <div>
            <div className="row g-3 mb-0  align-items-center justify-content-between">
                <div className="card bg-warningx pt-1"
                    style={{ color: '#1f84d6', backgroundColor: '#1f84d621', border: '1px solid white' }}>
                    <h6 className="pt-1">
                        <i className="bi bi-eye-fill me-2"></i>APERÇU DU DOSSIER
                    </h6>
                </div>
            </div>
            <div className="container">
                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Numéro du dossier</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table"> {dossier && dossier.reference_dossier}</p>
                    </div>
                </div>
                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Partie(s) et Conseil(s)</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table">
                            {dossier && dossier.get_partie && dossier.get_partie.length > 0 &&
                                Object.values(dossier.get_partie.reduce((acc, dossier) => {
                                    const type_partie_id = dossier.type_partie_id;
                                    if (!acc[type_partie_id]) {
                                        acc[type_partie_id] = [];
                                    }
                                    acc[type_partie_id].push(dossier);
                                    return acc;
                                }, {})).map((group, idx) => (
                                    <div key={idx}>
                                        <p className="text-centerxx mt-3 fw-bold">
                                            {group[0].type_partie_id != 1 && 'C/'}
                                        </p>
                                        {group.map((dossier, index) => (
                                             <><span key={index}>
                                             {dossier.is_avocat ? ' (' + dossier.get_assujetie.raison_sociale + ')' : dossier.get_assujetie.raison_sociale}
                                         </span></>
                                        ))}
                                    </div>
                                ))
                            }
                        </p>
                    </div>
                </div>
                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Statut</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table"><span
                            className="badge text-bg-success"> {dossier && dossier.get_statut_dossier && dossier.get_statut_dossier.libelle}</span>
                        </p>
                    </div>
                </div>
                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Date d'enrôlement</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table"> {dossier && dayjs(dossier.created_at).format('DD/MM/YYYY H:m:s')}</p>
                    </div>
                </div>
                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Date d'audience</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table text-left">
                            {dossier &&
                                dossier.get_date_audience &&
                                dossier.get_date_audience.length > 0 &&
                                <span>
                                    {convertDateToTexte(sortedData(dossier.get_date_audience).jour)}
                                </span>
                            }
                        </p>
                    </div>
                </div>
                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Objet</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table"
                            style={{}}> {dossier && dossier.get_objet && dossier.get_objet.libelle}</p>
                    </div>
                </div>

                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Type de Procédure</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table"><span
                            className="badge bg-secondary">{dossier && dossier.get_procedure.get_type_procedure.libelle}</span>
                        </p>
                    </div>
                </div>

                <div className="row border-ligne">
                    <div className="col-md-4">
                        <p className="th-table fw-bold">Chambre</p>
                    </div>
                    <div className="col-md-8">
                        <p className="th-table">
                            {dossier && dossier.get_date_audience && <span>{getChambre(sortedData(dossier.get_date_audience).chambre_id)}</span>
                            }</p>
                    </div>
                </div>

            </div>
            <div className="container mt-3" style={{display : profils.includes("AJ") ? 'none' : ''}}>
                <div className="row">
                    <div className="row g-3 mb-0  align-items-center justify-content-between">
                        <div className="card bg-warningx pt-1"
                            style={{ color: '#1f84d6', backgroundColor: '#1f84d621', border: '1px solid white' }}>
                            <h6 className="pt-1">
                                <i className="fa fa-users me-2"></i>MEMBRES AUTORISES
                            </h6>
                        </div>
                    </div>
                    {dossier &&
                        dossier.get_date_audience &&
                        dossier.get_date_audience.length > 0 &&
                        dossier.get_date_audience.map((option, index) => (
                            option.get_calendrier && (
                                getActeurChambre(option.get_calendrier.chambre_id) !== null &&
                                <div key={index} className="row border-ligne mt-3">
                                    <div className="col-md-11">
                                        <div className="th-table d-flex">
                                            <img className="me-3" src="/NiceAdmin/assets/img/images.png" alt="user"
                                                style={{ width: '28px', height: '28px' }} />
                                            <p className="th-tablex fw-boldx">{getActeurChambre(option.get_calendrier.chambre_id)}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}

                </div>
            </div>
        </div>
    );
};


export default TabResumeDossier;