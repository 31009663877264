import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import StatutsService from "../../../../services/StatutsService";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import $ from "jquery"
//import 'datatables.net-dt/js/dataTables.dataTables'
//import 'datatables.net-dt/css/jquery.dataTables.css'
import PartiesService from "../../../../services/PartiesService";
import Layout from "../../../../backoffice/admin/include/layout";
import { setDataTable } from "../../../../utils/helpers";


                

export default function PartiesIndex() {

    //##########################/// METHODES ##########################///
    const [statuts, setStatuts] = useState([])
    const [pays, setPays] = useState([])
    const [parties, setParties] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const [visible, setVisible] = useState(false);
    const [paysTrouve, setPaysTrouve] = useState([]);
    const [statutTrouve, setStatutTrouve] = useState([]);



    //get liste statuts
    const fetchStatuts = async () => {
        await StatutsService.index(token).then((res) => {
            setStatuts(res.data.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const getStatutLibelle = (statutId) => {
        const statutTrouve = statuts.find(statuts => statuts.id === statutId);
        return statutTrouve ? statutTrouve.libelle : "N/A";
      };
     // console.log(getStatutLibelle(1))

    
    //get liste pays
  /*  const fetchPays = async () => {
        await PaysService.index(token).then((res) => {
            setPays(res.data.data);
           // console.log("res.data.data", res.data.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNomPays = (paysId) => {
        const paysTrouve = pays.find(pays => pays.id === paysId);
        return paysTrouve ? paysTrouve.nom_pays : "N/A";
      };
      */

      
    //get liste parties
    const fetchParties = async () => {
        try {
          const response = await PartiesService.index(token);
          const responseData = response.data;
           // console.log("responseData", responseData)
          if (responseData.data && responseData.data.length > 0) {
            setParties(responseData.data);
            //console.log("responseData.data", responseData.data)
          } else {
            console.error("Erreur dans la réponse:", responseData.message);
          }
        } catch (error) {
          console.error("Erreur lors de la requête de statuts:", error);
        }
      
        
    //Afficher dataTable
    setDataTable();
      };
      

    //redirection sur la page edit sans afficher les id dans l'url
    const goToEdit = (param) => {
        navigate(myroutes.statuts_edit, { state: { ref: param } })
    }

    //suppression d'un élément
    const onDelete = (param) => {
        //confirm action
        Swal.fire({
            icon: "warning",
            text: "Voulez-vous confirmer cette action ?",
            showCancelButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui",
            confirmButtonColor: "red"
        }).then(result => {
            if (result.isConfirmed) {
                PartiesService.delete(param,token).then((response) => {
                    if (response.data.erreur) {
                        Swal.fire({
                            icon: "error",
                            text: response.data.erreur
                        })
                    } else {
                        Swal.fire({
                            icon: "success",
                            text: response.data.message
                        })
                        fetchParties()
                    }
                }).catch(e => {
                    Swal.fire({
                        icon: "error",
                        text: e.message
                    })
                    console.log(e)
                })
            }
        })
    }

    useEffect(() => {
        fetchParties();
        fetchStatuts();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///


    return (
        <Layout>
              <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                <div className="container-xl app-card-body">

                    <div className="row g-3 align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="pagetitle">
                                <h1 className="app-page-title mb-0">Paramètres</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                        <li className="breadcrumb-item active">Parties </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/*//row*/}

                    <div className="row g-3 mb-4 align-items-center justify-content-between">
                        <div className="col-auto">
                            <h3 className="app-page-title mb-0">Liste des Parties <Link className="btn btn-success btn-sm" to={myroutes.assujetties_create} title="Ajouter"><i className="bi bi-plus"></i>Ajouter</Link> </h3>
                        </div>
                    </div>{/*//row*/}

                    <div className="app-card app-card-orders-table shadow-lg mb-5">
                        <div className="app-card-body mx-3 py-2">
                            <div className="table-responsive mt-4">
                                
                                <table className="table table-striped  table-hover app-table-hover mb-0 data-table">
                                    <thead className="table-info mt-2">
                                        <tr>
                                            <th className="">N°</th>
                                            <th className="">Référence dossier</th>
                                            <th className="">Assujetties</th>
                                            <th className="">Types parties</th>
                                            <th className="">Date modification</th>
                                            <th className="">Activité</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider" style={{textAlign : "left"}}>
                                        {parties.length > 0 && parties.map((item, index) => (
                                            <tr key={index}>
                                                <td className="cell">{index + 1}</td>
                                               <td className="cell">{item.get_dossier.reference_dossier}</td> 
                                               <td className="cell">
                                                        {item.get_assujetie !== null ? (
                                                            item.get_assujetie.type_personne === 'PM' ? 
                                                            (item.get_assujetie.raison_sociale ?? 'N/A') : 
                                                            (`${item.get_assujetie.nom ?? ''} ${item.get_assujetie.prenoms ?? ''}`) ?? 'N/A'
                                                        ) : 'N/A'}
                                                        </td>
                                                        <td className="cell">
                                                        {item.type_partie_id === 1 ? <span style={{ color: 'green' }}>Demandeur</span> : item.type_partie_id === 2 ? <span style={{ color: 'red' }}>Défendeur</span> : 'N/A'}
                                                        </td>
                                                <td className="cell"><span>{dayjs(item.updated_at).format("DD/MM/YYYY H:m:s")}</span>
                                                </td>
                                                <td className="cell">
                                                    <Button className="btn btn-sm btn-danger" onClick={() => onDelete(item.ref)} title="Supprimer">
                                                        <i className="bi bi-trash"></i></Button>
                                                </td>
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>{/*//table-responsive*/}

                        </div>{/*//app-card-body*/}
                    </div>
                    {/*//app-card*/}

                    {/*//End table-content*/}



                </div>{/*//container-fluid*/}
            </div>
           
            </main>
          {/*//app-content*/}
        </Layout>
    )
}