import { Link, useNavigate } from "react-router-dom";
import Layout from "../../include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import CalendriersGlobalsService from "../../../../services/CalendriersGlobalsService";

export default function CalendriersGlobalsCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [annee, setAnnee] = useState('')
    const [date_debut, setDateDebut] = useState('')
    const [date_fin, setDateFin] = useState('')

    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            annee, date_debut, date_fin
        }

        await CalendriersGlobalsService.store(form, token).then((response) => {
            if (response.data.status == true) {
                navigate(myroutes.calendriersglobals_index)
                //alert ("ok")
                Swal.fire({
                    icon: "success",
                    text: "Bravo ! Votre enregistrement a été efectué avec succès. ",
                    timer: 3000,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.message + ' ' + response.data.data,
                    timer: 3000,
                });
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message,
            });
            console.log(error);
        });
    }



    useEffect(() => {
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: true, libelle: 'Calendrier Global' }} routeName={myroutes.calendriersglobals_index}></BreadcrumbComponent>


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Enregistrement </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                    <Row>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date début<i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setDateDebut(e.target.value)}
                                                    value={date_debut} placeholder="" name="date_debut" aria-label="date_debut" maxLength={100}
                                                    minLength={3} type="date" required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date fin<i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setDateFin(e.target.value)}
                                                    value={date_fin} placeholder="" name="date_fin" aria-label="date_fin" maxLength={100}
                                                    minLength={3} type="date" required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Annee juridique<i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Control className="border  border rounded-2" onChange={(e) => setAnnee(e.target.value)}
                                                    value={annee} placeholder="2023-2024" name="annee" aria-label="annee" maxLength={9}
                                                    minLength={9} type="text"
                                                />
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.calendriersglobals_index)}>Annuler</Button>
                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}