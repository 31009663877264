import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardActeur from "../backoffice/acteurs/dashboardActeur";
import Dashboard from "../backoffice/admin/dashboard";
import DashboardNewUser from "../backoffice/dashboardNewUser";
import Login from "../backoffice/auth/login";
import Register from "../backoffice/auth/register";
import Loginparties from "../backoffice/auth/loginparties";
import Accueil from "../backoffice/accueil";
import TableaudebordStats from "../backoffice/tableaudebordStats";
import Statistiques from "../backoffice/statistiques";
import StatutIndex from "../backoffice/admin/parametres/statuts";
import StatutsCreate from "../backoffice/admin/parametres/statuts/create";
import StatutsEdit from "../backoffice/admin/parametres/statuts/edit";
import UtilisateursIndex from "../backoffice/admin/utilisateurs";
import UtilisateursCreate from "../backoffice/admin/utilisateurs/create";
import UtilisateursEdit from "../backoffice/admin/utilisateurs/edit";
import ProfilsIndex from "../backoffice/admin/parametres/profils";
import ProfilsCreate from "../backoffice/admin/parametres/profils/create";
import ProfilsEdit from "../backoffice/admin/parametres/profils/edit";
import CategoriesIndex from "../backoffice/admin/parametres/categories";
import CategoriesCreate from "../backoffice/admin/parametres/categories/create";
import CategoriesEdit from "../backoffice/admin/parametres/categories/edit";
import ObjetsIndex from "../backoffice/admin/parametres/Objets";
import ObjetsCreate from "../backoffice/admin/parametres/Objets/create"
import ObjetsEdit from "../backoffice/admin/parametres/Objets/edit"
import RolesmembresIndex from "../backoffice/admin/parametres/rolesmembres";
import RolesmembresCreate from "../backoffice/admin/parametres/rolesmembres/create";
import RolesmembresEdit from "../backoffice/admin/parametres/rolesmembres/edit";
import ChambresIndex from "../backoffice/admin/parametres/chambres";
import ChambresCreate from "../backoffice/admin/parametres/chambres/create";
import ChambresEdit from "../backoffice/admin/parametres/chambres/edit";
import HabilitationsIndex from "../backoffice/admin/parametres/habilitations";
import HabilitationsCreate from "../backoffice/admin/parametres/habilitations/create";
import HabilitationsEdit from "../backoffice/admin/parametres/habilitations/edit";
import MotifsvalidationsIndex from "../backoffice/admin/parametres/motifsvalidations";
import MotifsvalidationsCreate from "../backoffice/admin/parametres/motifsvalidations/create";
import PartiesIndex from "../backoffice/admin/parametres/parties";
import PartiesCreate from "../backoffice/admin/parametres/parties/create";
import PartiesIndexActeur from "../backoffice/acteurs/parametresActeurs/partiesActeur";
import PartiesCreateActeur from "../backoffice/acteurs/parametresActeurs/partiesActeur/create";
import PartiesprofilsIndex from "../backoffice/admin/parametres/partiesprofils";
import PartiesprofilsCreate from "../backoffice/admin/parametres/partiesprofils/create";
import StatutsdossiersIndex from "../backoffice/admin/parametres/statutsdossiers";
import StatutsdossiersCreate from "../backoffice/admin/parametres/statutsdossiers/create";
import DossiersdocumentsIndex from "../backoffice/admin/dossierspartiesdocuments";
import DossiersdocumentsIndexActeur from "../backoffice/acteurs/dossierspartiesdocumentsActeur";
import DossiersdocumentsCreateActeur from "../backoffice/acteurs/dossierspartiesdocumentsActeur/create";
import TypespartiesIndex from "../backoffice/admin/parametres/typesparties";
import TypespartiesCreate from "../backoffice/admin/parametres/typesparties/create";
import DossiersIndex from "../backoffice/admin/Dossiers";
//import DossiersCreate from "../backoffice/admin/Dossiers/create";
//import DossiersEdit from "../backoffice/admin/Dossiers/edit";
import DossiersShow from "../backoffice/admin/Dossiers/show";
import CreerDossiers from "../backoffice/admin/Dossiers/creer";
import DossiersIndexActeur from "../backoffice/acteurs/DossiersActeurs";
//import DossiersCreateActeur from "../backoffice/acteurs/DossiersActeurs/create";
import SaisinesIndex from "../backoffice/admin/saisines";
import SaisinesCreate from "../backoffice/admin/saisines/create";
import PartiesmembresIndex from "../backoffice/admin/parametres/partiesmembres";
import PartiesmembresCreate from "../backoffice/admin/parametres/partiesmembres/create";
import CivilitesCreate from "../backoffice/admin/parametres/civilites/create";
import CivilitesIndex from "../backoffice/admin/parametres/civilites";
import CivilitesEdit from "../backoffice/admin/parametres/civilites/edit";
import DatesaudiencesIndex from "../backoffice/admin/datesaudiences";
import DatesaudiencesCreate from "../backoffice/admin/datesaudiences/create";
import PartiesdossiersIndex from "../backoffice/admin/parametres/partiesdossiers";
import PartiesdossiersCreate from "../backoffice/admin/parametres/partiesdossiers/create";
import AssujettiesIndex from "../backoffice/admin/assujetties";
import AssujettiesCreate from "../backoffice/admin/assujetties/create";
import AssujettiesEdit from "../backoffice/admin/assujetties/edit";
import AssujettiesIndexActeur from "../backoffice/acteurs/assujettiesActeurs";
import AssujettiesEditActeur from "../backoffice/acteurs/assujettiesActeurs/edit";
import AssujettiesCreateActeurs from "../backoffice/acteurs/assujettiesActeurs/create";
import ChangePassword from "../backoffice/auth/changepassword";
import ChangePasswordActeur from "../backoffice/auth/changepasswordActeur";
import PolesIndex from "../backoffice/admin/parametres/poles";
import PolesCreate from "../backoffice/admin/parametres/poles/create";
import PolesEdit from "../backoffice/admin/parametres/poles/edit";
import ProceduresIndex from "../backoffice/admin/parametres/procedures";
import ProceduresCreate from "../backoffice/admin/parametres/procedures/create";
import ProceduresEdit from "../backoffice/admin/parametres/procedures/edit";
import TypesproceduresEdit from "../backoffice/admin/parametres/typesprocedures/edit";
import TypesproceduresIndex from "../backoffice/admin/parametres/typesprocedures";
import TypesproceduresCreate from "../backoffice/admin/parametres/typesprocedures/create";
import StatutsdossiersEdit from "../backoffice/admin/parametres/statutsdossiers/edit";
import DossiersdocumentsCreate from "../backoffice/admin/dossierspartiesdocuments/create";
import CreerDossiersActeurs from "../backoffice/acteurs/DossiersActeurs/creer";
import DatesaudiencesIndexActeur from "../backoffice/acteurs/datesaudiencesActeurs";
import RolesaudiencesIndex from "../backoffice/admin/rolesaudiences";
import RolesaudiencesCreate from "../backoffice/admin/rolesaudiences/create";
import UtilisateursShow from "../backoffice/admin/utilisateurs/show";
import ChambresmembresIndex from "../backoffice/admin/parametres/chambresmembres";
import ChambresmembresCreate from "../backoffice/admin/parametres/chambresmembres/create";
import ChambresmembresEdit from "../backoffice/admin/parametres/chambresmembres/edit";
import Voirtout from "../backoffice/admin/parametres/voirtout";
import CalendriersGlobalsIndex from "../backoffice/admin/parametres/calendriersglobals";
import CalendriersGlobalsCreate from "../backoffice/admin/parametres/calendriersglobals/create";
import CalendriersGlobalsEdit from "../backoffice/admin/parametres/calendriersglobals/edit";
import CalendriersGlobalsImportxlsx from "../backoffice/admin/parametres/calendriersglobals/importxlsx";
import CalendriersIndex from "../backoffice/admin/parametres/calendriers";
import CalendriersCreate from "../backoffice/admin/parametres/calendriers/create";
import CalendriersEdit from "../backoffice/admin/parametres/calendriers/edit";
import DatesaudiencesEdit from "../backoffice/admin/datesaudiences/edit";
import PaiementDossier from "../backoffice/paiementDossier";
import DemandeArretsCreate from "../backoffice/acteurs/demandearrets/demandeArretsCreate";
import DemandeArrets from "../backoffice/demandeArrets";
import DemandesArretsIndex from "../backoffice/admin/demandesarrets";
import StatutsJuridiquesIndex from "../backoffice/admin/parametres/statutsjuridiques";
import StatutsJuridiquesCreate from "../backoffice/admin/parametres/statutsjuridiques/create";
import StatutsJuridiquesEdit from "../backoffice/admin/parametres/statutsjuridiques/edit";
import { useSelector } from "react-redux";
import TypesdocumentsIndex from "../backoffice/admin/parametres/typesdocuments";
import TypesdocumentsCreate from "../backoffice/admin/parametres/typesdocuments/create";
import TypesdocumentsEdit from "../backoffice/admin/parametres/typesdocuments/edit";
import TypesacteursIndex from "../backoffice/admin/parametres/typesacteurs";
import TypesacteursEdit from "../backoffice/admin/parametres/typesacteurs/edit";
import TypesacteursCreate from "../backoffice/admin/parametres/typesacteurs/create";
import PaiementsIndex from "../backoffice/admin/paiements";
import TribunauxIndex from "../backoffice/admin/parametres/tpi";
import DossiersShowActeurs from "../backoffice/acteurs/DossiersActeurs/show";
import CalendriersProceduresCreate from "../backoffice/admin/calendriersprocedures/create";
import CalendriersProceduresIndex from "../backoffice/admin/calendriersprocedures";
import HistoriqueActionsIndex from "../backoffice/admin/parametres/historiqueactions";
import InsertCodeOtp from "../backoffice/auth/insertCodeOtp";
import ForgotPassword from "../backoffice/auth/forgotPassword";


//Déclaration des noms des routes

const myroutes = {

    ///############## BACK OFFICE PATH ######################///

    /* accueil: "/accueil",*/
    login: "/",
    login_acteurs: "/admin/login",
    login_parties: "/acteurs/authentification",
    register: "/register",
    dashboard: "/admin/dashboard",
    dashboard_acteur: "/acteurs/dashboard",
    dashboard_newuser: "/acteurs/dashboard_newuser",
    tableaudebordstats: "/acteurs/tableaudebordstats",
    statistiques: "/acteurs/statistiques",

    //change password
    change_password: "/admin/change_password",

    //change password Acteur
    change_passwordacteur: "/parties/change_passwordacteur",

    //profils
    profils_index: "/admin/profils/index",
    profils_create: "/admin/profils/create",
    profils_edit: "/admin/profils/edit",


    //statuts
    statuts_index: "/admin/statuts/index",
    statuts_create: "/admin/statuts/create",
    statuts_edit: "/admin/statuts/edit",

    //utilisateurs
    utilisateurs_index: "/admin/utilisateurs/index",
    utilisateurs_create: "/admin/utilisateurs/create",
    utilisateurs_edit: "/admin/utilisateurs/edit",
    utilisateurs_show: "/admin/utilisateurs/show",


    //categories
    categories_index: "/admin/categories/index",
    categories_create: "/admin/categories/create",
    categories_edit: "/admin/categories/edit",

    //objets
    objets_index: "/admin/objets/index",
    objets_create: "/admin/objets/create",
    objets_edit: "/admin/objets/edit",

    //roles membres
    rolesmembres_index: "/admin/rolesmembres/index",
    rolesmembres_create: "/admin/rolesmembres/create",
    rolesmembres_edit: "/admin/rolesmembres/edit",

    //chambres
    chambres_index: "/admin/chambres/index",
    chambres_create: "/admin/chambres/create",
    chambres_edit: "/admin/chambres/edit",

    //habilitations
    habilitations_index: "/admin/habilitations/index",
    habilitations_create: "/admin/habilitations/create",
    habilitations_edit: "/admin/habilitations/edit",

    //motifsvalidations
    motifsvalidations_index: "/admin/motifsvalidations/index",
    motifsvalidations_create: "/admin/motifsvalidations/create",
    motifsvalidations_edit: "/admin/motifsvalidations/edit",

    //parties
    parties_index: "/admin/parties/index",
    parties_create: "/admin/parties/create",
    parties_edit: "/admin/parties/edit",

    //partiesacteurs
    partiesacteurs_index: "/parties/partiesacteurs/index",
    partiesacteurs_create: "/parties/partiesacteurs/create",
    partiesacteurs_edit: "/parties/partiesacteurs/edit",

    //partiesmembres
    partiesmembres_index: "/admin/partiesmembres/index",
    partiesmembres_create: "/admin/partiesmembres/create",
    partiesmembres_edit: "/admin/partiesmembres/edit",

    //partiesprofils
    partiesprofils_index: "/admin/partiesprofils/index",
    partiesprofils_create: "/admin/partiesprofils/create",
    partiesprofils_edit: "/admin/partiesprofils/edit",

    //statutsdossiers
    statutsdossiers_index: "/admin/statutsdossiers/index",
    statutsdossiers_create: "/admin/statutsdossiers/create",
    statutsdossiers_edit: "/admin/statutsdossiers/edit",

    //typesparties
    typesparties_index: "/admin/typesparties/index",
    typesparties_create: "/admin/typesparties/create",
    typesparties_edit: "/admin/typesparties/edit",

    //dossiers
    dossiers_index: "/admin/dossiers/index",
    //dossiers_create: "/admin/dossiers/create",
    //dossiers_edit: "/admin/dossiers/edit",
    creer_dossier: "/admin/dossiers/creer",
    dossiers_show: "/admin/dossiers/show",

    //dossiers
    dossiersacteurs_index: "/parties/dossiersacteurs/index",
    //dossiersacteurs_create: "/parties/dossiersacteurs/create",
    //dossiersacteurs_edit: "/parties/dossiersacteurs/edit",
    dossiersacteurs_creer: "/parties/dossiersacteurs/creer",
    dossiersacteurs_show: "/parties/dossiersacteurs/show",


    //saisines
    saisines_index: "/admin/saisines/index",
    saisines_create: "/admin/saisines/create",
    saisines_edit: "/admin/saisines/edit",

    //civilites
    civilites_index: "/admin/civilites/index",
    civilites_create: "/admin/civilites/create",
    civilites_edit: "/admin/civilites/edit",

    //datesaudiences
    datesaudiences_index: "/admin/datesaudiences/index",
    datesaudiences_create: "/admin/datesaudiences/create",
    datesaudiences_edit: "/admin/datesaudiences/edit",
    datesaudiencesacteurs_index: "/admin/datesaudiencesacteurs/index",


    //parties dossiers
    partiesdossiers_index: "/admin/partiesdossiers/index",
    partiesdossiers_create: "/admin/partiesdossiers/create",
    partiesdossiers_edit: "/admin/partiesdossiers/edit",

    //assujetties
    assujetties_index: "/admin/assujetties/index",
    assujetties_create: "/admin/assujetties/create",
    assujetties_edit: "/admin/assujetties/edit",

    //assujetties
    assujettiesacteurs_index: "/acteurs/assujettiesacteurs/index",
    assujettiesacteurs_create: "/acteurs/assujettiesacteurs/create",
    assujettiesacteurs_edit: "/acteurs/assujettiesacteurs/edit",

    //dossierspartiesdocuments
    dossierspartiesdocuments_index: "/admin/dossierspartiesdocuments/index",
    dossierspartiesdocuments_create: "/admin/dossierspartiesdocuments/create",

    //dossierspartiesdocuments
    dossierspartiesdocumentsacteurs_index: "/acteurs/dossierspartiesdocumentsacteurs/index",
    dossierspartiesdocumentsacteurs_create: "/acteurs/dossierspartiesdocumentsacteurs/create",

    //poles
    poles_index: "/admin/poles/index",
    poles_create: "/admin/poles/create",
    poles_edit: "/admin/poles/edit",

    //procedures
    procedures_index: "/admin/procedures/index",
    procedures_create: "/admin/procedures/create",
    procedures_edit: "/admin/procedures/edit",

    //typesprocedures
    typesprocedures_index: "/admin/typesprocedures/index",
    typesprocedures_create: "/admin/typesprocedures/create",
    typesprocedures_edit: "/admin/typesprocedures/edit",

    //chambres membres
    chambresmembres_index: "/admin/chambresmembres/index",
    chambresmembres_create: "/admin/chambresmembres/create",
    chambresmembres_edit: "/admin/chambresmembres/edit",

    //calendriers globals
    calendriersglobals_index: "/admin/calendriersglobals/index",
    calendriersglobals_create: "/admin/calendriersglobals/create",
    calendriersglobals_edit: "/admin/calendriersglobals/edit",
    calendriersglobals_importxlsx: "/admin/calendriersglobals/importxlsx",



    //calendriers 
    calendriers_index: "/admin/calendriers/index",
    calendriers_create: "/admin/calendriers/create",
    calendriers_edit: "/admin/calendriers/edit",


    //voir tous les parametres
    voirtout: "/admin/parametres/voirtout",
    //rolesaudiences
    rolesaudiences_index: "/admin/rolesaudiences/index",
    rolesaudiences_create: "/admin/rolesaudiences/create",

    //Page de paiement frais dossier
    paiement_dossier: "/paiement/:referenceDossier",

    //Demande de copie d'arret
    demande_arret_create: "/admin/demande/arret/create",
    demande_arret_index: "/admin/demande/arret/index",


    //Demande de copie d'arret sur le frontoffice
    demande_arret: "/demande/arret",

    //statuts juridiques
    statutsjuridiques_index: "/admin/statutsjuridiques/index",
    statutsjuridiques_create: "/admin/statutsjuridiques/create",
    statutsjuridiques_edit: "/admin/statutsjuridiques/edit",

    //types documents
    typesdocuments_index: "/admin/typesdocuments/index",
    typesdocuments_create: "/admin/typesdocuments/create",
    typesdocuments_edit: "/admin/typesdocuments/edit",

    //types acteurs
    typesacteurs_index: "/admin/typesacteurs/index",
    typesacteurs_create: "/admin/typesacteurs/create",
    typesacteurs_edit: "/admin/typesacteurs/edit",

    //paiements
    paiements_index: "/admin/paiements/index",

    //tribunaux
    tribunaux_index: "/admin/tribunaux/index",

    //Calendriers des procédures
    calendriersprocedures_create: "/admin/calendriersprocedures/create",
    calendriersprocedures_index: "/admin/calendriersprocedures/index",

    //historiques des actions
    historiqueactions_index: "/admin/historiqueactions/index",

    //Insertion du code otp
    forgot_password: "/forgot-password",
    //Insertion du code otp
    insert_codeotp: "/new-password",


}

export { myroutes };


export default function Webroute() {
    const profils = useSelector((state) => state.auth.profil);
    const profil_habilitation = useSelector((state) => state.auth.profil_habilitation);

    return (
        <BrowserRouter>
            {/*<Suspense fallback={<div className="container">En cours...</div>}>*/}
            <Routes>

                <Route path={myroutes.paiement_dossier} name="paiement_dossier" element={<PaiementDossier />} />
                <Route path={myroutes.demande_arret} name="demande_arret" element={<DemandeArrets />} />
                <Route path={myroutes.forgot_password} name="forgot_password" element={<ForgotPassword />} />
                <Route path={myroutes.insert_codeotp} name="insert_codeotp" element={<InsertCodeOtp />} />

                {/** Start Route BACK OFFICE */}
                {profils.includes("AJ") && <Route path={myroutes.dashboard_acteur} name="dashboard_acteur" element={<DashboardActeur />} />}
                {profils.includes("USER") && <Route path={myroutes.dashboard_newuser} name="dashboard_newuser" element={<DashboardNewUser />} />}

                {profil_habilitation.includes("INTERFACE-ADMIN") && <Route path={myroutes.dashboard} name="dashboard" element={<Dashboard />} />}
                <Route path={myroutes.login} name="login" element={<Login />} />

                {/**
                 * <Route path={myroutes.accueil} name="accueil" element={<Accueil />} />
                 * <Route path={myroutes.parties_create} name="parties_create" element={<PartiesCreate />} />
                 * <Route path={myroutes.login_parties} name="login_parties" element={<Loginparties />} />
                 * <Route path={myroutes.register} name="register" element={<Register />} />
                 * //Parties Acteur 
                 * <Route path={myroutes.partiesacteurs_index} name="partiesacteurs_index" element={<PartiesIndexActeur />} />
                 * <Route path={myroutes.partiesacteurs_create} name="partiesacteurs_create" element={<PartiesCreateActeur />} />
                 * //Parties profils 
                 * <Route path={myroutes.partiesprofils_index} name="partiesprofils_index" element={<PartiesprofilsIndex />} />
                 * <Route path={myroutes.partiesprofils_create} name="partiesprofils_create" element={<PartiesprofilsCreate />} />
                 * // Parties membres 
                 * <Route path={myroutes.partiesmembres_index} name="partiesmembres_index" element={<PartiesmembresIndex />} />
                 * <Route path={myroutes.partiesmembres_create} name="partiesmembres_create" element={<PartiesmembresCreate />} />
                 *  // DatesaudiencesActeurs 
                 * <Route path={myroutes.datesaudiencesacteurs_index} name="datesaudiencesacteurs_index" element={<DatesaudiencesIndexActeur />} />
                 * Parties dossiers
                 * <Route path={myroutes.partiesdossiers_index} name="partiesdossiers_index" element={<PartiesdossiersIndex />} />
                 * <Route path={myroutes.partiesdossiers_create} name="partiesdossiers_create" element={<PartiesdossiersCreate />} />
                 */}



                {profil_habilitation.includes("INTERFACE-ADMIN") &&
                    <>
                        <Route path={myroutes.statistiques} name="statistiques" element={<Statistiques />} />
                        <Route path={myroutes.tableaudebordstats} name="tableaudebordstats" element={<TableaudebordStats />} />

                        {/** changes password */}
                        <Route path={myroutes.change_password} name="change_password" element={<ChangePassword />} />
                        {/** rolesaudiences*/}
                        <Route path={myroutes.rolesaudiences_index} name="rolesaudiences_index" element={<RolesaudiencesIndex />} />

                    </>}


                {/** utilisateurs */}
                {(profils.includes("SUPERADMIN") || profils.includes("ADMIN")) &&
                    <>
                        {/** Parties */}
                        <Route path={myroutes.parties_index} name="parties_index" element={<PartiesIndex />} />

                        <Route path={myroutes.voirtout} name="voirtout" element={<Voirtout />} />
                        <Route path={myroutes.utilisateurs_index} name="utilisateurs_index" element={<UtilisateursIndex />} />
                        <Route path={myroutes.utilisateurs_create} name="utilisateurs_create" element={<UtilisateursCreate />} />
                        <Route path={myroutes.utilisateurs_edit} name="utilisateurs_edit" element={<UtilisateursEdit />} />
                        <Route path={`${myroutes.utilisateurs_show}/:ref`} name="utilisateurs_show" element={<UtilisateursShow />} />

                        {/** statuts */}
                        <Route path={myroutes.statuts_index} name="statuts_index" element={<StatutIndex />} />
                        <Route path={myroutes.statuts_create} name="statuts_create" element={< StatutsCreate />} />
                        <Route path={myroutes.statuts_edit} name="statuts_edit" element={< StatutsEdit />} />
                        {/** profils */}
                        <Route path={myroutes.profils_index} name="profils_index" element={<ProfilsIndex />} />
                        <Route path={myroutes.profils_create} name="profils_create" element={<ProfilsCreate />} />
                        <Route path={myroutes.profils_edit} name="profils_edit" element={<ProfilsEdit />} />

                        {/** categories */}
                        <Route path={myroutes.categories_index} name="categories_index" element={<CategoriesIndex />} />
                        <Route path={myroutes.categories_create} name="categories_create" element={<CategoriesCreate />} />
                        <Route path={myroutes.categories_edit} name="categories_edit" element={<CategoriesEdit />} />

                        {/** objets */}
                        <Route path={myroutes.objets_index} name="objets_index" element={<ObjetsIndex />} />
                        <Route path={myroutes.objets_create} name="objets_create" element={<ObjetsCreate />} />
                        <Route path={myroutes.objets_edit} name="objets_edit" element={<ObjetsEdit />} />


                        {/** roles membres */}
                        <Route path={myroutes.rolesmembres_index} name="rolesmembres_index" element={<RolesmembresIndex />} />
                        <Route path={myroutes.rolesmembres_create} name="rolesmembres_create" element={<RolesmembresCreate />} />
                        <Route path={myroutes.rolesmembres_edit} name="rolesmembres_edit" element={<RolesmembresEdit />} />


                        {/** chambres */}
                        <Route path={myroutes.chambres_index} name="chambres_index" element={<ChambresIndex />} />
                        <Route path={myroutes.chambres_create} name="chambres_create" element={<ChambresCreate />} />
                        <Route path={myroutes.chambres_edit} name="chambres_edit" element={<ChambresEdit />} />

                        {/** habilitations */}
                        <Route path={myroutes.habilitations_index} name="habilitations_index" element={<HabilitationsIndex />} />
                        <Route path={myroutes.habilitations_create} name="habilitations_create" element={<HabilitationsCreate />} />
                        <Route path={myroutes.habilitations_edit} name="habilitations_edit" element={<HabilitationsEdit />} />

                        {/** Types parties */}
                        <Route path={myroutes.typesparties_index} name="typesparties_index" element={<TypespartiesIndex />} />
                        <Route path={myroutes.typesparties_create} name="typesparties_create" element={<TypespartiesCreate />} />

                        {/** statuts juridiques  */}
                        <Route path={myroutes.statutsjuridiques_index} name="statutsjuridiques_index" element={<StatutsJuridiquesIndex />} />
                        <Route path={myroutes.statutsjuridiques_create} name="statutsjuridiques_create" element={<StatutsJuridiquesCreate />} />
                        <Route path={myroutes.statutsjuridiques_edit} name="statutsjuridiques_edit" element={<StatutsJuridiquesEdit />} />

                        {/** Statuts dossiers */}
                        <Route path={myroutes.statutsdossiers_index} name="statutsdossiers_index" element={<StatutsdossiersIndex />} />
                        <Route path={myroutes.statutsdossiers_create} name="statutsdossiers_create" element={<StatutsdossiersCreate />} />
                        <Route path={myroutes.statutsdossiers_edit} name="statutsdossiers_edit" element={<StatutsdossiersEdit />} />

                        {/** Assujetties */}
                        <Route path={myroutes.assujetties_index} name="assujetties_index" element={<AssujettiesIndex />} />
                        <Route path={myroutes.assujetties_create} name="assujetties_create" element={<AssujettiesCreate />} />
                        <Route path={myroutes.assujetties_edit} name="assujetties_edit" element={<AssujettiesEdit />} />

                        {/** Civilites */}
                        <Route path={myroutes.civilites_index} name="civilites_index" element={<CivilitesIndex />} />
                        <Route path={myroutes.civilites_create} name="civilites_create" element={<CivilitesCreate />} />
                        <Route path={myroutes.civilites_edit} name="civilites_edit" element={<CivilitesEdit />} />

                        {/** Poles*/}
                        <Route path={myroutes.poles_index} name="poles_index" element={<PolesIndex />} />
                        <Route path={myroutes.poles_create} name="poles_create" element={<PolesCreate />} />
                        <Route path={myroutes.poles_edit} name="poles_edit" element={<PolesEdit />} />

                        {/** Procedures*/}
                        <Route path={myroutes.procedures_index} name="procedures_index" element={<ProceduresIndex />} />
                        <Route path={myroutes.procedures_create} name="procedures_create" element={<ProceduresCreate />} />
                        <Route path={myroutes.procedures_edit} name="procedures_edit" element={<ProceduresEdit />} />

                        {/** typesprocedures*/}
                        <Route path={myroutes.typesprocedures_index} name="typesprocedures_index" element={<TypesproceduresIndex />} />
                        <Route path={myroutes.typesprocedures_create} name="typesprocedures_create" element={<TypesproceduresCreate />} />
                        <Route path={myroutes.typesprocedures_edit} name="typesprocedures_edit" element={<TypesproceduresEdit />} />

                        {/** chambres membres */}
                        <Route path={myroutes.chambresmembres_index} name="chambresmembres_index" element={<ChambresmembresIndex />} />
                        <Route path={myroutes.chambresmembres_create} name="chambresmembres_create" element={<ChambresmembresCreate />} />
                        <Route path={myroutes.chambresmembres_edit} name="chambresmembres_edit" element={<ChambresmembresEdit />} />

                        {/** calendriers globales */}
                        <Route path={myroutes.calendriersglobals_index} name="calendriersglobals_index" element={<CalendriersGlobalsIndex />} />
                        <Route path={myroutes.calendriersglobals_create} name="calendriersglobals_create" element={<CalendriersGlobalsCreate />} />
                        <Route path={myroutes.calendriersglobals_edit} name="calendriersglobals_edit" element={<CalendriersGlobalsEdit />} />
                        <Route path={myroutes.calendriersglobals_importxlsx} name="calendriersglobals_importxlsx" element={<CalendriersGlobalsImportxlsx />} />

                        {/** calendriers  */}
                        <Route path={myroutes.calendriers_index} name="calendriers_index" element={<CalendriersIndex />} />
                        <Route path={myroutes.calendriers_create} name="calendriers_create" element={<CalendriersCreate />} />
                        <Route path={myroutes.calendriers_edit} name="calendriers_edit" element={<CalendriersEdit />} />

                        {/** Motifs validations */}
                        <Route path={myroutes.motifsvalidations_index} name="motifsvalidations_index" element={<MotifsvalidationsIndex />} />
                        <Route path={myroutes.motifsvalidations_create} name="motifsvalidations_create" element={<MotifsvalidationsCreate />} />

                        {/** Types documents */}
                        <Route path={myroutes.typesdocuments_index} name="typesdocuments_index" element={<TypesdocumentsIndex />} />
                        <Route path={myroutes.typesdocuments_create} name="typesdocuments_create" element={<TypesdocumentsCreate />} />
                        <Route path={myroutes.typesdocuments_edit} name="typesdocuments_edit" element={<TypesdocumentsEdit />} />

                        {/** Types acteurs */}
                        <Route path={myroutes.typesacteurs_index} name="typesacteurs_index" element={<TypesacteursIndex />} />
                        <Route path={myroutes.typesacteurs_create} name="typesacteurs_create" element={<TypesacteursCreate />} />
                        <Route path={myroutes.typesacteurs_edit} name="typesacteurs_edit" element={<TypesacteursEdit />} />

                        {/** Tribunaux */}
                        <Route path={myroutes.tribunaux_index} name="tribunaux_index" element={<TribunauxIndex />} />

                        {/** HIstoriques des actions */}
                        <Route path={myroutes.historiqueactions_index} name="historiqueactions_index" element={<HistoriqueActionsIndex />} />

                    </>
                }


                {/** Dossiers */}
                {!profils.includes("AJ") && !profils.includes("USER") && <Route path={myroutes.dossiers_index} name="dossiers_index" element={<DossiersIndex />} />}


                {(profil_habilitation.includes("ADMIN-RW")) &&
                    <>
                        <Route path={myroutes.creer_dossier} name="creer_dossier" element={<CreerDossiers />} />
                    </>}

                {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("PDT") || profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("PC") || profils.includes("CCC")) && <Route path={`${myroutes.dossiers_show}/:ref`} name="dossiers_show" element={<DossiersShow />} />}


                {/** DossiersActeurs */}
                {profils.includes("AJ") &&
                    <>
                        <Route path={myroutes.dossiersacteurs_index} name="dossiersacteurs_index" element={<DossiersIndexActeur />} />
                        <Route path={`${myroutes.dossiersacteurs_show}/:ref`} name="dossiersacteurs_show" element={<DossiersShowActeurs />} />
                        <Route path={myroutes.dossiersacteurs_creer} name="dossiersacteurs_creer" element={<CreerDossiersActeurs />} />

                    </>}


                {/** Saisines */}
                {profil_habilitation.includes("INTERFACE-ADMIN") && !profils.includes("PDT") && <>
                    <Route path={myroutes.saisines_index} name="saisines_index" element={<SaisinesIndex />} />
                    {/** Datesaudiences */}
                    <Route path={myroutes.datesaudiences_index} name="datesaudiences_index" element={<DatesaudiencesIndex />} />
                    <Route path={myroutes.datesaudiences_create} name="datesaudiences_create" element={<DatesaudiencesCreate />} />
                    <Route path={myroutes.datesaudiences_edit} name="datesaudiences_edit" element={<DatesaudiencesEdit />} />

                    {/** Assujetties acteurs*/}
                    <Route path={myroutes.assujettiesacteurs_index} name="assujettiesacteurs_index" element={<AssujettiesIndexActeur />} />
                    <Route path={myroutes.assujettiesacteurs_create} name="assujettiesacteurs_create" element={<AssujettiesCreateActeurs />} />
                    <Route path={myroutes.assujettiesacteurs_edit} name="assujettiesacteurs_edit" element={<AssujettiesEditActeur />} />

                    {/** documents dossiers parties */}
                    <Route path={myroutes.dossierspartiesdocuments_index} name="dossierspartiesdocuments_index" element={<DossiersdocumentsIndex />} />
                    <Route path={myroutes.dossierspartiesdocuments_create} name="dossierspartiesdocuments_create" element={<DossiersdocumentsCreate />} />

                    {/** documents dossiers parties acteurs */}
                    <Route path={myroutes.dossierspartiesdocumentsacteurs_index} name="dossierspartiesdocumentsacteurs_index" element={<DossiersdocumentsIndexActeur />} />
                    <Route path={myroutes.dossierspartiesdocumentsacteurs_create} name="dossierspartiesdocumentsacteurs_create" element={<DossiersdocumentsCreateActeur />} />

                </>}

                {profils.includes("SUPERADMIN") && <Route path={myroutes.saisines_create} name="saisines_create" element={<SaisinesCreate />} />}


                {/** changes password */}
                {profils.includes("AJ") && <Route path={myroutes.change_passwordacteur} name="change_passwordacteur" element={<ChangePasswordActeur />} />}

                {/** rolesaudiences*/}
                {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) && <>
                    <Route path={myroutes.rolesaudiences_create} name="rolesaudiences_create" element={<RolesaudiencesCreate />} />
                    <Route path={myroutes.calendriersprocedures_create} name="calendriersprocedures_create" element={<CalendriersProceduresCreate />} />
                    <Route path={myroutes.calendriersprocedures_index} name="calendriersprocedures_index" element={<CalendriersProceduresIndex />} />
                </>}

                {/***demande de copie d'arrêt */}
                {!profils.includes("USER") && <Route path={myroutes.demande_arret_create} name="demande_arret_create" element={<DemandeArretsCreate />} />}
                {(profil_habilitation.includes("INTERFACE-ADMIN") && !profils.includes("CCC") && !profils.includes("PC") && !profils.includes("PDT")) && <Route path={myroutes.demande_arret_index} name="demande_arret_index" element={<DemandesArretsIndex />} />}

                {/** Paiements */}
                {(profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("PDT") || profils.includes("GEC")) && <Route path={myroutes.paiements_index} name="paiements_index" element={<PaiementsIndex />} />}



                {/** END Route BACK OFFICE */}
            </Routes>
            {/*</Suspense>*/}
        </BrowserRouter>
    )
}