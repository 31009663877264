import React, { useState } from "react";
import IntimeForm from "./IntimeForm";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setIntimes } from "../app/providers/authSlice";

const IntimeFormContainer = () => {
  const [users, setUsers] = useState([{ id: 1 }]);
  const dispatch = useDispatch();
  const [dataFormBloc, setDataFormBloc] = useState([]);

  const addUser = () => {
    const newUser = { id: users.length + 1 };
    setUsers([...users, newUser]);
  };

  const removeUser = (userId) => {
    setUsers(users.filter((user) => user.id !== userId));
    //Supprimer l'élément du stockage
    
  };

  const handleDataFromChild = (data) => {
    const newDataFormBloc = [...dataFormBloc]; // Copie du tableau d'état

    newDataFormBloc[data.position] = data.form;
    setDataFormBloc(newDataFormBloc); // Mise à jour de l'état avec le nouveau tableau
    //console.log(dataFormBloc)

    // Transmission des données au reducer
    dispatch(setIntimes(newDataFormBloc));

  }

  return (
    <div>
      {users.map((user, index) => (
        <div key={user.id}>
          <div className="row g-3 mb-0  align-items-center justify-content-between">
            <div className="card bg-warningx pt-1" style={{color: "#1f84d6",backgroundColor: "#1f84d621", border: "1px solid white",}}>
              <h6 className="pt-1">
                <i className="bi bi-caret-right-fill"></i> INTIME-E ({user.id})
              </h6>
            </div>
          </div>
          <IntimeForm index={index} sendData={handleDataFromChild} />
          {(index > 0) && ( 
            <Button className="mb-3 me-3" variant="danger" onClick={() => removeUser(user.id)}>Supprimer</Button>
        )}

          {index === users.length - 1 && ( // Afficher les boutons uniquement pour le dernier utilisateur
            <>
              <Button className="mb-3" type="submit" variant="success me-3" onClick={addUser}>
                <i className="bi bi-plus"></i> Ajouter plus
              </Button>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default IntimeFormContainer;
