import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutRequest } from "../../auth/logout";
import Swal from "sweetalert2";
import { checkAuthenticate, resetAuthData } from "../../../app/providers/authSlice";
import { myroutes } from "../../../routes/webroute";
import { afficherOneMessageError } from "../../../utils/helpers";
import AdministrationService from "../../../services/AdministrationService";

export default function SideBar() {
  const auth = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);
  const profils = useSelector((state) => state.auth.profil);
  const profil_habilitation = useSelector((state) => state.auth.profil_habilitation);

  //Check profil
  const checkProfil = () => {
    if (!profil_habilitation.includes("INTERFACE-ADMIN") && profil_habilitation.includes("ADMIN-RW") && profil_habilitation.includes("ADMIN-RO")) {
      logoutRequest(auth.token);
      dispatch(resetAuthData());
      navigate(myroutes.login);
      afficherOneMessageError("Vous n'êtes pas autorisé à accéder  à cette page");
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // se déconnecter
  const handleLogout = () => {
    Swal.fire({
      icon: "question",
      text: "Voulez-vous vraiment vous déconnecter ? Cela terminera votre session actuelle.",
      showCancelButton: true,
      cancelButtonText: "Annuler",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutRequest(auth.token);
        dispatch(resetAuthData());
        navigate(myroutes.login);
        Swal.fire({
          icon: "success",
          text: "Vous vous êtes déconnecté avec succès",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          background: "green",
          color: "white",
          iconColor: "white",
        });
      }
    });
  };

  const location = useLocation();
  const currentPath = location.pathname;

  // check if user is authenticate
  dispatch(checkAuthenticate())
  // Vérifier si le token existe et n'est pas expiré 
  if (!auth.isAuthenticate) {
    //deconnecter l'utilisateur et renvoi sur login page 
    dispatch(resetAuthData())
    navigate(myroutes.login)
  }


  //Clear cache
  const handleClearCache = async () => {
    await AdministrationService.clear_cache(token).then(response => {
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.resultat
        });
      }
    }).catch(error => {
      Swal.fire({
        icon: "error",
        text: error.response.data.error
      });
      console.log(error)
    })
  }


   //Active storage
   const handleActiveStorage = async () => {
    await AdministrationService.active_storage(token).then(response => {
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.resultat
        });
      }
    }).catch(error => {
      Swal.fire({
        icon: "error",
        text: error.response.data.error
      });
      console.log(error)
    })
  }

  useEffect(() => {
    checkProfil();
  }, []);


  return (
    <React.Fragment>
      {/* ======= Sidebar ======= */}
      <aside id="sidebar" className="sidebar" style={{ textTransform: "" }}>

        <p className="fw-bold text-warning"
          style={{
            fontWeight: "lighter",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          Plateforme d'Enrôlement et de Gestion des Procédures
        </p>
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              className={`nav-link ${currentPath == myroutes.dashboard ? "active" : ""
                }`}
              to={myroutes.dashboard}
            >
              <i className="bi bi-grid"></i>
              <span>Accueil</span>
            </Link>
          </li>
          {/* End Dashboard Nav */}
          {!profils.includes("PDT") &&
            <>
              <li className="nav-item">
                <Link
                  className="nav-link collapsed"
                  data-bs-target="#dossiers-nav"
                  data-bs-toggle="collapse"
                >
                  <i className="bi bi-journal-text"></i>
                  <span>Dossiers</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </Link>
                <ul
                  id="dossiers-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  {/**  <li>
                  <Link to={myroutes.creer_dossier}>
                    <i className="bi bi-circle"></i>
                    <span>Créer un dossier</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.creer_dossier}>
                    <i className="bi bi-circle"></i>
                    <span>Enrôler un dossier</span>
                  </Link>
                </li>*/}
                  {/***Menu ADMIN et SUPERADMIN dossiersacteurs_index*/}
                  {(profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("PC") || profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("CCC")) &&
                    <>
                      <li>
                        <Link to={myroutes.dossiers_index} className={`${currentPath == myroutes.dossiers_index && "active"}`}>
                          <i className="bi bi-circle"></i>
                          <span>Liste des dossiers</span>
                        </Link>
                      </li>

                      <li>
                        <Link to={myroutes.dossierspartiesdocuments_index} className={`${currentPath == myroutes.dossierspartiesdocuments_index && "active"}`}>
                          <i className="bi bi-circle"></i>
                          <span>Liste des pièces</span>
                        </Link>
                      </li>
                    </>
                  }
                  {/* 
                
                <li>
                  <Link to={myroutes.dossierspartiesdocuments_create}>
                    <i className="bi bi-circle"></i>
                    <span>Ajouter un document</span>
                  </Link>
                </li>
                
                */}
                </ul>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#datesaudiences-nav"
                  data-bs-toggle="collapse"
                  href="#"
                >
                  <i className="bi bi-layout-text-window-reverse"></i>
                  <span>Audiences</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul
                  id="datesaudiences-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >
                  <li>
                    <Link to={myroutes.datesaudiences_index} className={`${currentPath == myroutes.datesaudiences_index && "active"}`}>
                      <i className="bi bi-circle"></i>
                      <span>Dossiers aux rôles</span>
                    </Link>
                  </li>
                  {/***
                   * (profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("GEC")) &&
                    <>
                      <li>
                        <Link to={myroutes.datesaudiences_create} className={`${currentPath == myroutes.datesaudiences_create && "active"}`}>
                          <i className="bi bi-circle"></i>
                          <span>Editer un dossier</span>
                        </Link>
                      </li>
                    </>
                   */
                  }
                  <li>
                    <Link to={myroutes.rolesaudiences_index} className={`${currentPath == myroutes.rolesaudiences_index && "active"}`}>
                      <i className="bi bi-circle"></i>
                      <span>Rôles d'audiences</span>
                    </Link>
                  </li>
                  {(profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH")) &&
                    <>
                      <li>
                        <Link to={myroutes.calendriersprocedures_index} className={`${currentPath == myroutes.calendriersprocedures_index && "active"}`}>
                          <i className="bi bi-circle"></i>
                          <span>Calendriers des procédures</span>
                        </Link>
                      </li>
                    </>}
                </ul>
              </li>


              {/* End Forms Nav */}
              <li className="nav-item">
                <Link
                  to={myroutes.saisines_index}
                  className={`nav-link collapsed ${currentPath == myroutes.saisines_index ? "active" : ""}`}
                >
                  <i className="fas fa-balance-scale"></i>&nbsp;&nbsp;
                  <span>Saisines</span>
                </Link>
              </li>


              {(profil_habilitation.includes("ADMIN-RW") || profil_habilitation.includes("ADMIN-RO")) &&
                <>
                  <li className="nav-heading"></li>
                  <li className="nav-item">
                    <Link
                      to={myroutes.assujetties_index}
                      className={`nav-link collapsed ${currentPath == myroutes.assujetties_index ? "active" : ""}`}
                    >
                      <i className="bi bi-person-standing"></i>&nbsp;&nbsp;
                      <span>Assujetties</span>
                    </Link>
                  </li>
                </>
              }

              {(!profils.includes("CCC") && !profils.includes("PC") && !profils.includes("PDT")) &&
                <>
                  <li className="nav-heading"></li>
                  <li className="nav-item">
                    <Link
                      to={myroutes.demande_arret_index}
                      className={`nav-link collapsed ${currentPath == myroutes.demande_arret_index ? "active" : ""}`}
                    >
                      <i className="bi bi-envelope-paper"></i>&nbsp;&nbsp;
                      <span>Demandes de copie ou grosse </span>
                    </Link>
                  </li>
                </>}

              {(profils.includes("ADMIN") || profils.includes("SUPERADMIN") || profils.includes("PDT") || profils.includes("GEC")) &&
                <>
                  <li className="nav-heading"></li>
                  <li className="nav-item">
                    <Link
                      to={myroutes.paiements_index}
                      className={`nav-link collapsed ${currentPath == myroutes.paiements_index ? "active" : ""}`}
                    >
                      <i className="bi bi-wallet"></i>&nbsp;&nbsp;
                      <span>Liste des paiements</span>
                    </Link>
                  </li>
                </>}


              <li className="nav-heading"></li>
              {(profil_habilitation.includes("ADMIN-RW") || profil_habilitation.includes("ADMIN-RO")) &&
                <li className="nav-item">
                  <Link
                    to={myroutes.utilisateurs_index}
                    className={`nav-link collapsed ${currentPath == myroutes.utilisateurs_index ? "active" : ""}`}
                  >
                    <i className="bi bi-person"></i>&nbsp;&nbsp;
                    <span>Utilisateurs</span>
                  </Link>
                </li>}

            </>}

          <li className="nav-heading"></li>
          {/*  */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#outils-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-speedometer2"></i>
              <span>Outils</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="outils-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <Link to={myroutes.tableaudebordstats} className={`${currentPath == myroutes.tableaudebordstats && "active"}`}>
                  <i className="bi bi-circle"></i>
                  <span>Tableau de board</span>
                </Link>
              </li>
              <li>
                <Link to={myroutes.statistiques} className={`${currentPath == myroutes.statistiques && "active"}`}>
                  <i className="bi bi-circle"></i>
                  <span>Statistiques</span>
                </Link>
              </li>
            </ul>
          </li>

          {/*  */}

          {/* End Tables Nav */}
          {/* Menus Paramètres */}
          {(profil_habilitation.includes("ADMIN-RW") || profil_habilitation.includes("ADMIN-RO")) &&
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                data-bs-target="#parametres-nav"
                data-bs-toggle="collapse"
                href="#"
              >
                <i className="bi bi-gem"></i>
                <span>Paramètres</span>
                <i className="bi bi-chevron-down ms-auto"></i>
              </a>
              <ul
                id="parametres-nav"
                className="nav-content collapse "
                data-bs-parent="#sidebar-nav"
              >
                <li>
                  <Link to={myroutes.voirtout} className={`${currentPath == myroutes.voirtout && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Voir tout</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.calendriers_index} className={`${currentPath == myroutes.calendriers_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Calendrier</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.calendriersglobals_index} className={`${currentPath == myroutes.calendriersglobals_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Calendrier Global</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.categories_index} className={`${currentPath == myroutes.categories_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Categories</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.chambres_index} className={`${currentPath == myroutes.chambres_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Chambres</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.chambresmembres_index} className={`${currentPath == myroutes.chambresmembres_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Chambres membres</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.civilites_index} className={`${currentPath == myroutes.civilites_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Civilites</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.habilitations_index} className={`${currentPath == myroutes.habilitations_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Habilitations</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.historiqueactions_index} className={`${currentPath == myroutes.historiqueactions_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Historique des actions</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.motifsvalidations_index} className={`${currentPath == myroutes.motifsvalidations_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Motifs validations</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.objets_index} className={`${currentPath == myroutes.objets_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Objets</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.parties_index} className={`${currentPath == myroutes.parties_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Parties</span>
                  </Link>
                </li>
                {/**
                *  <li>
                  <Link to={myroutes.partiesmembres_index} className={`${currentPath == myroutes.partiesmembres_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Parties membres</span>
                  </Link>
                    </li>
                    <li>
                      <Link to={myroutes.partiesprofils_index} className={`${currentPath == myroutes.partiesprofils_index && "active"}`}>
                        <i className="bi bi-circle"></i>
                        <span>Parties profils</span>
                      </Link>
                    </li>
                */}
                <li>
                  <Link to={myroutes.poles_index} className={`${currentPath == myroutes.poles_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Pôles</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.profils_index} className={`${currentPath == myroutes.profils_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Profils</span>
                  </Link>
                </li>
                <li>
                  <Link to={myroutes.procedures_index} className={`${currentPath == myroutes.procedures_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Procédures</span>
                  </Link>
                </li>
                {/**
                 * <li>
                  <Link to={myroutes.rolesmembres_index} className={`${currentPath == myroutes.rolesmembres_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Rôles membres</span>
                  </Link>
                  </li>
                 */}
                <li>
                  <Link to={myroutes.statuts_index} className={`${currentPath == myroutes.statuts_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Statuts</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.statutsdossiers_index} className={`${currentPath == myroutes.statutsdossiers_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Statuts dossiers</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.statutsjuridiques_index} className={`${currentPath == myroutes.statutsjuridiques_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Statuts juridiques</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.tribunaux_index} className={`${currentPath == myroutes.tribunaux_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Tribunaux</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.typesacteurs_index} className={`${currentPath == myroutes.typesacteurs_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Types d'acteurs</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.typesdocuments_index} className={`${currentPath == myroutes.typesdocuments_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Types documents</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.typesparties_index} className={`${currentPath == myroutes.typesparties_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Types parties</span>
                  </Link>
                </li>

                <li>
                  <Link to={myroutes.typesprocedures_index} className={`${currentPath == myroutes.typesprocedures_index && "active"}`}>
                    <i className="bi bi-circle"></i>
                    <span>Types procédures</span>
                  </Link>
                </li>

              </ul>
            </li>}


          {/* End Menus paramètres*/}

          {/* Start Administration Page Nav */}
          {(profil_habilitation.includes("ADMIN-RW") || profil_habilitation.includes("ADMIN-RO")) &&
            <>
              <li className="nav-heading"></li>
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  data-bs-target="#administration-nav"
                  data-bs-toggle="collapse"
                  href="#"
                >
                  <i className="bi bi-kanban"></i>
                  <span>Administration</span>
                  <i className="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul
                  id="administration-nav"
                  className="nav-content collapse "
                  data-bs-parent="#sidebar-nav"
                >

                  <li>
                    <a href="#" onClick={() => handleActiveStorage()} className={`${currentPath == myroutes.active_storage && "active"}`}>
                      <i className="bi bi-circle"></i>
                      <span>Active storage</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => handleClearCache()} className={`${currentPath == myroutes.clear_cache && "active"}`}>
                      <i className="bi bi-circle"></i>
                      <span>Clear cache</span>
                    </a>
                  </li>
                </ul>
              </li>
            </>
          }

          {/* End Administration Page Nav */}

          <li className="nav-item">
            <Link
              to={myroutes.change_password}
              className={`nav-link collapsed ${currentPath == myroutes.change_password ? "active" : ""}`}
            >
              <i className="bi bi-p-square"></i>
              <span>Changer mot de passe</span>
            </Link>
          </li>

          <li>
            <a
              onClick={() => handleLogout()}
              className="nav-link collapsed"
              href="#"
            >
              <i className="bi bi-box-arrow-right"></i>
              <span>Se déconnecter</span>
            </a>
          </li>
          {/* End Login Page Nav */}

          {/* End Error 404 Page Nav */}

          {/* End Blank Page Nav */}
        </ul>
      </aside>
      {/* End Sidebar*/}
    </React.Fragment>
  );
}
