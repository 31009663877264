import http from "./http";

class TypespartiesService {
    index(token){
        return http.get("/api/v01/web/typesparties/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    store(data, token){
        return http.post("/api/v01/web/typesparties/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    }

    delete(ref,token){
        return http.delete(`/api/v01/web/typesparties/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }
}

export default new TypespartiesService()