import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/styles.css";
import { myroutes } from "../routes/webroute";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import ModesPaiementsService from "../services/ModesPaiementsService";
import DemandeArretsService from "../services/DemandeArretsService";
import Swal from "sweetalert2";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener, } from "kkiapay";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { RadioButton } from 'primereact/radiobutton';
import { getPaiementKey } from "../utils/helpers";
//import { MobileOnlyView, isBrowser, isTablet } from "react-device-detect";
import FraisPaiementsService from "../services/FraisPaiementsService";
import useMediaQuery from '@mui/material/useMediaQuery';


export default function DemandeArrets() {
    ///############### METHODES #############################//

    const token = useSelector((state) => state.auth.token);
    const [email, setEmail] = useState([]);
    const [telephone, setTelephone] = useState("");
    const [nom, setNom] = useState("");
    const [prenoms, setPrenoms] = useState("");
    const [num_arret, setNumArret] = useState("");
    const [nature_affaire, setNatureAffaire] = useState("");
    const [reference_dossier, setReferenceDossier] = useState("");
    const [date_arret, setDateArret] = useState("");
    const [telephoneError, setTelephoneError] = useState("");
    const navigate = useNavigate();
    const [showModePaiement, setshowModePaiement] = useState(false);
    const [modespaiements, setModesPaiements] = useState([]);
    const [mode_paiement_id, setModePaiement_Id] = useState("");
    const [montant_payer, setMontantPayer] = useState('');
    const [loading, setLoading] = useState(false);
    const [cabinet, setCabinet] = useState("");
    const [frais_paiements, setFraisPaiements] = useState([]);
    const [frais_paiement_id, setFraisPaiementId] = useState("");

    const isMobile = useMediaQuery('(max-width:600px)');
    const isBrowser = useMediaQuery('(min-width:600px)');

    //get liste mode de paiement
    const fetchModepaiements = async () => {
        await ModesPaiementsService.indexFrontoffice(token)
            .then((response) => {
                //Récupérer uniquement les modes de paiements actif
                setModesPaiements(response.data.data.filter((mode) => mode.statut_id === 1))
                //Recupérer par defaut le mode de paiement kkiapay
                setModePaiement_Id(response.data.data.filter((mode) => mode.code === "KKIAPAY")[0].code)
                //console.log(mode_paiement_id)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get liste frais paiement
    const fetchFraisPaiements = async () => {
        await FraisPaiementsService.indexFrontoffice(token)
            .then((response) => {
                //Récupérer uniquement les actif
                setFraisPaiements(response.data.data.filter((mode) => (mode.statut_id === 1 && mode.libelle !== "Dossier")))
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleChange = (value, country) => {
        setTelephone(value);
        setTelephoneError("");
    };

    const handleSetModePaiement = (e) => {
        setModePaiement_Id(e.target.value)
    }

    const handlesetFraisPaiements = (event) => {
        setFraisPaiementId(event.target.value)
        //Récupérer le montant à payer
        const selectedOption = event.target.options[event.target.selectedIndex];
        const price = selectedOption.getAttribute('price');

        setMontantPayer(price)
        //console.log(price)
    }



    //Get kkiapay reponse after paiement success
    const successHandler = async (response) => {
        //console.log(response)
        setLoading(true); // Début du chargement

        //Recuperation du code de la transaction de kkiapay
        var code_transaction = response.transactionId
        // Enregistrer le paiement
        let paiementsInfo = {
            montant: response.data.montant_payer, reference_dossier: response.data.reference_dossier,
            telephone: response.data.telephone, modepaiement_id: response.data.mode_paiement_id, demande_id: response.data.demande_id
        }
        DemandeArretsService.storePaiement(code_transaction, paiementsInfo, token).then((res) => {
            if (res.status === 200) {
                //setLoading(false);
                Swal.fire({
                    icon: 'success',
                    text: 'Paiement effectué avec succès !',
                    //text: res.data.data.transaction.message,
                });
                // Actualiser la page
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else if (res.data.data.echec) {
                setLoading(false); // Fin du chargement en cas d'erreur de validation

                Swal.fire({
                    icon: 'warning',
                    title: 'Une erreur s\'est produite !',
                    text: res.data.data.echec,
                });
            } else if (res.data.erreur) {
                setLoading(false); // Fin du chargement en cas d'erreur de validation

                Swal.fire({
                    icon: 'error',
                    title: 'Erreur!',
                    text: res.data.message,
                });
            }
        }).catch(error => {
            setLoading(false); // Fin du chargement en cas d'erreur de validation
            console.log("Error", error);
        });

    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        //setshowModePaiement(true)
        setLoading(true); // Début du chargement

        try {
            // Validation du numéro de téléphone
            if (!/^\+?\d*$/.test(telephone)) {
                setTelephoneError("Le numéro de téléphone n'est pas valide.");
                setLoading(false); // Fin du chargement en cas d'erreur de validation
                return;
            }

            if (telephone === "") {
                setTelephoneError("Veuillez saisir votre téléphone");
                setLoading(false); // Fin du chargement en cas d'erreur de validation
                return;
            }

            if (montant_payer == "") {
                setTelephoneError("Veuillez sélectionner le type de la demande");
                setLoading(false); // Fin du chargement en cas d'erreur de validation
                return;
            }

            if (date_arret == "") {
                setTelephoneError("Veuillez sélectionner la date de la demande");
                setLoading(false); // Fin du chargement en cas d'erreur de validation
                return;
            }

            const formData = new FormData();

            // Ajoutez les champs communs
            formData.append("email", email);
            formData.append("telephone", telephone);
            formData.append("nom", nom);
            formData.append("prenoms", prenoms);
            formData.append("num_arret", num_arret);
            formData.append("nature_affaire", nature_affaire);
            formData.append("date", date_arret);
            formData.append("reference_dossier", reference_dossier);
            formData.append("cabinet_avocat", cabinet);
            formData.append("frais_paiement_id", frais_paiement_id);

            const response = await DemandeArretsService.store(formData, token);
            if (response.status === 200) {
                //Lancer la fenetre de paiement
                if (showModePaiement == true && mode_paiement_id == '') {
                    return false;
                }
                //Afficher le hook de paiement
                //Cle kiosque digital
                var key = getPaiementKey();

                var fullname = nom + ' ' + prenoms;
                //Recupérer l'id de la demande
                var demande_id = response.data.data.id;

                openKkiapayWidget({
                    amount: montant_payer,
                    position: "center",
                    data: { reference_dossier, mode_paiement_id, montant_payer, telephone, demande_id },
                    name: fullname,
                    theme: "green",
                    api_key: key,
                    sandbox: true,
                    email: email,
                    phone: parseInt(telephone),
                });



            } else {
                Swal.fire({
                    icon: "error",
                    text: response.data.error,
                });
            }

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: error.response.data.message
            });
            console.error(error);
        } finally {
            setLoading(false); // Fin du chargement après le traitement
        }
    };

    const handleBack = () => {
        navigate(myroutes.login);
    }


    useEffect(() => {
        fetchModepaiements();
        fetchFraisPaiements();
        addKkiapayListener('success', successHandler)
        return () => {
            removeKkiapayListener('success', successHandler)
        };
    }, []);

    ///############### END METHODES #############################//

    return (
        <React.Fragment>
            <main
                style={{
                    backgroundImage: "url('../NiceAdmin/assets/img/log3.webp')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <section className="header-login mb-3">
                    <div className="container p-1 pt-3">
                        <div className="row align-items">
                            {/**Affichage pour ordinateur et tablet */}
                            {(isBrowser) && <>
                                <div className="col-md-2">
                                    <Link to={myroutes.accueil}>
                                        <img
                                            style={{
                                                height: "100px",
                                                width: "120px",
                                                paddingLeft: "20px",
                                            }}
                                            src="/NiceAdmin/assets/img/logo-blanc.png"
                                            alt="logo-blanc"
                                        />
                                    </Link>
                                </div>

                                <div className="col-md-1"></div>
                                <div className="col-md-6">
                                    <h3
                                        className="fw-bold"
                                        style={{
                                            fontSize: "24px",
                                            textAlign: "center",
                                        }}
                                    >
                                        COUR D'APPEL DE COMMERCE - COTONOU
                                    </h3>
                                    <hr />
                                    <p
                                        className="fw-bold text-warning"
                                        style={{
                                            fontWeight: "lighter",
                                            fontSize: "21px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Plateforme d'Enrôlement et de Gestion des Procédures
                                    </p>
                                </div>
                                <div className="col-md-1"></div>
                                <div className="col-md-2">
                                    <img
                                        style={{
                                            height: "70px",
                                            width: "230px",
                                            paddingRight: "50px",
                                            paddingTop: "20px",
                                        }}
                                        src="/NiceAdmin/assets/img/ministere.png"
                                        alt="Ministère"
                                    />
                                </div>
                            </>}
                            {/**Affichage pour mobile uniquement */}
                            {isMobile && <>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-5">
                                            <Link to={myroutes.accueil}>
                                                <img
                                                    style={{
                                                        height: "100px",
                                                        width: "120px",
                                                    }}
                                                    src="/NiceAdmin/assets/img/logo-blanc.png"
                                                    alt="logo-blanc"
                                                />
                                            </Link>
                                        </div>
                                        <div className="col-7 pt-4">
                                            <Link to={myroutes.accueil}>
                                                <img
                                                    style={{
                                                        height: "60px",
                                                        width: "200px",
                                                    }}
                                                    src="/NiceAdmin/assets/img/ministere.png"
                                                    alt="Ministère"
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <h3
                                            className="fw-bold"
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            COUR D'APPEL DE COMMERCE - COTONOU
                                        </h3>

                                        <hr />
                                        <p
                                            className="fw-bold text-warning"
                                            style={{
                                                fontWeight: "lighter",
                                                fontSize: "21px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Plateforme d'Enrôlement et de Gestion des Procédures
                                        </p>
                                    </div>
                                </div>
                            </>}

                        </div>
                    </div>
                </section>
                <br />
                <section className="mt-3">
                    <div className="container mt-2">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-sm-12 align-items-center justify-content-center">
                                <div className="container mt-0"></div>
                                <div className="card mb-3 mt-3 p-3">
                                    <div className="card-body">
                                        <div className="pt-3 pb-2">
                                            <div className="pt-0 pb-2 text-center mb-3">
                                                <h5
                                                    className="card-titlexx text-center pb-0 fs-4"
                                                    style={{ color: "#3B7D82" }}
                                                >
                                                    Formulaire de demande de copie ou grosse
                                                </h5>
                                            </div>

                                        </div>

                                        <Form
                                            className="settings-form"
                                            style={{ textAlign: "left" }}
                                            onSubmit={handleSubmit}
                                        >
                                            <Row>
                                                <Col md={12} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">
                                                            Cabinet / SCPA <i className="text-danger"></i>
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setCabinet(e.target.value)}
                                                            value={cabinet}
                                                            placeholder=""
                                                            name="cabinet"
                                                            aria-label="cabinet"
                                                            maxLength={255}
                                                            minLength={2}
                                                            type="text"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">
                                                            Nom <i className="text-danger">*</i>
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setNom(e.target.value)}
                                                            value={nom}
                                                            placeholder=""
                                                            name="nom"
                                                            aria-label="nom"
                                                            maxLength={100}
                                                            minLength={2}
                                                            type="text"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">
                                                            Prénom (s) <i className="text-danger">*</i>
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setPrenoms(e.target.value)}
                                                            value={prenoms}
                                                            placeholder=""
                                                            name="prenoms"
                                                            aria-label="prenoms"
                                                            maxLength={100}
                                                            minLength={3}
                                                            type="text"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">
                                                            E-mail <i className="text-danger">*</i>
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            value={email}
                                                            placeholder=""
                                                            name="email"
                                                            aria-label="email"
                                                            maxLength={100}
                                                            minLength={3}
                                                            type="email"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">
                                                            Téléphone <i className="text-danger">*</i>{"  "}<i className="text-info bi bi-question-circle-fill" title="Veuillez choisir le drapeau et compléter votre numéro"></i>
                                                        </Form.Label>
                                                        <div
                                                        ><PhoneInput
                                                                className="border  border rounded-2" style={{ height: "38px", backgroundColor: "#abbcd556" }}
                                                                defaultCountry="BJ"
                                                                value={telephone}
                                                                onChange={handleChange}
                                                                inputclass={`form-input`}
                                                                placeholder="60 00 00 00"
                                                                required
                                                            />

                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Numéro de l'arrêt <i className="text-danger">*</i></Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setNumArret(e.target.value)}
                                                            value={num_arret}
                                                            placeholder=""
                                                            name="num_arret"
                                                            aria-label="num_arret"
                                                            maxLength={100}
                                                            minLength={3}
                                                            required
                                                            type="text"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Date de l'arrêt <i className="text-danger">*</i></Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setDateArret(e.target.value)}
                                                            value={date_arret}
                                                            placeholder=""
                                                            name="date_arret"
                                                            aria-label="date_arret"
                                                            required
                                                            type="date"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Numéro du dossier <i className="text-danger">*</i></Form.Label>
                                                        <Form.Control
                                                            className="border border rounded-2"
                                                            onChange={(e) => setReferenceDossier(e.target.value)}
                                                            value={reference_dossier}
                                                            placeholder=""
                                                            name="reference_dossier"
                                                            aria-label="reference_dossier"
                                                            maxLength={100}
                                                            minLength={3}
                                                            type="text"
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">Type de demande <i className="text-danger">*</i></Form.Label>
                                                        <select name="type_demande" id="type_demande" className="form-control form-select"
                                                            onChange={handlesetFraisPaiements}>
                                                            <option value="" selected disabled>-- Sélectionner le type de demande --</option>
                                                            {frais_paiements.map((item) => (
                                                                <option value={item.id} price={item.montant}>{item.description}</option>
                                                            ))}
                                                        </select>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12} sm={12} className="form-group">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="fw-bold">
                                                            Nature de l'affaire<i className="text-danger">*</i>
                                                        </Form.Label>
                                                        <textarea value={nature_affaire} name="nature_affaire" id="" cols="30" onChange={(e) => setNatureAffaire(e.target.value)} rows="3" className="form-control summernote border border rounded-2"
                                                            placeholder="Contentieux de paiement..."
                                                            maxLength={100}
                                                            minLength={3}></textarea>

                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <div className="mb-3">
                                                <strong className="mb-4 me-3">Mode(s) de paiement <i className="text-danger">*</i></strong>
                                            </div>
                                            <div className="row mb-4 pt-2 p-fluid">
                                                {modespaiements.length > 0 && modespaiements.map((item, index) => (
                                                    <div className="col-md-4" key={index}>
                                                        <div className="p-field">
                                                            <RadioButton className="me-2" inputId={`option-${index}`} required value={item.code} onChange={handleSetModePaiement} checked />
                                                            <label htmlFor={`option-${index}`} className="fw-bold">{item.libelle}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="col">
                                                    <img src="/modepaiement.png" alt="modepaiement" className="img-fluid  me-2 p-1" style={{ height: "auto", width: "130px", float: "right" }} />
                                                </div>

                                            </div>

                                            {/**<div className="row align-items-center justify-content-between pb-4">
                                                <div className="col-md-12 text-center">
                                                    <img src="/modepaiement.png" alt="modepaiement" className="img-fluid" style={{ height: "80px" }} />
                                                    {/**<img src="/mtnmomo.jpg" alt="modepaiement" className="img-fluid" style={{ height: "70px" }} />
                                            
                                                <img src="/celtis.png" alt="modepaiement" className="img-fluid" style={{ height: "70px" }} />
                                             */}
                                            {/** </div>
                                            </div> */}

                                            <div className="row">
                                                <div className="col-md-4 mt-2">
                                                    <Button type="submit" variant="warning" onClick={handleSubmit} disabled={loading}>Enregistrer et payer</Button>
                                                </div>
                                                <div className="col-md-4 mt-2">
                                                    <Button variant="dark" onClick={handleBack} disabled={loading}>Annuler</Button>
                                                </div>
                                            </div>
                                        </Form>

                                        {/**
                                         * <Modal show={loading} backdrop="static" keyboard={false} centered>
                                            <Modal.Body className="text-center">
                                                <ProgressSpinner />
                                                <p className="my-3 fw-bold" style={{ fontSize: '18px' }}>Veuillez patienter pendant l'enregistrement de vos données...</p>
                                            </Modal.Body>
                                        </Modal>
                                         */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </section>
                <section className="footer-login">
                    <div className="col-md-12 d-flex w-100 ">
                        <div
                            className="col-md-4"
                            style={{ height: "8px", backgroundColor: "#008559" }}
                        ></div>
                        <div
                            className="col-md-4"
                            style={{ height: "8px", backgroundColor: "#FFD400" }}
                        ></div>
                        <div
                            className="col-md-4"
                            style={{ height: "8px", backgroundColor: "#E43009" }}
                        ></div>
                    </div>
                    <div className="container text-center py-2">
                        <small className="copyright">
                            &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE -
                            COTONOU | Tous droits réservés.
                            {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
                        </small>
                    </div>
                </section>
            </main>
        </React.Fragment>
    );
}
