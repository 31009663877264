import http from "./http";

class RolesaudiencesService {
    index(token) {
        return http.get("/api/v01/web/rolesaudiences/index", { headers: { 'Authorization': 'Bearer ' + token } })
    }
    store(data, token) {
        return http.post("/api/v01/web/rolesaudiences/store", data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    delete(ref, token) {
        return http.delete(`/api/v01/web/rolesaudiences/${ref}/delete`, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    filter(data, token) {
        return http.post("/api/v01/web/rolesaudiences/filter", data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    validate(ref, data, token) {
        return http.put(`/api/v01/web/rolesaudiences/${ref}/update`, data, { headers: { 'Authorization': 'Bearer ' + token } })
    }

    get_stats(token) {
        return http.get("/api/v01/web/rolesaudiences/count_by_month", { headers: { 'Authorization': 'Bearer ' + token } })
    }
}

export default new RolesaudiencesService()