import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";
import { MultiSelect } from "primereact/multiselect";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AssujettiesService from "../services/AssujettiesService";
import CivilitesService from "../services/CivilitesService";
import { RadioButton } from 'primereact/radiobutton';
import { afficherMessagesSuccess, afficherOneMessageError } from "../utils/helpers";

const Step2 = ({

  handleSelectedAssujettiesChange,
  handleSelectedRepresenteParChange,
}) => {
  const token = useSelector((state) => state.auth.token);
  const [assujetties, setAssujetties] = useState([]);
  const [assujetties_acteurs, setAssujetties_acteurs] = useState([]);
  const [selectedOptionAssujetties, setSelectedOptionAssujetties] = useState([]);
  const [selectedOptionRepresentePar, setSelectedOptionRepresentePar] =
    useState([]);
  const [is_acteur, setIsActeur] = useState(0);
  const [is_avocat, setIsAvocat] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [civilites, setCivilites] = useState([]);
  const [civilite_id, setCivilite_id] = useState("");
  const [type_personne, setType_personne] = useState("PM");
  const [raison_sociale, setRaison_sociale] = useState("");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [rccm, setRccm] = useState("");
  const [ifu, setIfu] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const [type_partie_id, setType_partie_id] = useState(1);


  const handleOptionChange = (value) => {
    setIsActeur(value);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  


  const fetchAssujetties = async () => {
    await AssujettiesService.index(token)
      .then((response) => {
        setAssujetties(response.data.data);
        //  console.log("assujetties", response.data.data )
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataassujetties = assujetties.map((item) => {
    return {
      label:
        item.type_personne === "PP"
          ? `${item.nom} ${item.prenoms}`
          : item.raison_sociale,
      value: item.id,
    };
  });

  const fetchAssujetties_acteurs = async () => {
    await AssujettiesService.index_acteurs(token)
      .then((response) => {
        setAssujetties_acteurs(response.data.data);
        //console.log(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dataassujetties_acteurs = assujetties_acteurs.map((item) => {
    return {
      label:
        item.type_personne === "PP"
          ? `Me ${item.nom} ${item.prenoms}`
          : item.raison_sociale,
      value: item.id,
    };
  });

  // Générer les options pour le deuxième champ select en filtrant les options disponibles
  const filteredDataassujetties = selectedOptionAssujetties
    ? dataassujetties.filter(
      (option) => !selectedOptionAssujetties.includes(option.value)
    )
    : dataassujetties;

  //enregistrement sur le modal
  const navigate = useNavigate();

  const handleChange = (value, country) => {
    setTelephone(value);
    setTelephoneError("");
  };

  //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  // formater les données des types de chambres

  const datacivilites = civilites.map((item) => {
    return {
      label: item.libelle,
      value: item.id,
    };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation du numéro de téléphone
    if (!/^\+?\d*$/.test(telephone)) {
      setTelephoneError("Le numéro de téléphone n'est pas valide.");
      return;
    }

    if (telephone === "") {
      setTelephoneError("Veuillez saisir votre téléphone");
      return;
    }

    const formData = new FormData();

    // Ajoutez les champs communs
    formData.append("email", email);
    formData.append("telephone", telephone);
    formData.append("adresse", adresse);
    formData.append("rccm", rccm);
    formData.append("ifu", ifu);

    // Ajoutez le champ spécifique pour le type de personne
    formData.append("type_personne", type_personne);

    // Ajoutez les champs spécifiques en fonction du type de personne
    if (type_personne === "PM") {
      formData.append("raison_sociale", raison_sociale);
    } else {
      formData.append("raison_sociale", raison_sociale);
      formData.append("nom", nom);
      formData.append("prenoms", prenoms);
      formData.append("civilite_id", civilite_id);
    }
    formData.append("is_acteur", is_acteur);

    // Enregistrez le formulaire
    await AssujettiesService.store(formData, token)
      .then((response) => {
        if (response.data.status == true) {
          
          afficherMessagesSuccess("Votre enregistrement a été effectué avec succès.");

          // Réinitialisez les données ici
          setRaison_sociale("");
          setNom("");
          setPrenoms("");
          setEmail("");
          setTelephone("");
          setAdresse("");
          setRccm("");
          setIfu("");
          setType_personne("PM");

          // Récupérer les données mises à jour
          fetchAssujetties();
          fetchAssujetties_acteurs();
        } else {
          //Afficher le message d'erreur
          afficherOneMessageError(response.data.data)
        }
        
        handleCloseModal();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Vous avez mal renseigné les champs ou cet élément existe déjà dans la base de données",
        });
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAssujetties();
    fetchCivilites();
    fetchAssujetties_acteurs();
  }, []);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span
        className="font-bold white-space-nowrap p-2 m-3"
        style={{ backgroundColor: "aliceblue", width: "300px" }}
      >
        Enregistrement d'une partie
      </span>
    </div>
  );

  const footerContent = <div></div>;

  return (
    <div className="p-4 mb-2">
      <div className="mb-3">
        <h5
          className="fw-bold"
          style={{ color: "#51678f", backgroundColor: "" }}
        >
          Renseignements sur appelant-e-s <i className="bi bi-person-fill"></i>{" "}
          <hr />
        </h5>
      </div>
      <Form className="mx-3 settings-form" style={{ textAlign: "left" }}>

        <Row>
          <Col
            md={10}
            sm={10}
            className="formx-groupx"
            style={{ width: "850px" }}
          >
            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                Nom du/des demandeur/s <i className="text-danger">*</i>{" "}
              </Form.Label>
              <MultiSelect
                value={selectedOptionAssujetties}
                onChange={(e) => {
                  setSelectedOptionAssujetties(e.value);
                  handleSelectedAssujettiesChange(e.value);
                }}
                options={dataassujetties}
                optionLabel="label"
                placeholder="Sélectionner"
                maxSelectedLabels={10}
                className="w-full md:w-25rem"
                style={{ width: "100%", height: "40px" }}
                filter
                filterPlaceholder="Rechercher"
              />
            </Form.Group>
          </Col>
          <Col
            md={1}
            sm={1}
            className="formx-groupx"
            style={{ marginTop: "35px" }}
          >
            <Link
              className="btn btn-success btn-sm"
              onClick={handleShowModal}
              title="Ajouter"
            >
              <i className="bi bi-plus"></i>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col
            md={10}
            sm={10}
            className="formx-groupx"
            style={{ width: "850px" }}
          >
            <Form.Group className="mb-4">
              <Form.Label className="fw-bold">
                Représenté(e) par <i className="text-danger">*</i>{" "}
              </Form.Label>
              <MultiSelect
                value={selectedOptionRepresentePar}
                onChange={(e) => {
                  setSelectedOptionRepresentePar(e.value);
                  handleSelectedRepresenteParChange(e.value);
                }}
                options={dataassujetties_acteurs}
                optionLabel="label"
                placeholder="Sélectionner"
                maxSelectedLabels={10}
                className="w-full md:w-25rem"
                style={{ width: "100%", height: "40px" }}
                filter
                filterPlaceholder="Rechercher"
              />
            </Form.Group>
          </Col>
          <Col
            md={1}
            sm={1}
            className="formx-groupx"
            style={{ marginTop: "35px" }}
          >
            <Link
              className="btn btn-success btn-sm"
              onClick={handleShowModal}
              title="Ajouter"
            >
              <i className="bi bi-plus"></i>
            </Link>
          </Col>
        </Row>
      </Form>

      <Dialog
        header={headerElement}
        visible={showModal}
        style={{ width: "50rem" }}
        onHide={handleCloseModal}
        footer={footerContent}
      >
        <p className="m-0">
          <Row>
            {Object.keys(validationError).length > 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-danger">
                    <ul className="mb-0">{<li>{validationError}</li>}</ul>
                  </div>
                </div>
              </div>
            )}
          </Row>
          <Form
            className="mx-3 settings-form"
            style={{ textAlign: "left" }}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col md={12} sm={12} className="form-group">
                <Form.Group className="mb-3 d-flexx">
                  <Form.Label
                    className="fw-bold mb-3"
                    style={{
                      fontSize: "18px",
                      color: "tomato",
                      fontStyle: "italicx",
                    }}
                  >
                    Etes-vous une personne physique ?{" "}
                    <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    id="type_personneSwitch"
                    label={type_personne === "PP" ? "Oui" : "Non"}
                    onChange={() =>
                      setType_personne(type_personne === "PP" ? "PM" : "PP")
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                sm={12}
                className="form-group"
                style={{ display: type_personne === "PP" ? "none" : "block" }}
              >
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Raison sociale <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Control
                    className="border border rounded-2"
                    onChange={(e) => setRaison_sociale(e.target.value)}
                    value={raison_sociale}
                    placeholder=""
                    name="raison_sociale"
                    aria-label="raison_sociale"
                    maxLength={100}
                    minLength={3}
                    type="text"
                    required={type_personne === "PP" ? false : true}
                  // hidden={type_personne === "PP"}
                  />
                </Form.Group>
              </Col>
              {type_personne === "PP" && (
                <>
                  <Col md={2} sm={2} className="formx-groupx">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Civilité <i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Select
                        className=""
                        id="civilite_id"
                        name="civilite_id"
                        value={civilite_id}
                        // required
                        onChange={(e) => {
                          setCivilite_id(e.target.value);
                        }}
                      >
                        <option value="" selected>
                          --
                        </option>
                        {civilites.length > 0 &&
                          civilites.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.libelle}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={5} sm={5} className="form-group">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Nom <i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        className="border border rounded-2"
                        onChange={(e) => setNom(e.target.value)}
                        value={nom}
                        placeholder=""
                        name="nom"
                        aria-label="nom"
                        maxLength={100}
                        minLength={3}
                        type="text"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5} sm={5} className="form-group">
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">
                        Prénom (s) <i className="text-danger">*</i>
                      </Form.Label>
                      <Form.Control
                        className="border border rounded-2"
                        onChange={(e) => setPrenoms(e.target.value)}
                        value={prenoms}
                        placeholder=""
                        name="prenoms"
                        aria-label="prenoms"
                        maxLength={100}
                        minLength={3}
                        type="text"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} sm={12} className="form-group">
                    <Form.Group
                      className="mb-3"
                      style={{
                        displayx: type_personne === "PP" ? "none" : "block",
                      }}
                    >
                      <Form.Label className="fw-bold">
                        Raison sociale <i className="text-danger"></i>
                      </Form.Label>
                      <Form.Control
                        className="border border rounded-2"
                        onChange={(e) => setRaison_sociale(e.target.value)}
                        value={raison_sociale}
                        placeholder="Renseigner la raison sociale s'il y a lieu"
                        name="raison_sociale"
                        aria-label="raison_sociale"
                        maxLength={100}
                        minLength={3}
                        type="text"
                      // disabled={type_personne === "PP"}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}

              <Col md={6} sm={6} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">RCCM </Form.Label>
                  <Form.Control
                    className="border border rounded-2"
                    onChange={(e) => setRccm(e.target.value)}
                    value={rccm}
                    placeholder=""
                    name="rccm"
                    aria-label="rccm"
                    maxLength={100}
                    minLength={3}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={6} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">IFU </Form.Label>
                  <Form.Control
                    className="border border rounded-2"
                    onChange={(e) => setIfu(e.target.value)}
                    value={ifu}
                    placeholder=""
                    name="ifu"
                    aria-label="ifu"
                    maxLength={100}
                    minLength={3}
                    type="number"
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={6} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Email <i className="text-danger">*</i>
                  </Form.Label>
                  <Form.Control
                    className="border border rounded-2"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder=""
                    name="email"
                    aria-label="email"
                    maxLength={100}
                    minLength={3}
                    type="email"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6} sm={6} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Téléphone <i className="text-danger">*</i>{"  "}<i className="text-info bi bi-question-circle-fill" title="Veuillez choisir le drapeau et compléter votre numéro"></i>
                  </Form.Label>
                  <PhoneInput
                    defaultCountry="BJ"
                    value={telephone}
                    onChange={handleChange}
                    inputClass={`form-input`}
                    containerStyle={{
                      border: "1px solid #ccc",
                      borderRadius: "6px",
                    }}
                    inputStyle={{
                      width: "80%",
                      border: "none",
                      outline: "none",
                    }}
                    placeholder="61 00 00 00"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={12} sm={12} className="form-group">
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">
                    Adresse<i className="text-danger">*</i>
                  </Form.Label>
                  <textarea
                    value={adresse}
                    name="adresse"
                    id=""
                    cols="30"
                    onChange={(e) => setAdresse(e.target.value)}
                    rows="3"
                    className="form-control summernote border border rounded-2"
                    placeholder="Boîte postale, localisation, ville, ..."
                    maxLength={100}
                    minLength={3}
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>
            <div className="form-group flex justify-content-center mb-4 fw-bold">
              <span
                className="me-3 p-2 mb-3"
                style={{
                  color: "tomato",
                  backgroundColor: "",
                  fontSize: "18px",
                }}
              >
                Etes-vous un acteur de justice (Avocat ou Huissier) ?
              </span>
              <div className="p-field-checkbox">
                <div className="p-formgroup-inline d-flex mt-3">
                  <div className="p-field-radiobutton me-4">
                    <RadioButton
                      className="me-2"
                      inputId="oui"
                      name="oui"
                      value={1}
                      onChange={(e) => handleOptionChange(e.value)}
                      checked={is_acteur === 1}
                    />
                    <label htmlFor="oui">Oui</label>
                  </div>
                  {"  "}
                  <div className="p-field-radiobutton">
                    <RadioButton
                      className="me-2"
                      inputId="non"
                      name="non"
                      value={0}
                      onChange={(e) => handleOptionChange(e.value)}
                      checked={is_acteur === 0}
                    />
                    <label htmlFor="non">Non</label>
                  </div>
                </div>
              </div>
            </div>
            <Button type="submit" variant="warning">
              Enregistrer
            </Button>
          </Form>
        </p>
      </Dialog>
    </div>
  );
};

export default Step2;
