import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import StatutsService from "../../../../services/StatutsService";
import { useSelector } from "react-redux";
import CalendriersService from "../../../../services/CalendriersService";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import { afficherMessagesSuccess, convertDateToTexte, filterStatut } from "../../../../utils/helpers";
import CalendriersGlobalsService from "../../../../services/CalendriersGlobalsService";
import ChambresService from "../../../../services/ChambresService";

export default function CalendriersEdit() {
 
    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [chambre_id, setChambre_id] = useState('')
    const [chambres, setChambres] = useState('')
    const [calendrier_global_id, setCalendrierglobal_id] = useState('')
    const [calendriersglobals, setCalendriersGlobals] = useState([])
    const [statut_id, setStatut_id] = useState('')
    const [statuts, setStatuts] = useState([])
    const jour = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const [jour_value, setJourValue] = useState('')
    
  

    //get liste statuts
    const fetchStatuts = async () => {
        try {
            const response = await StatutsService.index(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {                
                setStatuts(filterStatut(responseData.data));
            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

    };

    //get liste chambres
    const fetchTypeschambres = async () => {
        await ChambresService.index(token)
            .then((response) => {
                setChambres(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get liste calendries globals
  const fetchCalendriersGlobals = async () => {
    try {
      const response = await CalendriersGlobalsService.index(token);
      const responseData = response.data;
  
      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setCalendriersGlobals(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }
  
  };


    ///Save form data
    const handleUpdate = async (event) => {
        event.preventDefault()
        let form = {
            statut_id, chambre_id, jour: jour_value, calendrier_global_id
        }

        await CalendriersService.update(location.state.ref, form, token).then((response) => {
            //redirection sur index
            navigate(myroutes.calendriers_index)
            afficherMessagesSuccess(response.data.message);

            setValidationError(response.data.message)

        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.response.data.message
            })
            //affichage des erreurs sur le formulaire
            setValidationError(error.response.data.message)
        });
    }

    const edit = async () => {
        try {
            const response = await CalendriersService.edit(location.state.ref, token);
            if (response.data) {
                const data = response.data.data;
                setChambre_id(data.chambre_id);
                setCalendrierglobal_id(data.calendrier_global_id);
                setStatut_id(data.statut_id);
                setJourValue(data.jour)
            } else {
                console.log("Data not found in API response");
                // Gérer le cas où response.data ou response.data.statut est undefined ou null
            }
        } catch (error) {
            console.error("API error", error);
            Swal.fire({
                icon: "error",
                text: error.message
            });
            setValidationError(error.erreur);
        }
    };





    useEffect(() => {
        fetchCalendriersGlobals();
        fetchStatuts();
        fetchTypeschambres();
        edit();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: true, libelle: 'Calendriers' }} routeName={myroutes.calendriers_index}></BreadcrumbComponent>


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Modification </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleUpdate}>
                                    <Row>
                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Chambre <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="chambre_id" name="chambre_id" value={chambre_id}
                                                    required
                                                    onChange={(e) => {
                                                        setChambre_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {chambres.length > 0 &&
                                                        chambres.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Jour <i className="text-danger">*</i>
                                                </Form.Label>
                                                {/**
                                                 * <Form.Select className="" id="jour" name="jour" value={jour_value}
                                                   onChange={(e) => setJourValue(e.target.value)} >
                                                   {jour.map((jour, index) => (
                                                       <option key={index} value={jour}>{jour}</option>
                                                       ))}
                                                    </Form.Select>
                                                 */}

                                               <Form.Control className="border  border rounded-2" onChange={(e) => setJourValue(e.target.value)}
                                                    value={jour_value}
                                                    placeholder=""
                                                    name="jour_value"
                                                    aria-label="jour_value"
                                                    maxLength={100}
                                                    minLength={3}
                                                    type="date"
                                                    required
                                                />
                                               
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Calendrier global <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="calendrier_global_id" name="calendrier_global_id" value={calendrier_global_id}
                                                     required 
                                                    onChange={(e) => {
                                                        setCalendrierglobal_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {calendriersglobals.length > 0 &&
                                                        calendriersglobals.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {convertDateToTexte(item.date_debut) + " au " + convertDateToTexte(item.date_fin)}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">
                                                    Statut <i className="text-danger">*</i>
                                                </Form.Label>
                                                <Form.Select className="" id="statut_id" name="statut_id" value={statut_id}
                                                    // required
                                                    onChange={(e) => {
                                                        setStatut_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        --
                                                    </option>
                                                    {statuts.length > 0 &&
                                                        statuts.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.calendriers_index)}>Annuler</Button>                            </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}