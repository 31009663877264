import React, { useCallback, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

const StepForm4 = ({ onFileChange }) => {

  const handleFileChange = (e) => {
    const file = e.target.files[0]; 
    const selectedFiles = Array.from(e.target.files);
  /*  console.log("--- info file ----")
    console.log(selectedFiles)
    console.log("--- end info file ----")*/
    onFileChange(selectedFiles); // Appel de la fonction fournie par le parent pour mettre à jour le chemin du fichier
  };

  

  return (
    <div className="p-4 mb-2">
      <div className="mb-2">
        <h5 className="fw-bold" style={{ color: "#51678f" }}>
          {" "}
          Transmission des fichiers
          <i className="bi bi-file-earmark-text-fill"></i> <hr />
        </h5>
      </div>
      <Col md={8} sm={8} className="form-group">
        <Form.Group className="mb-3">
          <Form.Label className="fw-bold mb-3">
            Joindre fichier(s) <i className="text-danger">*</i>{" "}
            (pdf,doc,docx,txt...)
          </Form.Label>
          <Form.Group className="mb-3">
          <Form.Control
            className="border border rounded-2"
            onChange={handleFileChange}
            name="path"
            aria-label="path"
            required
            type="file"
            accept="document/pdf"
            id="path"
            multiple
          />
        </Form.Group>
        </Form.Group>
      </Col>
    </div>
    
    
  );
};

export default StepForm4;

