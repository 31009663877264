import { Link, useNavigate } from "react-router-dom";
import Layout from "../include/layout";
import { myroutes } from "../../../routes/webroute";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import DatesaudiencesService from "../../../services/DatesaudiencesService";
import DossiersService from "../../../services/DossiersService";
import { Editor } from "primereact/editor";
import CalendriersService from "../../../services/CalendriersService";
import ChambresService from "../../../services/ChambresService";
import { convertDateToTexte, sortedDates } from '../../../utils/helpers';
import { Dropdown } from "primereact/dropdown";
import StatutsdossiersService from '../../../services/StatutsdossiersService';
import TypesDocumentsService from "../../../services/TypesDocumentsService";
import CalendriersProceduresService from "../../../services/CalendriersProceduresService";
import { InputText } from "primereact/inputtext";

export default function CalendriersProceduresCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)
    const [dossiers, setDossiers] = useState([])
    const [dossier_id, setDossier_id] = useState('')
    const [type_document_id, setTypedocument_id] = useState('')
    const [typesdocuments, setTypesdocuments] = useState('')
    const [description, setDescription] = useState('')
    const [date_echeance, setDateEcheance] = useState('')
    const [loading, setLoading] = useState(false)

    //get liste type document
    const fetchTypesdocuments = async () => {
        await TypesDocumentsService.index(token).then((res) => {
            setTypesdocuments(res.data.data.filter((option) => option.is_enrolement == false))
        }).catch(error => {
            console.log(error)
        })
    }

    //get liste dossiers
    const fetchDossiers = async () => {
        await DossiersService.listeDossierPayer(token)
            .then((response) => {
                const data = response.data.data.map((item) => {
                    return {
                        name: item.reference_dossier,
                        id: item.id
                    }
                })
                setDossiers(data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

  
    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()
        let form = {
            dossier_id : dossier_id, type_document_id : type_document_id
        }
        setLoading(true)

        await CalendriersProceduresService.store(form, token)
            .then((response) => {
                setLoading(false)
                if (response.status == 200) {
                    navigate(myroutes.calendriersprocedures_index)
                    //alert ("ok")
                    Swal.fire({
                        icon: "success",
                        text: response.data.message,
                        timer: 3000,
                    });
                } else {
                    //redirection sur index
                    Swal.fire({
                        icon: "error",
                        text: response.data.message + ' ' + response.data.data,
                        showConfirmButton: true,
                        timer: 3000,
                    });
                }
            }).catch(error => {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
                //affichage des erreurs sur le formulaire
                setValidationError(error.response.data.message)
            });
    }

  
    useEffect(() => {
        fetchDossiers();
        fetchTypesdocuments();
        $("#datesaudiences-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Calendrier des procédures </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Ajouter un calendrier des procédures </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                                    <Row>
                                        <Col md={4} sm={12} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Numero du dossier<i className="text-danger">*</i></Form.Label>
                                                <Dropdown value={dossier_id} onChange={(e) => setDossier_id(e.value)} options={dossiers} optionLabel="name" placeholder="Rechercher..."
                                                    filter className="w-100" />
                                            </Form.Group>

                                        </Col>

                                        <Col md={4} sm={12} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Types de documents<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="type_document_id"
                                                    name="type_document_id"
                                                    value={type_document_id}
                                                    required
                                                    onChange={(e) => {
                                                        setTypedocument_id(e.target.value)
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {typesdocuments.length > 0 &&
                                                        typesdocuments.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={4} sm={12} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Date d'échéance<i className="text-danger">*</i></Form.Label>
                                                <InputText id="date_echeance" className="form-control"
                                                    type="datetime-local" name="date_echeance" value={date_echeance} />
                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Observation  <i className="text-danger"></i> <i className="text-info bi bi-question-circle-fill" title="Observation sur le dossier"></i>
                                                </Form.Label>
                                                <Editor value={description} onTextChange={(e) => setDescription(e.htmlValue)} style={{ height: '100px' }} />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.calendriersprocedures_index)}>Annuler</Button>
                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}
