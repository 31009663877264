import React, { useEffect } from "react"
import SideBar from "./sideBar_acteur"
import Footer from "./footer_acteur"
import "../../assets/css/style.css"
import "../../assets/js/main"
import { useNavigate } from "react-router-dom"
import Header from "./header_acteur"

export default function LayoutActeur({ children }) {
    const navigate = useNavigate()

    useEffect(() => {
   
    }, [navigate])

    return (
        <React.Fragment>
         <header id="header" className="header fixed-top d-flex align-items-center bg-warningx" style={{backgroundColor:"#2256A6"}}>   
            <Header />
        </header>
            <SideBar />
                {children}
            <Footer style={{height:"90px"}} />
        </React.Fragment>
    )
}
