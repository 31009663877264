import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import HabilitationsService from "../../../../services/HabilitationsService";
import { myroutes } from "../../../../routes/webroute";

export default function HabilitationsEdit() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const location = useLocation()
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [libelle, setLibelle] = useState('')
    const [code, setCode] = useState('')

    ///Save form data
    const handleUpdate = async (event) => {
        event.preventDefault()
        let form = {
            libelle, code
        }

        await HabilitationsService.update(location.state.ref, form,token).then((response) => {
            if (response.data.erreur) {
                Swal.fire({
                    icon: "error",
                    text: response.data.erreur,
                    showConfirmButton: false,
                    timer: 2000
                })
                setValidationError(response.data.erreur)
            } else {
                //redirection sur index
                navigate(myroutes.habilitations_index)
                 Swal.fire({
                    icon: "success",
                    text: response.data.message,
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2500,
                    timerProgressBar: true,
                    background: "green",
                    color : "white",
                    iconColor: "white"
                })
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message
            })
            //affichage des erreurs sur le formulaire
            setValidationError(error.erreur)
        });
    }

    //get edit data
    const edit = async () => {
        await HabilitationsService.edit(location.state.ref,token).then((response) => {
            const data = response.data.data
            setLibelle(data.libelle)
            setCode(data.code)

        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message
            })
            setValidationError(error.erreur)
        });
    }

    useEffect(() => {
        edit();
        $("#parametres-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///

    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                <div className="container-xl app-card-body">


                <div className="row g-3 align-items-center justify-content-between">
                        <div className="col-auto">
                            <div className="pagetitle">
                                <h1 className="app-page-title mb-0">Paramètres</h1>
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                        <li className="breadcrumb-item active">Statuts </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/*//row*/}

                    <div className="row g-3 mb-4 align-items-center justify-content-between">
                        <div className="col-auto">
                            <h1 className="app-page-title mb-0">Modification </h1>
                        </div>
                    </div>{/*//row*/}

                    <div className="app-card app-card-settings shadow-lg mb-5">
                        <div className="app-card-body py-3">
                            <Row>
                                {
                                    Object.keys(validationError).length > 0 && (
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="alert alert-danger">
                                                    <ul className="mb-0">
                                                        {
                                                            <li>{validationError}</li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Row>
                            <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleUpdate}>
                            <Row>
                                    <Col md={12} sm={12} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Code <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border  border rounded-2" onChange={(e) => setCode(e.target.value)}
                                                value={code}
                                                placeholder="code"
                                                name="code"
                                                aria-label="code"
                                                maxLength={100}
                                                minLength={3}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} sm={12} className="form-group">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Libelle <i className="text-danger">*</i>
                                            </Form.Label>
                                            <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                                value={libelle}
                                                placeholder="Libelle du statut"
                                                name="libelle"
                                                aria-label="libelle"
                                                maxLength={100}
                                                minLength={3}
                                                type="text"
                                                required

                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                <Button variant="secondary" onClick={() => navigate(myroutes.habilitations_index)}>Annuler</Button>
                            </Form>

                        </div>{/*//app-card-body*/}
                    </div>
                    {/*//app-card*/}

                </div>{/*//container-fluid*/}
            </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}