import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { myroutes } from "../../routes/webroute";

import Swal from "sweetalert2";
import AuthService from "../../services/AuthService";
import { Button } from "react-bootstrap";
import "../../styles/styles.css";

import useMediaQuery from '@mui/material/useMediaQuery';
import { afficherMessagesSuccess, afficherOneMessageError } from "../../utils/helpers";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { InputOtp } from 'primereact/inputotp';


export default function InsertCodeOtp() {
  ///############### METHODES #############################//
  const [loading, setLoading] = useState(false);
  const [loginRequest, setLoginRequest] = useState({ email: "", password: "", confirm_password: "" });

  const isMobile = useMediaQuery('(max-width:600px)');
  const isBrowser = useMediaQuery('(min-width:600px)');

  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [otp, setOtp] = useState('');

  const handleChangePassword = async (e) => {
    //e.preventDefault();
    setLoading(true)
    let form = { email: loginRequest.email, otp: otp, password: loginRequest.password, confirm_password: loginRequest.confirm_password }

    await AuthService.validationOtp(form).then(response => {
      if (response.status === 200) {
        setLoading(false)

        afficherMessagesSuccess(response.data.message)
        navigate(myroutes.login)

      } else {
        setLoading(false)

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.message,
        })
      }
    }).catch(error => {
      setLoading(false)
      console.log(error)
      afficherOneMessageError(error.response.data.message ?? error.response.data.error.message)
    })

    setLoading(false)
  };

  const onInputChange = (name, e) => {
    const val = (e.target && e.target.value) || "";

    let _formRequest = { ...loginRequest };
    _formRequest[`${name}`] = val;
    setLoginRequest(_formRequest);

  };


  const customInput = ({ events, props }) => {
    return <><input {...events} {...props} type="text" className="custom-otp-input-sample" />
      {props.id === 2 && <div className="px-3">
        <i className="pi pi-minus" />
      </div>}
    </>
  };


  const handleGetNewCode = async () => {
    if (!location.state || location.state.email == null) {
      afficherOneMessageError("Vous devez renseigner à nouveau votre email. Cliquez sur le bouton Annuler.")
      return;
    }

    setLoading(true)
    await AuthService.forgotPassword({ email: location.state.email }).then(response => {
      if (response.status === 200) {

        afficherMessagesSuccess(response.data.message)
        setOtp("")
        setLoginRequest("")
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.message,
        })
      }
    }).catch(error => {
      console.log(error)
    })

    setLoading(false)
  }


  const handleBack = () => {
    navigate(myroutes.forgot_password);
  }

  useEffect(() => {
    if (location.state && location.state.email !== null) {
      setLoginRequest({email: location.state.email})
    }

  }, []);
  ///############### END METHODES #############################//

  return (
    <React.Fragment>
      <section className="bg-login py-md-5 py-xl-8">
        <div className="container position-absolute top-50 start-50 translate-middle">
          <div className="row gy-4 align-items-center">

            <div className="col-12 col-md-6 col-xl-7">
              <div className="d-flex justify-content-center text-bg-login">
                <div className="col-12">
                  {/**Affichage pour ordinateur et tablet */}
                  {isBrowser &&
                    <div className="row">

                      <div className="col-md-12 mx-auto text-center">
                        <Link to={myroutes.login}>
                          <img
                            style={{
                              height: "270px"
                            }}
                            src="/NiceAdmin/assets/img/logo-blanc.png"
                            alt="logo-blanc"
                            className="img-fluid rounded mb-1" loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>}

                  {/**Affichage pour mobile uniquement */}
                  {isMobile && <>
                    <div className="row" style={{ marginTop: "300px" }}>
                      <div className="col-md-12 mx-auto text-center">
                        <Link to={myroutes.login}>
                          <img
                            style={{
                              height: "200px"
                            }}
                            src="/NiceAdmin/assets/img/logo-blanc.png"
                            alt="logo-blanc"
                            className="img-fluid rounded mb-4" loading="lazy"
                          />
                        </Link>
                      </div>
                    </div>
                  </>}


                  <hr className="border-primary-subtle mb-4" />

                  <p className="h4 mb-5 text-warning text-center fw-bold">Plateforme d'Enrôlement et de Gestion des Procédures</p>

                  <div className="text-endx">
                    <svg className="text-white bi bi-grip-horizontal" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-5 text-center">
              <div className="card border-0 rounded-4">
                <div className="card-body p-3 p-md-4 p-xl-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-4">
                        <h3 className="fw-bold" style={{ fontSize: "22px" }}><i className="fas fa-user-edit"></i> &nbsp;&nbsp;Entrer le code de vérification</h3>
                      </div>
                    </div>
                  </div>

                  <style scoped>
                    {`
                    .custom-otp-input-sample {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        appearance: none;
                        text-align: center;
                        transition: all 0.2s;
                        border-radius: 0;
                        border: 1px solid var(--surface-400);
                        background: transparent;
                        outline-offset: -2px;
                        outline-color: transparent;
                        border-right: 0 none;
                        transition: outline-color 0.3s;
                        color: var(--text-color);
                    }

                    .custom-otp-input-sample:focus {
                        outline: 2px solid var(--primary-color);
                    }

                    .custom-otp-input-sample:first-child,
                    .custom-otp-input-sample:nth-child(5) {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }

                    .custom-otp-input-sample:nth-child(3),
                    .custom-otp-input-sample:last-child {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                        border-right-width: 1px;
                        border-right-style: solid;
                        border-color: var(--surface-400);
                    }
                `}
                  </style>
                  <div className="d-flex justify-content-center align-items-center mb-4">
                    <InputOtp value={otp} onChange={(e) => setOtp(e.value)} length={6} inputTemplate={customInput} style={{ gap: 0 }} integerOnly />
                  </div>                  

                  <div className="col-md-12 email mb-4 mt-3">
                    <label className="fw-bold mb-2" style={{float: "left"}} htmlFor="signin-email1">
                      Email <span style={{ color: 'red' }}>*</span>
                    </label>
                    <input
                      id="signin-email1"
                      value={loginRequest.email}
                      minLength={3}
                      maxLength={100}
                      name="signin-email1"
                      onChange={(e) => onInputChange("email", e)}
                      type="email"
                      className="form-control signin-email border border-border-secondary"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div className="col-md-12 password mb-3">
                    <label
                      className="fw-bold mb-2"
                      htmlFor="signin-password" style={{float: "left"}}
                    >
                      Nouveau mot de passe <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                      <input
                        style={{}}
                        id="signin-password2"
                        value={loginRequest.password}
                        minLength={3}
                        maxLength={50}
                        name="signin-password2"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) =>
                          onInputChange("password", e)
                        }
                        className="form-control signin-password border border-border-secondary"
                        placeholder="Mot de passe"
                        required="required"
                      />
                      <button
                        type="button"
                        className="password-toggle-button btn btn-outline-secondary"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </button>
                    </div>
                  </div>

                  <div className="col-md-12 password mb-3">
                    <label
                      className="fw-bold mb-2"
                      htmlFor="signin-password" style={{float: "left"}}
                    >
                      Confirmer le mot de passe <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="input-group">
                      <input
                        id="signin-confirm_password"
                        value={loginRequest.confirm_password}
                        minLength={3}
                        maxLength={50}
                        name="signin-confirm_password"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) =>
                          onInputChange("confirm_password", e)
                        }
                        className="form-control signin-password border border-border-secondary"
                        placeholder="Confirmer mot de passe"
                        required="required"
                      />
                      <button
                        type="button"
                        className="password-toggle-button btn btn-outline-secondary"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FiEyeOff /> : <FiEye />}
                      </button>
                    </div>
                  </div>

                  <div className="flex justify-content-between py-3 align-self-stretch">
                    Vous voulez un nouveau code ? <a href="#" className="text-primary" disabled={loading} onClick={handleGetNewCode}>Cliquez ici</a>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <Button disabled={loading} onClick={handleChangePassword} style={{ fontSize: "18px" }} className="btn app-btn-dark btn-warning w-100 mx-auto fw-bold" >
                        Valider
                      </Button>
                    </div>
                    <div className="col-md-6 mt-2">
                      <Button variant="dark" onClick={handleBack} className="w-100" disabled={loading}>Annuler</Button>
                    </div>
                  </div>

                  <div className="">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="footer-login">
        <div className="col-md-12 d-flex w-100 ">
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#008559" }}
          ></div>
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#FFD400" }}
          ></div>
          <div
            className="col-md-4"
            style={{ height: "8px", backgroundColor: "#E43009" }}
          ></div>
        </div>
        <div className="container text-center py-2">
          <small className="copyright">
            &copy;{new Date().getFullYear()} COUR D'APPEL DE COMMERCE -
            COTONOU | Tous droits réservés.
            {/*
              Conception & Réalisation :{" "}
              <a className="app-link" href="www.digiweb.bj" target="_blank">
                DigiWeb SARL
              </a>
              */}
          </small>
        </div>
      </section>
    </React.Fragment>
  );
}
