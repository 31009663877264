import http from "./http";

class FraisPaiementsService {
    indexFrontoffice(token){
        return http.get("/api/v01/web/fraispaiements/liste",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    
    store(data, token){
        return http.post("/api/v01/web/fraispaiements/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    } 

}

export default new FraisPaiementsService()