import { Button } from '@mui/material';
import { ProgressSpinner } from 'primereact/progressspinner';

const ButtonWithSpinner = ({ loading, color, onClick, children, ...props }) => {
  return (
    <Button
      {...props}
      disabled={loading} // Désactiver le bouton lorsqu'il est en chargement
      onClick={onClick}
      variant="contained"
      color={color ? color : 'primary'}
    >
      {loading ? <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" /> : children}
    </Button>
  );
}

export default ButtonWithSpinner;
