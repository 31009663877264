import http from "./http";

class ModesPaiementsService {
    indexFrontoffice(token){
        return http.get("/api/v01/web/modespaiements/liste",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    index(token){
        return http.get("/api/v01/web/modespaiements/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }
    
    store(data, token){
        return http.post("/api/v01/web/modespaiements/store", data, { headers: { 'Authorization': 'Bearer '+token } })
    } 

}

export default new ModesPaiementsService()