import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import DossiersService from "../../../services/DossiersService";
import DossierspartiesdocumentsService from "../../../services/DossierspartiesdocumentsService";
import LayoutActeur from "../inc/layout_acteur";
import TypesDocumentsService from "../../../services/TypesDocumentsService";

export default function DossiersdocumentsCreateActeur() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState({})
    const token = useSelector(state => state.auth.token)

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [dossiers, setDossiers] = useState([])
    const [dossier_id, setDossier_id] = useState(" ")
    const [paths, setPaths] = useState([])
    const [typesdocuments, setTypesdocuments] = useState([]);
    const [type_document_id, setTypedocument_id] = useState("");


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const selectedFiles = Array.from(e.target.files);
        setPaths([...paths, ...selectedFiles]);
    };



    //get liste dossiers
    const fetchDossiers = async () => {
        await DossiersService.listeDossierPayer(token)
            .then((response) => {
                setDossiers(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //get liste types documents
    const fetchTypesdocuments = async () => {
        await TypesDocumentsService.index(token)
            .then((response) => {
                setTypesdocuments(response.data.data.filter(option => option.is_enrolement != 0));
            })
            .catch((error) => {
                console.log(error);
            });
    };


    ///Save form data
    const handleSubmit = async (event) => {
        event.preventDefault()


        try {
            const formData = new FormData();

            paths.forEach((file, index) => {
                formData.append(`path[${index}]`, file);
            });
            //formData.append('path[]', paths);  
            formData.append('dossier_id', dossier_id);
            formData.append('type_document_id', type_document_id);

            const response = await DossierspartiesdocumentsService.store(formData, token);
            /* console.log("--- save data ----")
             console.log(response)
             console.log("--- save data ----")*/

            if (response.status === 200) {
                // Afficher un message de réussite
                Swal.fire({
                    icon: "success",
                    title: "Merci !",
                    text: response.data.message,
                    timer: 3000,
                });
                navigate(myroutes.dossiersacteurs_index)

            } else {
                Swal.fire({
                    icon: "error",
                    text: "Une erreur s'est produite lors de l'enregistrement",
                    showConfirmButton: true,
                    timer: 3000,
                });
            }
        } catch (error) {
            // En cas d'erreur, afficher un message d'erreur
            console.log(error)
            console.error("Erreur lors de l'envoi du fichier :", error.message);
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: error.message || 'Une erreur s\'est produite lors de l\'envoi du fichier. Veuillez réessayer.'
            });
        }
    };



    useEffect(() => {
        fetchDossiers();
        fetchTypesdocuments();
        $("#dossiers-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <LayoutActeur>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <div className="row g-3 align-items-center justify-content-between">
                            <div className="col-auto">
                                <div className="pagetitle">
                                    <h1 className="app-page-title mb-0">Paramètres</h1>
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={myroutes.dashboard_acteur} >Accueil</Link></li>
                                            <li className="breadcrumb-item active">Documents dossiers </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/*//row*/}

                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h1 className="app-page-title mb-0">Enregistrement </h1>
                            </div>
                        </div>{/*//row*/}

                        <div className="app-card app-card-settings shadow-lg mb-5">
                            <div className="app-card-body py-3">
                                <Row>
                                    {
                                        Object.keys(validationError).length > 0 && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="alert alert-danger">
                                                        <ul className="mb-0">
                                                            {
                                                                <li>{validationError}</li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                                <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Référence dossier<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="dossier_id"
                                                    name="dossier_id"
                                                    value={dossier_id}
                                                    required
                                                    onChange={(e) => {
                                                        setDossier_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {dossiers.length > 0 &&
                                                        dossiers.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.reference_dossier}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} sm={6} className="formx-groupx">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold">Type document<i className="text-danger">*</i></Form.Label>
                                                <Form.Select
                                                    className=""
                                                    id="type_document_id"
                                                    name="type_document_id"
                                                    value={type_document_id}
                                                    required
                                                    onChange={(e) => {
                                                        setTypedocument_id(e.target.value);
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        -- Sélectionnez --
                                                    </option>
                                                    {typesdocuments.length > 0 &&
                                                        typesdocuments.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.libelle}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} sm={12} className="form-group">
                                            <Form.Group className="mb-3">
                                                <Form.Label className="fw-bold mb-3">
                                                    Joindre fichier(s) <i className="text-danger">*</i>{" "}
                                                    (pdf,doc,docx,txt...)
                                                </Form.Label>
                                                <Form.Group className="mb-3">
                                                    <Form.Control
                                                        className="border border rounded-2"
                                                        onChange={handleFileChange}
                                                        name="path"
                                                        aria-label="path"
                                                        required
                                                        type="file"
                                                        accept="document/pdf"
                                                        id="path"
                                                        multiple
                                                    />
                                                </Form.Group>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                    <Button type="submit" variant="warning me-3">Enregistrer</Button>
                                    <Button variant="secondary" onClick={() => navigate(myroutes.dossiersacteurs_index)}>Annuler</Button>
                                </Form>

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                    </div>{/*//container-fluid*/}
                </div>{/*//app-content*/}
            </main>
        </LayoutActeur>
    )
}