import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import $ from "jquery"
import ChambresmembresService from "../services/ChambresmembresService";
import { convertDateToTexte } from "../utils/helpers";






const TabSaisines = ({ dossier }) => {
  const token = useSelector(state => state.auth.token)
  const [chambres_membres, setChambresMembres] = useState([])


  //get liste membres chambres
  const fetchChambresMembres = async () => {
    await ChambresmembresService.index(token).then((res) => {
      setChambresMembres(res.data.data);
    }).catch(error => {
      console.log(error)
    })
  }
  //Retourner les membres d'une chambre à partir de chambre_id
  const getActeurChambre = (chambreId) => {
    let membres = chambres_membres.find(opt => parseInt(opt.chambre_id) === chambreId);
    //console.log(membres)
    if (membres) return membres.get_utilisateur.nom + " " + membres.get_utilisateur.prenoms
  }

  useEffect(() => {
    fetchChambresMembres()
    $('.dataTable').DataTable();
  }, []);


  return (
    <div style={{ fontFamily: "montserrat" }}>
      <h5 className="card-title" style={{ fontSize: "14px", borderBottom: "1px solid rgba(1, 41, 112, 0.2)" }}>
        Informations sur la saisine
      </h5>
      <div className="table-responsive">
        <table className="table table-striped dataTable">
          <thead className="table-info mt-2">
            <tr>
              <th>#</th>
              <th>Référence</th>
              <th>Objets</th>
              <th>Acteurs autorisés</th>
              <th>Statut</th>
              <th>Type Prodédure</th>
              <th>Date de création</th>
              <th>Date d'audience</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-bold">{dossier.id}</td>
              <td>{dossier && dossier.reference_dossier}</td>
              <td className=""><span className="badge text-bg-warning">{dossier && dossier.get_objet && dossier.get_objet.libelle || 'N/A'}</span></td>
              <td><span>
                {dossier &&
                  dossier.get_date_audience &&
                  dossier.get_date_audience.length > 0 &&
                  dossier.get_date_audience.map((option, index) => (
                    <ul key={index} className="justify-content-center">
                      {option.get_calendrier && getActeurChambre(option.get_calendrier.chambre_id)}
                    </ul>
                  ))
                }
              </span></td>
              <td><span className="badge text-bg-success">{dossier && dossier.get_statut_dossier && dossier.get_statut_dossier.libelle}</span></td>
              <td><span className="badge text-bg-secondary">{dossier && dossier.get_procedure.get_type_procedure.libelle}</span></td>
              <td>{dossier && dayjs(dossier.created_at).format("DD/MM/YYYY H:m:s")}</td>
              <td>
                {dossier &&
                  dossier.get_date_audience &&
                  dossier.get_date_audience.length > 0 &&
                  dossier.get_date_audience.map((option, index) => (
                    <span key={index}>
                      {option.get_calendrier && convertDateToTexte(option.get_calendrier.jour)} <br />
                    </span>
                  ))
                }
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>


  );
};


export default TabSaisines;