import React, { useEffect, useState } from "react";
import FileComponent from "./FileComponent";
import DossierspartiesdocumentsService from "../services/DossierspartiesdocumentsService";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { app_url } from "../services/http";
import { Button, Col, Form, Row } from "react-bootstrap";
import TypesDocumentsService from "../services/TypesDocumentsService";
import { afficherMessagesErreur, afficherMessagesSuccess, afficherOneMessageError, copyToClipboard, setDataTable } from "../utils/helpers";
import dayjs from "dayjs";
import CalendriersProceduresService from "../services/CalendriersProceduresService";
import Swal from "sweetalert2";
import TypespartiesService from "../services/TypespartiesService";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import parse from "html-react-parser"
import StatutDossierEnum from "../enum/statutDossierEnum";



const TabCalendriersProcedures = ({ dossier }) => {
  const token = useSelector(state => state.auth.token)
  //Verifier si le dossier est déjà vidé
  const isVide = StatutDossierEnum.isStatusVide(dossier.statut_dossier_id);

  //const [editData, setEditData] = useState({})
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [calendriersprocedures, setCalendriersprocedures] = useState([])

  const [detailLabel, setDetailLabel] = useState("")
  const profils = useSelector((state) => state.auth.profil);

  const handleShowModalAdd = () => setShowModalAdd(true);
  const handleCloseModalAdd = () => setShowModalAdd(false);

  const handleShowModalEdit = () => setShowModalEdit(true);
  const handleCloseModalEdit = () => setShowModalEdit(false);

  const [typesparties, setTypesparties] = useState([]);
  const [type_partie_id, setTypepartie_id] = useState({});
  const [date_echeance, setDateEcheance] = useState({});

  const [typesdocuments, setTypesdocuments] = useState([]);
  const [type_document_id, setTypedocument_id] = useState({});
  const [documents, setDocuments] = useState([{ id: 1 }]);
  const [docData, setDocData] = useState(documents.map(doc => ({ id: doc.id, type_document_id: '', type_partie_id: '', date_echeance: '' })));


  const addLine = () => {
    const newDocId = docData.length ? docData[docData.length - 1].id + 1 : 1;
    setDocData([...docData, { id: newDocId, type_document_id: '', type_partie_id: '', date_echeance: '' }]);

  };

  const removeLine = (docId) => {
    setDocData(prevDocData => prevDocData.filter(doc => doc.id !== docId));
  };

  ///### Evenement: Sélection de donnée sur le formulaire d'ajout ////######## 
  const handleChangeTypeDoc = (e, docId) => {

    const newDoc = parseInt(e.target.value);

    setDocData(prevDocData => prevDocData.map(doc =>
      doc.id === docId ? { ...doc, type_document_id: newDoc } : doc
    ));

  };

  const handleChangeTypePartie = (e, docId) => {

    const newValue = parseInt(e.target.value);

    setDocData(prevDocData => prevDocData.map(doc =>
      doc.id === docId ? { ...doc, type_partie_id: newValue } : doc
    ));

  };

  const handleChangeDateEcheance = (e, docId) => {

    const newValue = e.target.value;

    setDocData(prevDocData => prevDocData.map(doc =>
      doc.id === docId ? { ...doc, date_echeance: newValue } : doc
    ));

  };
  ///### End Evenement: Sélection de donnée sur le formulaire d'ajout ////######## 



  //Afficher le modal de modification
  const handleEdit = async (option) => {
    setDetailLabel(option.ref)
    try {
      const response = await CalendriersProceduresService.edit(option.ref, token);
      if (response.status === 200 && response.data.status == true) {
        //setEditData(response.data.data);
        const data = response.data.data;
        setDateEcheance(data.date_echeance)
        setTypedocument_id(data.type_document_id)
        setTypepartie_id(data.type_partie_id)
      }
    } catch (error) {
      console.log(error);
    }

    handleShowModalEdit()
  }

  //get liste types documents
  const fetchTypesdocuments = async () => {
    await TypesDocumentsService.index(token).then((response) => {
      //Filtrer les données
      const filteredData = response.data.data.filter((option) => (option.is_enrolement == false) || (option.is_enrolement == null));

      //Ordonnant par ordre alphabétique
      const sortedData = filteredData.sort((a, b) => {
        const nameA = a.libelle.toUpperCase(); // ignore upper and lowercase
        const nameB = b.libelle.toUpperCase(); // ignore upper and lowercase

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setTypesdocuments(sortedData);

    })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = {
      data: docData,
      dossier_id: dossier.id
    };

    await CalendriersProceduresService.store(form, token).then((response) => {
      if (response.data.status == true && response.status === 200) {
        handleCloseModalAdd();
        //Vider les champs du formulaire
        setDocData(documents.map(doc => ({ id: doc.id, type_document_id: '', type_partie_id: '', date_echeance: '' })));
        afficherMessagesSuccess(response.data.message)

        //Actualiser la page
        setTimeout(() => {
          //window.location.reload()
        }, 1500);
        fetchCalendrierProcedure();

      } else {
        afficherOneMessageError(response.data.message || "Une erreur s'est produite!")
      }
    }).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Erreur de validation',
        html: error.response.data.data,
        toast: true,
        position: "top-end",
        timer: 10000,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        timerProgressBar: true,
      });
    });

  }


  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    let form = {
      date_echeance: date_echeance,
      type_document_id: type_document_id,
      type_partie_id: type_partie_id,
      dossier_id: dossier.id
    };

    let ref = detailLabel;

    await CalendriersProceduresService.update(ref, form, token).then((response) => {
      if (response.data.status == true && response.status === 200) {
        handleCloseModalEdit();

        afficherMessagesSuccess(response.data.message)

        //Actualiser la page
        /*setTimeout(() => {
          window.location.reload()
        }, 1500);*/
        fetchCalendrierProcedure();

      } else {
        afficherOneMessageError(response.data.message || "Une erreur s'est produite!")
      }
    }).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Erreur de validation',
        html: error.response.data.data,
        toast: true,
        position: "top-end",
        timer: 10000,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        timerProgressBar: true,
      });
    });

  }

  //get liste calendrier procedure
  const fetchCalendrierProcedure = async () => {
    try {
      const response = await CalendriersProceduresService.findByDossierId({dossier_ref: dossier.ref}, token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setCalendriersprocedures(responseData.data);

      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

    //Afficher dataTable
    setDataTable();
  };

  const handleCopyReference = (reference, itemId) => {
    copyToClipboard(reference)
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red"
    }).then(result => {
      if (result.isConfirmed) {
        CalendriersProceduresService.delete(param, token).then((response) => {
          if (response.status === 200) {

            afficherMessagesSuccess(response.data.message)
            setTimeout(() => {
              window.location.reload()
            }, 1500);
          } else {
            afficherOneMessageError(response.data.message)
          }
        }).catch(e => {
          Swal.fire({
            icon: "error",
            text: e.message
          })
          console.log(e)
        })
      }
    })
  }

  //get liste types parties
  const fetchTypesparties = async () => {
    try {
      const response = await TypespartiesService.index(token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setTypesparties(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

  };



  useEffect(() => {
    fetchCalendrierProcedure();
    fetchTypesdocuments();
    fetchTypesparties();
  }, []);



  return (
    <div style={{ fontFamily: "montserrat" }}>
      <div className="row g-3 mb-0  align-items-center justify-content-between">
        <div className="card bg-warningx pt-1" style={{ color: "#1f84d6", backgroundColor: "#1f84d621", border: "1px solid white", }}>
          <h6 className="pt-1">
            <i className="fa fa-file me-2"></i>CALENDRIERS DES PROCÉDURES &nbsp; &nbsp;
            {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
              !isVide && <Button className="btn btn-success btn-sm" onClick={(e) => handleShowModalAdd()} title="Ajouter"><i className="bi bi-plus"></i>Ajouter</Button>}
          </h6>
        </div>
      </div>

      <div className="app-card app-card-orders-table shadow-lg mb-5">
        <div className="app-card-body mx-3 py-2">
          <div className="table-responsive mt-4">

            <table className="table table-striped table-font table-bordered table-hover app-table-hover mb-0 data-table">
              <thead className="table-info mt-2">
                <tr>
                  <th className="">N°</th>
                  <th className="">Type document</th>
                  <th className="">Type de partie</th>
                  <th className="">Echéance</th>
                  {/**<th className="">Observation</th> */}
                  {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
                    <th className="text-center">Activité</th>}
                </tr>
              </thead>
              <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                {calendriersprocedures.length > 0 && calendriersprocedures.map((item, index) => (
                  <tr key={index}>
                    <td className="cell">{index + 1}</td>
                    <td className="cell">{item.get_type_document ? item.get_type_document.libelle : 'N/A'}</td>
                    <td className="cell">{item.get_type_partie ? item.get_type_partie.libelle : 'N/A'}</td>                    
                    <td className="cell">{dayjs(item.date_echeance).format("DD/MM/YYYY")}</td>
                    {/***<td className="cell">{item.description != null ? parse(item.description) : item.description}</td> */}
                    {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
                      <>
                        <td className="text-center">
                          <div className="d-flex justify-content-evenly">
                            <Button className="btn btn-sm btn-primary" onClick={() => handleEdit(item)} title="Modifier">
                              <i className="bi bi-pen-fill"></i></Button> &nbsp;&nbsp;
                            <Button className="btn btn-sm btn-danger" onClick={() => onDelete(item.ref)} title="Supprimer">
                              <i className="bi bi-trash"></i>
                            </Button> &nbsp;&nbsp;
                            <Button
                              className="btn btn-sm btn-success"
                              onClick={() => handleCopyReference(item.get_dossier?.reference_dossier, item.id)}>
                              <i className="bi bi-clipboard2-fill"></i> Ref
                            </Button>
                          </div>

                        </td>
                      </>}
                  </tr>
                ))}


              </tbody>
            </table>
          </div>{/*//table-responsive*/}

        </div>{/*//app-card-body*/}
      </div>

      {/* Modal ajout*/}
      <Dialog
        header={"Ajouter un calendrier des procédures"}
        visible={showModalAdd}
        maximizable
        onHide={handleCloseModalAdd}
        style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
        headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
      >

        <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>


          {docData.map((doc, index) => (
            <div key={doc.id}>

              <Row className="pt-4">
                <div className="col-lg-4 col-sm-12">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">Partie ou conseil<i className="text-danger">*</i></Form.Label>
                    <Form.Select
                      className=""
                      id="type_partie_id"
                      name={`type_partie_id-${doc.id}`}
                      value={doc.type_partie_id}
                      required
                      onChange={(e) => {
                        handleChangeTypePartie(e, doc.id)
                      }}
                    >
                      <option value="" selected>
                        -- Sélectionnez --
                      </option>
                      {typesparties.length > 0 &&
                        typesparties.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.libelle}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-lg-4 col-sm-12">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">Types de documents<i className="text-danger">*</i></Form.Label>
                    <Form.Select
                      className=""
                      id="type_document_id"
                      name={`type_document_id-${doc.id}`}
                      value={doc.type_document_id}
                      required
                      onChange={(e) => {
                        handleChangeTypeDoc(e, doc.id)
                      }}
                    >
                      <option value="" selected>
                        -- Sélectionnez --
                      </option>
                      {typesdocuments.length > 0 &&
                        typesdocuments.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.libelle}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-lg-4 col-sm-12">
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-bold">Date d'échéance<i className="text-danger">*</i></Form.Label>
                    <InputText id="date_echeance" className="form-control" onChange={(e) => {
                      handleChangeDateEcheance(e, doc.id)
                    }}
                      type="date" name={`date_echeance-${doc.id}`} required value={doc.date_echeance} />
                  </Form.Group>
                </div>

              </Row>

              {(index > 0) && (
                <Button className="mb-3 me-3" variant="danger"
                  onClick={() => removeLine(doc.id)}>Supprimer</Button>
              )}

              {index === docData.length - 1 && ( // Afficher les boutons uniquement pour le dernier bloc
                <>
                  <Button className="mb-3" type="button"
                    variant="warning me-3" onClick={addLine}>
                    <i className="bi bi-plus"></i> Ajouter plus
                  </Button>
                  <Button className="mb-3" type="submit"
                    variant="success me-3">
                    <i className="bi bi-check"></i> Enregistrer
                  </Button>
                  <Button variant="secondary me-3" className="mb-3" onClick={handleCloseModalAdd}>Fermer</Button>
                </>
              )}

            </div>
          ))}

        </Form>


      </Dialog>

      {/* Modal modification*/}
      <Dialog
        header={"Modification d'un calendrier des procédures"}
        visible={showModalEdit}
        maximizable
        onHide={handleCloseModalEdit}
        style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
        headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
      >

        <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmitEdit}>

        <Row className="pt-4">
            <div className="col-lg-4 col-sm-12">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Partie ou conseil<i className="text-danger">*</i></Form.Label>
                <Form.Select
                  className=""
                  id="type_partie_id"
                  name={`type_partie_id`}
                  value={type_partie_id}
                  required
                  onChange={(e) => {
                    setTypepartie_id(e.target.value)
                  }}
                >
                  <option value="" selected>
                    -- Sélectionnez --
                  </option>
                  {typesparties.length > 0 &&
                    typesparties.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-lg-4 col-sm-12">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Types de documents<i className="text-danger">*</i></Form.Label>
                <Form.Select
                  className=""
                  id="type_document_id"
                  name={`type_document_id`}
                  value={type_document_id}
                  required
                  onChange={(e) => {
                    setTypedocument_id(e.target.value)
                  }}
                >
                  <option value="" selected>
                    -- Sélectionnez --
                  </option>
                  {typesdocuments.length > 0 &&
                    typesdocuments.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.libelle}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-lg-4 col-sm-12">
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Date d'échéance<i className="text-danger">*</i></Form.Label>
                <InputText id="date_echeance" className="form-control" required onChange={(e) => {
                  setDateEcheance(e.target.value)
                }}
                  type="date" name={`date_echeance`} value={date_echeance} />
              </Form.Group>
            </div>

          </Row>

          <Button className="mb-3" type="submit"
            variant="success me-3">
            <i className="bi bi-check"></i> Enregistrer
          </Button>
          <Button variant="secondary me-3" className="mb-3" onClick={handleCloseModalEdit}>Fermer</Button>

        </Form>


      </Dialog>



    </div>

  );
};

export default TabCalendriersProcedures;
