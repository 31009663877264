import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import StatutsService from "../../../../services/StatutsService";
import { useSelector } from "react-redux";
import ChambresService from "../../../../services/ChambresService";
import PolesService from "../../../../services/PolesService";
import { filterStatut } from "../../../../utils/helpers";

export default function ChambresEdit() {
  //##########################/// METHODES ##########################///
  //navigate pour faire les redirections
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  //Creation des ascesseurs getters et setters pour tout le formulaire
  const [libelle, setLibelle] = useState("");
  const [description, setDescription] = useState("");
  const [statut_id, setStatut_id] = useState('')
  const [statuts, setStatuts] = useState([])
  const [pole_id, setPole_id] = useState('')
  const [poles, setPoles] = useState([])
  const [code, setCode] = useState('')


  //get liste poles
  const fetchPoles = async () => {
    try {
      const response = await PolesService.index(token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        setPoles(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de poles:", error);
    }

  };


  //get liste statuts
  const fetchStatuts = async () => {
    try {
      const response = await StatutsService.index(token);
      const responseData = response.data;

      if (responseData.status && responseData.data && responseData.data.length > 0) {
        
        setStatuts(filterStatut(responseData.data));
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }

  };

  ///Save form data
  const handleUpdate = async (event) => {
    event.preventDefault();
    let form = {
      libelle,
      description,
      statut_id,
      pole_id,
      code
    };

    await ChambresService.update(location.state.ref, form, token)
      .then((response) => {
        //redirection sur index
        navigate(myroutes.chambres_index);
        Swal.fire({
          icon: "success",
          text: response.data.message,
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });

        setValidationError(response.data.message);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error.message,
        });
        //affichage des erreurs sur le formulaire
        setValidationError(error.erreur);
      });
  };

  const edit = async () => {
    try {
      const response = await ChambresService.edit(
        location.state.ref,
        token
      );
      //console.log("API response", response);
      if (response.data) {
        const data = response.data.data;
        // console.log("data", data);
        setLibelle(data.libelle);
        setDescription(data.description);
        setStatut_id(data.statut_id);
        setPole_id(data.pole_id);
        setCode(data.code);


      } else {
        console.log("Data not found in API response");
        // Gérer le cas où response.data ou response.data.statut est undefined ou null
      }
    } catch (error) {
      console.error("API error", error);
      Swal.fire({
        icon: "error",
        text: error.message,
      });
      setValidationError(error.erreur);
    }
  };

  useEffect(() => {
    fetchStatuts();
    fetchPoles();
    edit();
    $("#parametres-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Chambres
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h1 className="app-page-title mb-0">Modification d'une chambre </h1>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
              <div className="app-card-body py-3">
                <Row>
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">{<li>{validationError}</li>}</ul>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
                <Form
                  className="mx-3 settings-form"
                  style={{ textAlign: "left" }}
                  onSubmit={handleUpdate}
                >
                  <Row>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Poles <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select className="" id="pole_id" name="pole_id" value={pole_id}
                          required
                          onChange={(e) => {
                            setPole_id(e.target.value);
                          }}>
                          <option value="" selected>
                            --
                          </option>
                          {poles.length > 0 &&
                            poles.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.libelle}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Libelle <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border  border rounded-2"
                          onChange={(e) => setLibelle(e.target.value)}
                          value={libelle}
                          name="libelle"
                          aria-label="libelle"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Code <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control className="border border rounded-2" onChange={(e) => setCode(e.target.value)}
                          value={code}
                          placeholder="Code de la chambre"
                          name="code"
                          aria-label="code"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Statut <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="statut_id"
                          name="statut_id"
                          value={statut_id}
                          // required
                          onChange={(e) => {
                            setStatut_id(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            --
                          </option>
                          {statuts.length > 0 &&
                            statuts.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.libelle}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Description <i className="text-danger"></i>
                        </Form.Label>
                        <textarea
                          value={description}
                          name="resume"
                          id=""
                          cols="30"
                          onChange={(e) => setDescription(e.target.value)}
                          rows="5"
                          className="form-control summernote border border rounded-2"
                          placeholder=""
                        ></textarea>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button type="submit" variant="warning me-3">Enregistrer</Button>
                  <Button variant="secondary" onClick={() => navigate(myroutes.chambres_index)}>Annuler</Button>
                </Form>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}
          </div>
          {/*//container-fluid*/}
        </div>
        {/*//app-content*/}
      </main>
    </Layout>
  );
}
