import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../backoffice/admin/include/layout";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery"
import { useSelector } from "react-redux";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import { afficherMessagesSuccess, afficherOneMessageError } from "../../../../utils/helpers";
import BasicCreateForm from "../../../../components/CreateFormTProcedures";
import TypesDocumentsService from "../../../../services/TypesDocumentsService";
import CreateFormTProcedures from "../../../../components/CreateFormTProcedures";

export default function TypesdocumentsCreate() {

    //##########################/// METHODES ##########################///
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const token = useSelector(state => state.auth.token)


    ///Save form data
    const handleFormSubmit = async (formData) => {

        await TypesDocumentsService.store(formData,token).then((response) => {
            if (response.data.status == true) {
                navigate(myroutes.typesdocuments_index)
                afficherMessagesSuccess("Bravo ! Votre enregistrement a été efectué avec succès. ");
            } else {
                afficherOneMessageError(response.data.message +' '+ response.data.data)                
            }
        }).catch(error => {
            Swal.fire({
                icon: "error",
                text: error.message,
              });
              console.log(error);
        });
    }

       

    useEffect(() => {
        $("#parametres-nav").addClass("show")
    }, [])

    //##########################/// END METHODES ##########################///

    return (
        <Layout>
           <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                <div className="container-xl app-card-body">

                <BreadcrumbComponent params={{isParametre: true, libelle: 'Types de documents'}} routeName={myroutes.typesdocuments_index}></BreadcrumbComponent>
                {/**Appel du formulaire de create */}
                <CreateFormTProcedures onSubmit={handleFormSubmit} routeBack={myroutes.typesdocuments_index} withoutCodeInput={true}></CreateFormTProcedures>
                    
                </div>{/*//container-fluid*/}
            </div>{/*//app-content*/}
            </main>
        </Layout>
    )
}