import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../admin/include/layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { myroutes } from "../../../routes/webroute";
import $ from "jquery"
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";





export default function Voirtout() {
    const navigate = useNavigate();

    useEffect(() => {
        $("#parametres-nav").addClass("show")
    }, [])



    return (
        <Layout>

            <main id="main" className="main">
                <div className="container">
                    <BreadcrumbComponent params={{ isParametre: true, libelle: 'Voir tout' }} routeName={myroutes.typesprocedures_index}></BreadcrumbComponent>
                </div>

                <div className="container d-flex justify-content-center">

                    <div className="row">
                        {/**Calendriers */}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.calendriers_index} >
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-calendar-day" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Calendriers<h3 className="card-title mb-2" ></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>




                        {/**Calendriers globals */}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.calendriersglobals_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-calendar-week" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Calendriers globals<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>

                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Catégories */}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.categories_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-layer-group" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Catégories<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>


                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Chambres**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.chambres_index} >
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-list" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Chambres<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>








                        {/**Chambresmembres **/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.chambresmembres_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-house-user" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Chambres membres<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**habilitations **/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.habilitations_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-user-check" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Habilitations<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Historique des ations **/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.historiqueactions_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-user-check" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Historique des ations<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Motifsvalidations **/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.motifsvalidations_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-list" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Motifs de validations<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Objectifs**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.objets_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-object-group" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Objets<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Parties**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.parties_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fas fa-indent" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Parties<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Partiesdossiers**/}
                        {/**
                       *   <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.partiesdossiers_index} >
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-folder" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Parties Dossiers<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                       */}


                        {/**Partiesmembres**/}
                        {/**
                        *  <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.partiesmembres_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-users" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Parties membres<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        */}


                        {/**PartiesProfils**/}
                        {/**
                         * <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.partiesprofils_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-user" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Parties profils<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                         */}


                        {/**Poles**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.poles_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fas fa-layer-group" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Poles<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Procédures**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.procedures_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fas fa-prescription-bottle" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Procédures<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Profils**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.profils_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-user" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Profils<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>




                        {/**Statuts**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.statuts_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-check" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Statuts<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Statuts Dossiers**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.statutsdossiers_index}>
                                <div className="card-dash blue bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-file" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Statuts Dossiers<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        {/**Statuts juridiques**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.statutsjuridiques_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-building" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Statuts juridiques<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>

                         {/**Tribunaux**/}
                         <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.tribunaux_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fas fa-city" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Tribunaux<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/**Types acteurs**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.typesacteurs_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fas fa-user-tie" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Types d'acteurs<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        {/**Types documents**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.typesdocuments_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fa-solid fa-file" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Types documents<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                                 

                        {/**Types Procédures**/}
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-4">
                            <Link to={myroutes.typesprocedures_index}>
                                <div className="card-dash yellow bg-secondaryx text-whitex">
                                    <div className="card-body">
                                        <div className="card-title d-flex align-items-start justify-content-between">
                                            <div className="avatar flex-shrink-0">
                                                <FontAwesomeIcon icon="fas fa-sitemap" size="2xl" className="icon-color" />
                                            </div>
                                        </div>
                                        <span className="fw-semibold d-block mb-1">Types Procédures<h3 className="card-title mb-2" style={{ float: "right" }}></h3></span>
                                    </div>
                                </div>
                            </Link>
                        </div>


                    </div>

                </div>
            </main>

        </Layout>
    );
}
