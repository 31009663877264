import { Button, Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Checkbox } from "primereact/checkbox";

const CreateFormTProcedures = ({ onSubmit, routeBack, withoutCodeInput, displayCheckBox }) => {
    const [validationError, setValidationError] = useState({})
    //navigate pour faire les redirections
    const navigate = useNavigate()
    const [showCheckBox, setShowCheckBox] = useState(displayCheckBox);

    //Creation des ascesseurs getters et setters pour tout le formulaire
    const [libelle, setLibelle] = useState('')
    const [code, setCode] = useState('')
    const [description, setDescription] = useState('');
    const [isEnrolement, setIsEnrolement] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ libelle, description, code });
        //setLibelle('');
        //setDescription('');
    };

    return (
        <>
            <div className="row g-3 mb-4 align-items-center justify-content-between">
                <div className="col-auto">
                    <h1 className="app-page-title mb-0">Enregistrement </h1>
                </div>
            </div>{/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
                <div className="app-card-body py-3">
                    <Row>
                        {
                            Object.keys(validationError).length > 0 && (
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-danger">
                                            <ul className="mb-0">
                                                {
                                                    <li>{validationError}</li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </Row>
                    <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmit}>

                        <Row>
                            {!withoutCodeInput && <>
                                <Col md={6} sm={12} className="form-group">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="fw-bold">Code <i className="text-danger">*</i>
                                        </Form.Label>
                                        <Form.Control className="border  border rounded-2" onChange={(e) => setCode(e.target.value)}
                                            value={code}
                                            placeholder=""
                                            name="code"
                                            aria-label="code"
                                            maxLength={100}
                                            minLength={2}
                                            type="text"
                                            required

                                        />
                                    </Form.Group>
                                </Col>
                            </>}
                            <Col md={withoutCodeInput ? 12 : 6} sm={12} className="form-group">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Libelle <i className="text-danger">*</i>
                                    </Form.Label>
                                    <Form.Control className="border  border rounded-2" onChange={(e) => setLibelle(e.target.value)}
                                        value={libelle}
                                        placeholder=""
                                        name="libelle"
                                        aria-label="libelle"
                                        maxLength={100}
                                        minLength={2}
                                        type="text"
                                        required

                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12} sm={12} className="form-group">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Description  <i className="text-danger"></i>
                                    </Form.Label>

                                    <textarea value={description} name="description" id="" cols="30" onChange={(e) => setDescription(e.target.value)} rows="5" className="form-control summernote border border rounded-2" placeholder=""></textarea>
                                    {/** 
                                     <ReactQuill value={description} theme="snow" id="" cols="30" onChange={(value) => setDescription(value)} placeholder="" />*/}

                                </Form.Group>
                            </Col>
                        </Row>
                        {showCheckBox == true && <><div className="py-3 flex flex-wrap justify-content-center gap-3">
                            <div className="flex align-items-center">
                                <Checkbox inputId="is_enrolement" name="is_enrolement" value="1" onChange={(e) => setIsEnrolement(e.checked)} checked={isEnrolement} />
                                <label htmlFor="is_enrolement" className="ml-2">&nbsp;&nbsp;Cocher cette case pour afficher ce type de document lors de l'enrôlement du dossier.</label>
                            </div>
                        </div></>}

                        <Button type="submit" variant="warning me-3">Enregistrer</Button>
                        <Button variant="secondary" onClick={() => navigate(routeBack)}>Annuler</Button>

                    </Form>

                </div>{/*//app-card-body*/}
            </div>
        </>
    )
}

export default CreateFormTProcedures;