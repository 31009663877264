import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { myroutes } from "../../../routes/webroute";
import { useSelector } from "react-redux";
import { PhonelinkSetupOutlined } from "@mui/icons-material";
import Layout from "../../../backoffice/admin/include/layout";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Importez les styles par défaut de la bibliothèque
import AssujettiesService from "../../../services/AssujettiesService";
import CivilitesService from "../../../services/CivilitesService";
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressSpinner } from "primereact/progressspinner";
import TypeActeursService from "../../../services/TypeActeursService";


export default function AssujettiesCreate() {
  //Déclaration des champs du formulaire
  const [is_acteur, setIsActeur] = useState(0);
  const [civilites, setCivilites] = useState([]);
  const [civilite_id, setCivilite_id] = useState("");
  const [type_personne, setType_personne] = useState("PM");
  const [raison_sociale, setRaison_sociale] = useState("");
  const [email, setEmail] = useState("");
  const [nom, setNom] = useState("");
  const [prenoms, setPrenoms] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephoneError, setTelephoneError] = useState("");
  const [rccm, setRccm] = useState("");
  const [ifu, setIfu] = useState("");
  const token = useSelector((state) => state.auth.token);
  const [validationError, setValidationError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [checked, setChecked] = useState(false);
  const [type_acteurs, setTypeacteurs] = useState([]);
  const [type_acteur_id, setTypeacteur_id] = useState('');

  const [loading, setLoading] = useState(false); // État pour le chargement


  const handleOptionChange = (value) => {
    setIsActeur(value);
    setTypeacteur_id("")
  };

  const navigate = useNavigate();

  const handleChange = (value, country) => {
    setTelephone(value);
    setTelephoneError("");
  };

  //get liste civilites
  const fetchCivilites = async () => {
    await CivilitesService.index(token)
      .then((response) => {
        setCivilites(response.data.data);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  // formater les données des types de chambres

  const datacivilites = civilites.map((item) => {
    return {
      label: item.libelle,
      value: item.id,
    };
  });

  //Liste des types d'acteurs
  const fetchTypeacteurs = async () => {
      await TypeActeursService.index(token).then((response) => {
        if(response.status === 200){
          setTypeacteurs(response.data.data)
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Début du chargement

    try {
      // Validation du numéro de téléphone
      if (!/^\+?\d*$/.test(telephone)) {
        setTelephoneError("Le numéro de téléphone n'est pas valide.");
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        return;
      }

      if (telephone === "") {
        setTelephoneError("Veuillez saisir votre téléphone");
        setLoading(false); // Fin du chargement en cas d'erreur de validation
        return;
      }

      const formData = new FormData();

      // Ajoutez les champs communs
      formData.append("email", email);
      formData.append("telephone", telephone);
      formData.append("adresse", adresse);
      formData.append("rccm", rccm);
      formData.append("ifu", ifu);
      formData.append("civilite_id", civilite_id);

      // Ajoutez le champ spécifique pour le type de personne
      formData.append("type_personne", type_personne);

      // Ajoutez les champs spécifiques en fonction du type de personne
      if (type_personne === "PM") {
        formData.append("raison_sociale", raison_sociale);
      } else {
        formData.append("raison_sociale", raison_sociale);
        formData.append("nom", nom);
        formData.append("prenoms", prenoms);
      }
      formData.append("is_acteur", is_acteur);
      formData.append("type_acteur_id", type_acteur_id);

      // Enregistrez le formulaire
      const response = await AssujettiesService.store(formData, token);
      if (response.data.status == true) {
        //console.log(response.data)
        // Affichez un message de succès
        Swal.fire({
          icon: "success",
          title: "Bravo !",
          text: response.data.message,
        });

        // Réinitialisez les données du formulaire
        setRaison_sociale("");
        setNom("");
        setPrenoms("");
        setEmail("");
        setTelephone("");
        setAdresse("");
        setRccm("");
        setIfu("");
        setType_personne("PM");
        setIsActeur(0);
        setTypeacteur_id("");

        // Redirigez l'utilisateur vers l'index
        navigate(myroutes.assujetties_index);
      } else {
        Swal.fire({
          icon: "error",
          text: response.data.message + ' ' + response.data.data,
        });
      }

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Vous avez mal renseigné les champs ou cet élément existe déjà dans la base de données'
      });
      console.error(error);
    } finally {
      setLoading(false); // Fin du chargement après le traitement
    }
  };


  useEffect(() => {
    fetchCivilites();
    fetchTypeacteurs();
  },
    []);

  return (
    <Layout>
      <main id="main" className="main ">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <div className="row g-3 align-items-center justify-content-between">
              <div className="col-auto">
                <div className="pagetitle">
                  <h1 className="app-page-title mb-0">Paramètres</h1>
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to={myroutes.dashboard}>Accueil</Link>
                      </li>
                      <li className="breadcrumb-item active">Assujetties </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
            {/*//row*/}

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h1 className="app-page-title mb-0">Enregistrement </h1>
              </div>
            </div>
            {/*//row*/}

            <div className="app-card app-card-settings shadow-lg mb-5">
              <div className="app-card-body py-3">
                <Row>
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">{<li>{validationError}</li>}</ul>
                        </div>
                      </div>
                    </div>
                  )}
                </Row>
                <Form
                  className="mx-3 settings-form p-4"
                  style={{ textAlign: "left" }}
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3 d-flexx">
                        <Form.Label
                          className="fw-bold mb-3"
                          style={{
                            fontSize: "18px",
                            color: "tomato",
                            fontStyle: "italicx",
                          }}
                        >
                          Est-ce une personne physique ?{" "}
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          id="type_personneSwitch"
                          label={type_personne === "PP" ? "Oui" : "Non"}
                          onChange={() =>
                            setType_personne(
                              type_personne === "PP" ? "PM" : "PP"
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12} className="form-group" style={{ display: type_personne === 'PP' ? 'none' : 'block' }}>
                      <Form.Group className="mb-3" >
                        <Form.Label className="fw-bold">
                        Dénomination sociale <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border border rounded-2"
                          onChange={(e) => setRaison_sociale(e.target.value)}
                          value={raison_sociale}
                          placeholder=""
                          name="raison_sociale"
                          aria-label="raison_sociale"
                          maxLength={100}
                          minLength={3}
                          type="text"
                        // hidden={type_personne === "PP"}
                        />
                      </Form.Group>
                    </Col>
                    {type_personne === "PP" && (
                      <>

                        <Col md={2} sm={2} className="formx-groupx">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Civilité <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Select
                              className=""
                              id="civilite_id"
                              name="civilite_id"
                              value={civilite_id}
                              // required
                              onChange={(e) => {
                                setCivilite_id(e.target.value);
                              }}
                            >
                              <option value="" selected>
                                --
                              </option>
                              {civilites.length > 0 &&
                                civilites.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.libelle}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={5} sm={5} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Nom <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              className="border border rounded-2"
                              onChange={(e) => setNom(e.target.value)}
                              value={nom}
                              placeholder=""
                              name="nom"
                              aria-label="nom"
                              maxLength={100}
                              minLength={2}
                              type="text"
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={5} sm={5} className="form-group">
                          <Form.Group className="mb-3">
                            <Form.Label className="fw-bold">
                              Prénom (s) <i className="text-danger">*</i>
                            </Form.Label>
                            <Form.Control
                              className="border border rounded-2"
                              onChange={(e) => setPrenoms(e.target.value)}
                              value={prenoms}
                              placeholder=""
                              name="prenoms"
                              aria-label="prenoms"
                              maxLength={100}
                              minLength={3}
                              type="text"
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} sm={12} className="form-group">
                          <Form.Group className="mb-3" style={{ displayx: type_personne === 'PP' ? 'none' : 'block' }}>
                            <Form.Label className="fw-bold">
                            Dénomination sociale <i className="text-danger"></i>
                            </Form.Label>
                            <Form.Control
                              className="border border rounded-2"
                              onChange={(e) => setRaison_sociale(e.target.value)}
                              value={raison_sociale}
                              placeholder="Renseigner la raison sociale s'il y a lieu"
                              name="raison_sociale"
                              aria-label="raison_sociale"
                              maxLength={100}
                              minLength={3}
                              type="text"
                            // disabled={type_personne === "PP"}
                            />
                          </Form.Group>
                        </Col>
                      </>
                    )}

                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">RCCM {type_personne === "PM" && <i className="text-danger">*</i> }</Form.Label>
                        <Form.Control
                          className="border border rounded-2"
                          onChange={(e) => setRccm(e.target.value)}
                          value={rccm}
                          placeholder=""
                          name="rccm"
                          aria-label="rccm"
                          maxLength={100}
                          minLength={3}
                          type="text"
                          required={type_personne === "PP" ? false : true}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">IFU {type_personne === "PM" && <i className="text-danger">*</i> }</Form.Label>
                        <Form.Control
                          className="border border rounded-2"
                          onChange={(e) => setIfu(e.target.value)}
                          value={ifu}
                          placeholder=""
                          name="ifu"
                          aria-label="ifu"
                          maxLength={13}
                          minLength={3}
                          type="number"
                          required={type_personne === "PP" ? false : true}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Email <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Control
                          className="border border rounded-2"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          placeholder=""
                          name="email"
                          aria-label="email"
                          maxLength={100}
                          minLength={3}
                          type="email"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={6} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Téléphone <i className="text-danger">*</i>{"  "}<i className="text-info bi bi-question-circle-fill" title="Veuillez choisir le drapeau et compléter votre numéro"></i>
                        </Form.Label>
                        <div
                        ><PhoneInput
                            defaultCountry="BJ"
                            value={telephone}
                            onChange={handleChange}
                            inputClass={`form-input`}
                            containerStyle={{
                              border: "",
                              borderRadius: "6px",
                              height: "50px"
                            }}
                            inputStyle={{
                              width: "80%",
                              border: "none",
                              outline: "none",
                              height: "100px"
                            }}
                            placeholder="61 00 00 00"
                            required
                          />

                        </div>

                      </Form.Group>
                    </Col>
                    <Col md={12} sm={12} className="form-group">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Adresse<i className="text-danger">*</i>
                        </Form.Label>
                        <textarea value={adresse} name="adresse" id="" cols="30" onChange={(e) => setAdresse(e.target.value)} rows="3" className="form-control summernote border border rounded-2"
                          placeholder="Boîte postale, localisation, ville, ..."
                          maxLength={100}
                          minLength={3}></textarea>

                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group flex justify-content-center mb-4 fw-bold">
                        <span className="me-3 p-2 mb-3" style={{ color: 'tomato', backgroundColor: '', fontSize: '18px' }}>Est-ce un acteur de justice (Avocat ou Huissier) ?</span>
                        <div className="p-field-checkbox">
                          <div className="p-formgroup-inline d-flex mt-3">
                            <div className="p-field-radiobutton me-4">
                              <RadioButton className="me-2" inputId="oui" name="oui" value={1} onChange={(e) => handleOptionChange(e.value)} checked={is_acteur === 1} />
                              <label htmlFor="oui">Oui</label>
                            </div>{"  "}
                            <div className="p-field-radiobutton">
                              <RadioButton className="me-2" inputId="non" name="non" value={0} onChange={(e) => handleOptionChange(e.value)} checked={is_acteur === 0} />
                              <label htmlFor="non">Non</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    {is_acteur == 1 && <Col md={5} sm={6} className="formx-groupx">
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-boldx">
                          <span className="mb-3" style={{ color: 'tomato', fontSize: '18px' }}>
                            Type acteur </span>
                          <i className="text-danger">*</i>
                        </Form.Label>
                        <Form.Select
                          className=""
                          id="type_acteur_id"
                          name="type_acteur_id"
                          value={type_acteur_id}
                          // required
                          onChange={(e) => {
                            setTypeacteur_id(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            --
                          </option>
                          {type_acteurs.length > 0 &&
                            type_acteurs.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.libelle}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    }

                  </Row>

                  <Button type="submit" variant="warning me-3" onClick={handleSubmit} disabled={loading}>Enregistrer</Button> {/* Désactiver le bouton pendant le chargement */}
                  <Button variant="secondary" onClick={() => navigate(myroutes.assujetties_index)}>Annuler</Button>
                </Form>
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}
          </div>
          {/*//container-fluid*/}
        </div>

        {/***
         * 
                  <Modal show={loading} backdrop="static" keyboard={false} centered>
                    <Modal.Body className="text-center">
                      <ProgressSpinner />
                      <p className="my-3 fw-bold" style={{ fontSize: '18px' }}>Veuillez patienter pendant l'enregistrement de vos données...</p>
                    </Modal.Body>
                  </Modal>
         */}
        {/*//app-content*/}
      </main>
    </Layout>
  );
}