import axios from "axios";

const authHttp = axios.create({
    baseURL : process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL, 
    headers: {
        "Content-Type": "application/json"
    }
})

class AuthService {

    login(data) {
        return authHttp.post("/api/v01/web/login", data)
    } 

    register(data) {
        return authHttp.post("/api/v01/web/acteursjustice/inscription", data)
    } 

    change_password(data, token) {
        return authHttp.post("/api/v01/web/change_password", data ,{ headers: { 'Authorization': 'Bearer '+token } })
    } 


    logout(data) {
        return authHttp.post("/api/v01/web/logout",  data,{ headers: { 'Authorization': 'Bearer '+data } })
    } 


    //Forgot Password
    forgotPassword(data) {
        return authHttp.post("/api/v01/web/password/forgot", data)
    } 

    validationOtp(data) {
        return authHttp.post("/api/v01/web/password/reset", data)
    }

} 


export default new AuthService
