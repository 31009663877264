import React from "react";

export default function Footer() {
    return (
        <React.Fragment>
         {/* ======= Footer ======= */}
            <footer id="footer" className="footer" style={{heightx:"90px", borderx:"1px solid red"}}>
                <div className="copyright">
                &copy; {(new Date().getFullYear())} - <strong><span>Cour d'Appel de Commerce - Cotonou </span></strong>.Tous droits réservés.
                </div>
                <div className="credits">
                Conception & Réalisation : <a className="app-link" href="www.digiweb.bj"
                            target="_blank">DigiWeb SARL</a>
                </div>
            </footer>{/* End Footer */}
        </React.Fragment>
    )
}
