import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../../routes/webroute";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Layout from "../../../../backoffice/admin/include/layout";
import $ from "jquery";
//import "datatables.net-dt/js/dataTables.dataTables";
//import "datatables.net-dt/css/jquery.dataTables.css";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
import CalendriersGlobalsService from "../../../../services/CalendriersGlobalsService";
import { FileUpload } from "primereact/fileupload";
import { afficherMessagesSuccess, afficherOneMessageError, convertDateToTexte, copyToClipboard, setDataTable } from "../../../../utils/helpers";
import ButtonWithSpinner from "../../../../components/ButtonWithSpinner";

export default function CalendriersGlobalsIndex() {
  //##########################/// METHODES ##########################///
  const [calendriersglobals, setCalendriersGlobals] = useState([]);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const [file, setFile] = useState('');
  const [loadingImport, setLoadingImport] = useState(false);

  const handleCopyReference = (reference, itemId) => {
    copyToClipboard(reference)
  };

  //get liste calendries globals
  const fetchCalendriersGlobals = async () => {
    try {
      const response = await CalendriersGlobalsService.index(token);
      const responseData = response.data;

      if (
        responseData.status &&
        responseData.data &&
        responseData.data.length > 0
      ) {
        setCalendriersGlobals(responseData.data);
      } else {
        console.error("Erreur dans la réponse:", responseData.message);
      }
    } catch (error) {
      console.error("Erreur lors de la requête de statuts:", error);
    }


    //Afficher dataTable
    setDataTable();
  };

  //redirection sur la page edit sans afficher les id dans l'url
  const goToEdit = (param) => {
    navigate(myroutes.calendriersglobals_edit, { state: { ref: param } });
  };

  //suppression d'un élément
  const onDelete = (param) => {
    //confirm action
    Swal.fire({
      icon: "warning",
      text: "Voulez-vous confirmer cette action ?",
      showCancelButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        CalendriersGlobalsService.delete(param, token)
          .then((response) => {
            if (response.data.status == true) {
              Swal.fire({
                icon: "success",
                text: response.data.message,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: response.data.message,
              });
              fetchCalendriersGlobals();
            }
          })
          .catch((e) => {
            Swal.fire({
              icon: "error",
              text: e.response.data.message,
            });
            console.log(e);
          });
      }
    });
  };

  // Fonction de gestion du changement de fichier
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };


  ///Save form data
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await CalendriersGlobalsService.importExcel(formData, token);
      //console.log(response.status)
      if (response.status === 200) {
        fetchCalendriersGlobals();

        afficherMessagesSuccess(response.data.message || "Bravo ! Votre enregistrement a été effectué avec succès.");

        setTimeout(() => {
          window.location.reload();
        }, 2000);

      } else {
        afficherOneMessageError(response.data.message || "Une erreur s'est produite lors de l'importation.")
      }
    } catch (error) {
      console.error("Une erreur s'est produite :", error);
      Swal.fire({
        icon: "error",
        text: "Une erreur s'est produite lors de l'importation.",
        timer: 3000,
      });
    }
  };



  useEffect(() => {
    fetchCalendriersGlobals();
    $("#parametres-nav").addClass("show");
  }, []);

  //##########################/// END METHODES ##########################///

  return (
    <Layout>
      <main id="main" className="main">
        <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
          <div className="container-xl app-card-body">
            <BreadcrumbComponent
              params={{ isParametre: true, libelle: "Calendriers Globals" }}
              routeName={myroutes.calendriersglobals_index}
            ></BreadcrumbComponent>

            <div className="row g-3 mb-4 align-items-center justify-content-between">
              <div className="col-auto">
                <h3 className="app-page-title mb-0">
                  Calendrier Global{" "}
                  <Link
                    className="btn btn-success btn-sm"
                    to={myroutes.calendriersglobals_create}
                    title="Ajouter"
                  >
                    <i className="bi bi-plus"></i>Ajouter
                  </Link>{" "}
                </h3>
              </div>
            </div>
            {/*//row*/}

            {/** Importation des dates
             * <Form className="settings-form mb-3" style={{ textAlign: "right" }} onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="d-flex align-items-end col-md-6">
                <input type="file" className="form-control" accept=".xlsx, .xls" name="file" onChange={handleFileChange} required />

                <ButtonWithSpinner type="submit" loading={loadingImport} color={"info"} title="Importer" className="ms-2">Importer</ButtonWithSpinner>
              </div>
            </Form>
             */}

            <div className="app-card app-card-orders-table shadow-lg mb-5">
              <div className="app-card-body mx-3 py-2">
                <div className="table-responsive mt-4">
                  <table className="table table-striped table-font table-hover app-table-hover mb-0 data-table">
                    <thead className="table-info mt-2">
                      <tr>
                        <th className="">N°</th>
                        <th className="">Date debut</th>
                        <th className="">Date fin</th>
                        <th className="">Année</th>
                        <th className="">Statut</th>
                        <th className="">Date modification</th>
                        <th className="">Activité</th>
                      </tr>
                    </thead>
                    <tbody
                      className="table-group-divider"
                      style={{ textAlign: "left" }}
                    >
                      {calendriersglobals.length > 0 &&
                        calendriersglobals.map((item, index) => (
                          <tr key={index}>
                            <td className="cell">{index + 1}</td>
                            <td className="cell">
                              <span className="text-truncate">
                                {convertDateToTexte(item.date_debut)}
                              </span>
                            </td>
                            <td className="cell">
                              <span className="text-truncate">
                                {convertDateToTexte(item.date_fin)}
                              </span>
                            </td>
                            <td className="cell">{item.annee}</td>
                            <td>
                              <span
                                className={`badge ${item.get_statut.libelle === "ACTIF"
                                  ? "text-bg-success"
                                  : "text-bg-danger"
                                  }`}
                              >
                                {item.get_statut.libelle}
                              </span>
                            </td>
                            <td className="cell">
                              <span>
                                {dayjs(item.updated_at).format(
                                  "DD/MM/YYYY H:m:s"
                                )}
                              </span>
                            </td>
                            <td className="cell">
                              <Button
                                className="btn btn-sm btn-primary"
                                onClick={() => goToEdit(item.ref)}
                                title="Modifier"
                              >
                                <i className="bi bi-pen-fill"></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className="btn btn-sm btn-danger"
                                onClick={() => onDelete(item.ref)}
                                title="Supprimer"
                              >
                                <i className="bi bi-trash"></i>
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handleCopyReference(item.ref, item.id)
                                }
                              >
                                <i className="bi bi-clipboard2-fill"></i> Ref
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                {/*//table-responsive*/}
              </div>
              {/*//app-card-body*/}
            </div>
            {/*//app-card*/}

            {/*//End table-content*/}
          </div>
          {/*//container-fluid*/}
        </div>
      </main>
      {/*//app-content*/}
    </Layout>
  );
}
