import { Link } from "react-router-dom";
import { myroutes } from "../routes/webroute";

const BreadcrumbComponent = ({routeName,  params}) => {
    return (
        <>
            <div className="row g-3 align-items-center justify-content-between">
                <div className="col-auto">
                    <div className="pagetitle">
                        { params.isParametre && <h1 className="app-page-title mb-0">Paramètres</h1>}
                        { !params.isParametre && <h1 className="app-page-title mb-0">{params.title}</h1>}
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={myroutes.dashboard} >Accueil</Link></li>
                                <li className="breadcrumb-item active"><Link to={routeName} >{params.libelle} </Link></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BreadcrumbComponent;