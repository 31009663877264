import { Link, useNavigate } from "react-router-dom";
import { myroutes } from "../../../routes/webroute";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../backoffice/admin/include/layout";
import $ from "jquery"
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
import { Tag } from 'primereact/tag';

import parse from "html-react-parser"
import { Button, Form, Row } from "react-bootstrap";
import { afficherMessagesSuccess, afficherOneMessageError, copyToClipboard, setDataTable } from "../../../utils/helpers";
import CalendriersProceduresService from "../../../services/CalendriersProceduresService";
import dayjs from "dayjs";
import { Dialog } from "primereact/dialog";
import Swal from "sweetalert2";
import { InputText } from "primereact/inputtext";

export default function CalendriersProceduresIndex() {

    //##########################/// METHODES ##########################///
    const [calendriersprocedures, setCalendriersprocedures] = useState([])
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token)
    const profils = useSelector((state) => state.auth.profil);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [detailLabel, setDetailLabel] = useState("")
    const [dossierId, setDossierId] = useState("")


    const handleShowModalEdit = () => setShowModalEdit(true);
    const handleCloseModalEdit = () => setShowModalEdit(false);

    const [typesparties, setTypesparties] = useState([]);
    const [type_partie_id, setTypepartie_id] = useState({});
    const [date_echeance, setDateEcheance] = useState({});

    const [typesdocuments, setTypesdocuments] = useState([]);
    const [type_document_id, setTypedocument_id] = useState({});

    //get liste calendrier procedure
    const fetchCalendrierProcedure = async () => {
        try {
            const response = await CalendriersProceduresService.listeByProfil(token);
            const responseData = response.data;

            if (responseData.status && responseData.data && responseData.data.length > 0) {
                setCalendriersprocedures(responseData.data);

            } else {
                console.error("Erreur dans la réponse:", responseData.message);
            }
        } catch (error) {
            console.error("Erreur lors de la requête de statuts:", error);
        }

        //Afficher dataTable
        setDataTable();
    };


    //redirection sur la page edit sans afficher les id dans l'url
    /*const goToEdit = (param) => {
        navigate(myroutes.calendriersprocedures_edit, { state: { ref: param } })
    }*/


    const goToDossier = (dossier_ref) => {
        navigate(`${myroutes.dossiers_show}/${dossier_ref}`);
    }

    const handleCopyReference = (reference, itemId) => {
        copyToClipboard(reference)
    };

    //Afficher le modal de modification
    const handleEdit = async (option) => {
        setDetailLabel(option.ref)
        try {
            const response = await CalendriersProceduresService.edit(option.ref, token);
            if (response.status === 200 && response.data.status == true) {
                //setEditData(response.data.data);
                const data = response.data.data;
                setDateEcheance(data.date_echeance)
                setTypedocument_id(data.type_document_id)
                setTypepartie_id(data.type_partie_id)
                setDossierId(option.dossier_id)
            }
        } catch (error) {
            console.log(error);
        }

        handleShowModalEdit()
    }


    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        let form = {
          date_echeance: date_echeance,
          type_document_id: type_document_id,
          type_partie_id: type_partie_id,
          dossier_id: dossierId
        };
    
        let ref = detailLabel;
    
        await CalendriersProceduresService.update(ref, form, token).then((response) => {
          if (response.data.status == true && response.status === 200) {
            handleCloseModalEdit();
    
            afficherMessagesSuccess(response.data.message)
    
            //Actualiser la page
            /*setTimeout(() => {
              window.location.reload()
            }, 1500);*/
            fetchCalendrierProcedure();
    
          } else {
            afficherOneMessageError(response.data.message || "Une erreur s'est produite!")
          }
        }).catch((error) => {
          console.log(error);
    
          Swal.fire({
            icon: 'error',
            title: 'Erreur de validation',
            html: error.response.data.data,
            toast: true,
            position: "top-end",
            timer: 10000,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            timerProgressBar: true,
          });
        });
    
      }


    useEffect(() => {
        fetchCalendrierProcedure();
        $("#datesaudiences-nav").addClass("show")
    }, [])


    //##########################/// END METHODES ##########################///


    return (
        <Layout>
            <main id="main" className="main">
                <div className="app-content pt-3 p-md-3 p-lg-4 app-card app-card-stat">
                    <div className="container-xl app-card-body">

                        <BreadcrumbComponent params={{ isParametre: false, libelle: "Calendriers des procédures", title: "Audiences" }} routeName={myroutes.calendriersprocedures_index}></BreadcrumbComponent>


                        <div className="row g-3 mb-4 align-items-center justify-content-between">
                            <div className="col-auto">
                                <h3 className="app-page-title mb-0">Liste des calendriers des procédures</h3>
                            </div>

                        </div>{/*//row*/}

                        <div className="app-card app-card-orders-table shadow-lg mb-5">
                            <div className="app-card-body mx-3 py-2">
                                <div className="table-responsive mt-4">

                                    <table className="table table-striped table-font table-bordered table-hover app-table-hover mb-0 data-table">
                                        <thead className="table-info mt-2">
                                            <tr>
                                                <th className="">N°</th>
                                                <th className="">Référence dossier</th>
                                                <th className="">Type document</th>
                                                <th className="">Type de partie</th>
                                                <th className="">Echéance</th>
                                                <th className="">Observation</th>
                                                {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
                                                    <th className="text-center">Activité</th>}

                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider" style={{ textAlign: "left" }}>
                                            {calendriersprocedures.length > 0 && calendriersprocedures.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="cell">{index + 1}</td>
                                                    <td className="cell">
                                                        {(profils.includes("GEC") || profils.includes("GCH") || profils.includes("AGEC") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) ?
                                                            <Tag severity="warning" value={item.get_dossier.reference_dossier} icon="pi pi-file" onClick={() => goToDossier(item.get_dossier.ref)} title="Afficher le dossier" />
                                                            : <Tag severity="warning" value={item.get_dossier.reference_dossier} icon="pi pi-file" />}
                                                    </td>
                                                    <td className="cell">{item.get_type_document ? item.get_type_document.libelle : 'N/A'}</td>
                                                    <td className="cell">{item.get_type_partie ? item.get_type_partie.libelle : 'N/A'}</td>

                                                    <td className="cell">{dayjs(item.date_echeance).format("DD/MM/YYYY")}</td>
                                                    <td className="cell">{item.description != null ? parse(item.description) : item.description}</td>
                                                    {(profils.includes("GEC") || profils.includes("AGEC") || profils.includes("GCH") || profils.includes("ADMIN") || profils.includes("SUPERADMIN")) &&
                                                        <>
                                                            <td className="text-center">
                                                                <div className="d-flex justify-content-evenly">
                                                                    <Button className="btn btn-sm btn-primary" title="Modifier" onClick={() => handleEdit(item)}>
                                                                        <i className="bi bi-pen-fill"></i></Button> &nbsp;&nbsp;
                                                                    <Button
                                                                        className="btn btn-sm btn-success"
                                                                        onClick={() => handleCopyReference(item.get_dossier?.reference_dossier, item.id)}>
                                                                        <i className="bi bi-clipboard2-fill"></i> Ref
                                                                    </Button>
                                                                </div>

                                                            </td>
                                                        </>}
                                                </tr>
                                            ))}


                                        </tbody>
                                    </table>
                                </div>{/*//table-responsive*/}

                            </div>{/*//app-card-body*/}
                        </div>
                        {/*//app-card*/}

                        {/*//End table-content*/}



                    </div>{/*//container-fluid*/}
                </div>
                {/* Modal modification*/}
                <Dialog
                    header={"Modification d'un calendrier des procédures"}
                    visible={showModalEdit}
                    maximizable
                    onHide={handleCloseModalEdit}
                    style={{ width: "700px", borderBottom: "1px solid grey", color: "GrayText" }}
                    headerStyle={{ background: "#f0f0f0" }} // Ajout du style pour le header du modal
                >

                    <Form className="mx-3 settings-form" style={{ textAlign: "left" }} onSubmit={handleSubmitEdit}>

                        <Row className="pt-4">
                            <div className="col-lg-4 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Partie ou conseil<i className="text-danger">*</i></Form.Label>
                                    <Form.Select
                                        className=""
                                        id="type_partie_id"
                                        name={`type_partie_id`}
                                        value={type_partie_id}
                                        required
                                        onChange={(e) => {
                                            setTypepartie_id(e.target.value)
                                        }}
                                    >
                                        <option value="" selected>
                                            -- Sélectionnez --
                                        </option>
                                        {typesparties.length > 0 &&
                                            typesparties.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Types de documents<i className="text-danger">*</i></Form.Label>
                                    <Form.Select
                                        className=""
                                        id="type_document_id"
                                        name={`type_document_id`}
                                        value={type_document_id}
                                        required
                                        onChange={(e) => {
                                            setTypedocument_id(e.target.value)
                                        }}
                                    >
                                        <option value="" selected>
                                            -- Sélectionnez --
                                        </option>
                                        {typesdocuments.length > 0 &&
                                            typesdocuments.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.libelle}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Form.Group>
                            </div>

                            <div className="col-lg-4 col-sm-12">
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold">Date d'échéance<i className="text-danger">*</i></Form.Label>
                                    <InputText id="date_echeance" className="form-control" required onChange={(e) => {
                                        setDateEcheance(e.target.value)
                                    }}
                                        type="date" name={`date_echeance`} value={date_echeance} />
                                </Form.Group>
                            </div>

                        </Row>

                        <Button className="mb-3" type="submit"
                            variant="success me-3">
                            <i className="bi bi-check"></i> Enregistrer
                        </Button>
                        <Button variant="secondary me-3" className="mb-3" onClick={handleCloseModalEdit}>Fermer</Button>

                    </Form>


                </Dialog>
            </main>
            {/*//app-content*/}
        </Layout>
    )
}
