import http from "./http"

class PartiesService {
    index(token){
        return http.get("/api/v01/web/parties/index",{ headers: { 'Authorization': 'Bearer '+token } })
    }

    store(data, token){
        return http.post("/api/v01/web/parties/store", data,{ headers: { 'Authorization': 'Bearer '+token } })
    }
    delete(ref, token){
        return http.delete(`/api/v01/web/parties/${ref}/delete`,{ headers: { 'Authorization': 'Bearer '+token } })
    }

}

export default new PartiesService()